import React, { useMemo, useState, useEffect } from 'react'
import { SmallDown, SmallUp } from 'components/Responsive/breakpoints'
import { defaultCurrencies } from '../../constants'
import {
  getFormattedPrice,
  getLabelFromValue,
  isMobile,
  numberWithCommas,
} from 'helpers'
import TooltipOnHover from 'components/TooltipOnHover'
import AdditionalChargesModal from './AdditionalChargesModal'
import UpdateOutsourcePrice from './UpdateOutsourcePriceModal'

const isMobileBrowser = isMobile()

const PartnerPricingDetails = ({
  charges = [],
  supplierInfo,
  dispatch,
  serviceType,
  bookingId,
  bookingPartner,
  reloadList,
  isLocalSupplier,
  dispatchStatus,
}) => {
  const [showChargesModal, setShowChargesModal] = useState(false)
  const [showOutsourceModal, setShowOutsourceModal] = useState(false)
  const [renderedCharges, setRenderedCharges] = useState([])
  const [chargeType, setChargeType] = useState(null)
  const supplierCurrency = useMemo(
    () => getLabelFromValue(defaultCurrencies, supplierInfo.supplierCurrency),
    [supplierInfo.supplierCurrency]
  )
  const buyingPrice = useMemo(() => parseFloat(supplierInfo.buyingPrice), [
    supplierInfo.buyingPrice,
  ])
  const totalPrice = useMemo(
    () =>
      numberWithCommas(parseFloat(supplierInfo.totalBuyingPrice).toFixed(2)),
    [supplierInfo.totalBuyingPrice]
  )

  let allCharges = isLocalSupplier
    ? charges.map((item, index) => ({
        ...item,
        index: index + 1,
      }))
    : []

  let sentCharges = isLocalSupplier
    ? allCharges.filter(item => item.outsource)
    : []
  let notSentCharges = isLocalSupplier
    ? allCharges.filter(item => !item.outsource)
    : []

  let updateChargesModal = (charges, type) => {
    try {
      setRenderedCharges(charges)
      setChargeType(type)
      setShowChargesModal(true)
    } catch (error) {
      console.log('updateChargesModal error', error)
    }
  }

  useEffect(() => {
    if (chargeType === 'sent') {
      setRenderedCharges(allCharges.filter(item => item.outsource))
    }
    if (chargeType === 'notSent') {
      setRenderedCharges(allCharges.filter(item => !item.outsource))
    }
  }, [JSON.stringify(charges)])

  const renderLocalSupplier = () => {
    let totalAmount = parseFloat(buyingPrice)
    let totalMargin = supplierInfo.marginRate
      ? parseFloat(supplierInfo.marginRate)
      : 0
    let totalOutSourced = supplierInfo.outsourceRate
      ? parseFloat(supplierInfo.outsourceRate)
      : 0

    return (
      <>
        <SmallUp>
          <div className='driver-service-card-header'>
            <span>Price</span>
          </div>
          <div className='driver-service-card-body'>
            <div className='driver-service-additionlCharges'>
              <div className='d-flex justify-content-between'>
                <span className='title'>{`${serviceType}`}</span>
                <span>{`${numberWithCommas(
                  getFormattedPrice(buyingPrice)
                )} ${supplierCurrency}`}</span>
              </div>
              {supplierInfo.outsourceRate ? (
                <div className='d-flex justify-content-between  mb-2'>
                  <span className='title'>Outsourced price</span>
                  <span style={{ marginLeft: '8px' }}>
                    {supplierInfo &&
                      numberWithCommas(
                        getFormattedPrice(supplierInfo.outsourceRate)
                      )}{' '}
                    {supplierInfo &&
                      getLabelFromValue(
                        defaultCurrencies,
                        supplierInfo.supplierCurrency
                      )}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {supplierInfo.marginRate ? (
                <div className='d-flex justify-content-between  mb-2'>
                  <span className='title'>
                    Margin ({supplierInfo.dispatchWeight}
                    %)
                  </span>
                  <span style={{ marginLeft: '8px' }}>
                    {supplierInfo &&
                      numberWithCommas(
                        getFormattedPrice(supplierInfo.marginRate)
                      )}{' '}
                    {supplierInfo &&
                      getLabelFromValue(
                        defaultCurrencies,
                        supplierInfo.supplierCurrency
                      )}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {dispatch &&
          supplierInfo &&
          isLocalSupplier &&
          (dispatchStatus === 'accepted' || dispatchStatus === 'outsourced') &&
          dispatch.supplierId !== supplierInfo.supplierId ? (
            <div
              className='d-flex justify-content-end'
              style={{ marginTop: '0.3rem', fontSize: '14px' }}
            >
              <button
                style={{ marginTop: '0.938rem' }}
                className='btn btn-anchor btn-search ml-3'
                type='button'
                onClick={() => setShowOutsourceModal(true)}
              >
                Edit outsourced price
              </button>
            </div>
          ) : (
            <></>
          )}
          {sentCharges.length ? (
            <>
              {sentCharges.map(item => {
                if (item.localSupplier && item.localSupplier.amount) {
                  totalAmount += parseFloat(item.localSupplier.originalPrice)
                  totalMargin += parseFloat(item.localSupplier.marginRate)
                  totalOutSourced += parseFloat(item.localSupplier.amount)
                }
                return (
                  <div
                    style={{ marginTop: '0.5em' }}
                    className='driver-service-card-body'
                  >
                    <div className='driver-service-additionlCharges'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <span
                            className='title mr-2'
                            style={{ whiteSpace: 'pre' }}
                          >{`Additional earning ${item.index}`}</span>
                          <TooltipOnHover
                            delay={200}
                            placement='right-start'
                            isMobileBrowser={false}
                            onMouseEnter={() => {}}
                            isPartner={true}
                            component={
                              <>
                                <span className='two-line-text'>
                                  {item.description}
                                </span>
                              </>
                            }
                          >
                            <img
                              className={'new-info-icon'}
                              style={{
                                marginLeft: '0.7rem',
                                marginTop: '0.25rem',
                                paddingBottom: '0px',
                                maxHeight: '13px',
                              }}
                              src='/images/icons/new-info-icon.png'
                              alt=''
                            />
                          </TooltipOnHover>
                        </div>
                        <span>{`${numberWithCommas(
                          getFormattedPrice(item.localSupplier.originalPrice)
                        )} ${supplierCurrency}`}</span>
                      </div>
                      {item.localSupplier ? (
                        <div className='d-flex justify-content-between  mb-2'>
                          <span className='title'>Outsourced price</span>
                          <span style={{ marginLeft: '8px' }}>
                            {supplierInfo &&
                              numberWithCommas(
                                getFormattedPrice(item.localSupplier.amount)
                              )}{' '}
                            {supplierInfo &&
                              getLabelFromValue(
                                defaultCurrencies,
                                supplierInfo.supplierCurrency
                              )}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.localSupplier ? (
                        <div className='d-flex justify-content-between  mb-2'>
                          <span className='title'>
                            Margin ({item.localSupplier.dispatchWeight}
                            %)
                          </span>
                          <span style={{ marginLeft: '8px' }}>
                            {supplierInfo &&
                              numberWithCommas(
                                getFormattedPrice(item.localSupplier.marginRate)
                              )}{' '}
                            {supplierInfo &&
                              getLabelFromValue(
                                defaultCurrencies,
                                supplierInfo.supplierCurrency
                              )}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })}

              <div
                className='d-flex justify-content-end '
                style={{ marginTop: '0.3rem', fontSize: '14px' }}
              >
                <button
                  style={{ marginTop: '0.938rem', marginBottom: '0.938rem' }}
                  className={`btn btn-anchor btn-search ml-3`}
                  type='button'
                  onClick={() => updateChargesModal(sentCharges, 'sent')}
                >
                  Edit earning(s)
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
          {notSentCharges.length ? (
            <>
              {notSentCharges.map(item => {
                totalAmount += parseFloat(item.rate)
                return (
                  <div
                    style={{ marginTop: '0.5em' }}
                    className='driver-service-card-body'
                  >
                    <div className='driver-service-additionlCharges'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <span
                            className='title mr-2'
                            style={{ whiteSpace: 'pre' }}
                          >{`Additional earning ${item.index}`}</span>
                          <TooltipOnHover
                            delay={200}
                            placement='right-start'
                            isMobileBrowser={false}
                            onMouseEnter={() => {}}
                            isPartner={true}
                            component={
                              <>
                                <span className='two-line-text'>
                                  {item.description}
                                </span>
                              </>
                            }
                          >
                            <img
                              className={'new-info-icon'}
                              style={{
                                marginLeft: '0.7rem',
                                marginTop: '0.25rem',
                                paddingBottom: '0px',
                                maxHeight: '13px',
                              }}
                              src='/images/icons/new-info-icon.png'
                              alt=''
                            />
                          </TooltipOnHover>
                        </div>
                        <span>{`${numberWithCommas(
                          getFormattedPrice(item.amount)
                        )} ${supplierCurrency}`}</span>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!dispatch ||
              (dispatch &&
                supplierInfo &&
                dispatch.supplierId !== supplierInfo.supplierId) ? (
                <div
                  className='d-flex justify-content-end'
                  style={{ marginTop: '0.3rem', fontSize: '14px' }}
                >
                  <button
                    style={{ marginTop: '0.938rem' }}
                    className='btn btn-anchor btn-search ml-3'
                    type='button'
                    onClick={() =>
                      updateChargesModal(notSentCharges, 'notSent')
                    }
                  >
                    Send earning(s) to partner
                  </button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {totalOutSourced ? (
            <>
              <div
                style={{ marginTop: '0.5em' }}
                className='driver-service-card-body'
              >
                <div style={{ padding: '1em', fontSize: '15px' }}>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                      <span
                        className='title mr-2'
                        style={{ whiteSpace: 'pre' }}
                      >
                        Total price
                      </span>
                    </div>
                    <span>{`${numberWithCommas(
                      getFormattedPrice(totalAmount)
                    )} ${supplierCurrency}`}</span>
                  </div>

                  <div className='d-flex justify-content-between  mb-2'>
                    <span className='title'>Total outsourced price</span>
                    <span style={{ marginLeft: '8px' }}>
                      {supplierInfo &&
                        numberWithCommas(
                          getFormattedPrice(totalOutSourced)
                        )}{' '}
                      {supplierInfo &&
                        getLabelFromValue(
                          defaultCurrencies,
                          supplierInfo.supplierCurrency
                        )}
                    </span>
                  </div>

                  <div className='d-flex justify-content-between  mb-2'>
                    <span className='title'>Total margin</span>
                    <span style={{ marginLeft: '8px' }}>
                      {supplierInfo &&
                        numberWithCommas(getFormattedPrice(totalMargin))}{' '}
                      {supplierInfo &&
                        getLabelFromValue(
                          defaultCurrencies,
                          supplierInfo.supplierCurrency
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <AdditionalChargesModal
            show={showChargesModal}
            onHide={() => {
              setShowChargesModal(false)
              setRenderedCharges([])
            }}
            supplierCurrency={supplierInfo.supplierCurrency}
            charges={renderedCharges}
            bookingId={bookingId}
            bookingPartner={bookingPartner}
            reloadList={reloadList}
          />
          <UpdateOutsourcePrice
            show={showOutsourceModal}
            onHide={() => {
              setShowOutsourceModal(false)
            }}
            partnerName={bookingPartner && bookingPartner.name}
            supplierCurrency={supplierInfo.supplierCurrency}
            bookingId={bookingId}
            updateBooking={reloadList}
            originalPrice={supplierInfo.buyingPrice}
            buyingPrice={
              supplierInfo.outsourceRate
                ? parseFloat(supplierInfo.outsourceRate.toFixed(2))
                : supplierInfo.dispatchWeight
                ? parseFloat(
                    supplierInfo.buyingPrice -
                      supplierInfo.buyingPrice *
                        (supplierInfo.dispatchWeight / 100)
                  ).toFixed(2)
                : supplierInfo.buyingPrice
            }
          />
        </SmallUp>
        <SmallDown>
          <div className='driver-service-card-header'>
            <span>Price</span>
          </div>
          <div className='driver-service-card-body'>
            <div className='driver-service-additionlCharges'>
              <div className='d-flex justify-content-between'>
                <span className='title'>{`${serviceType}`}</span>
                <span>{`${numberWithCommas(
                  getFormattedPrice(buyingPrice)
                )} ${supplierCurrency}`}</span>
              </div>
              {supplierInfo.outsourceRate ? (
                <div className='d-flex justify-content-between  mb-2'>
                  <span className='title'>Outsourced price</span>
                  <span style={{ marginLeft: '8px' }}>
                    {supplierInfo &&
                      numberWithCommas(
                        getFormattedPrice(supplierInfo.outsourceRate)
                      )}{' '}
                    {supplierInfo &&
                      getLabelFromValue(
                        defaultCurrencies,
                        supplierInfo.supplierCurrency
                      )}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {supplierInfo.marginRate ? (
                <div className='d-flex justify-content-between  mb-2'>
                  <span className='title'>
                    Margin ({supplierInfo.dispatchWeight}
                    %)
                  </span>
                  <span style={{ marginLeft: '8px' }}>
                    {supplierInfo &&
                      numberWithCommas(
                        getFormattedPrice(supplierInfo.marginRate)
                      )}{' '}
                    {supplierInfo &&
                      getLabelFromValue(
                        defaultCurrencies,
                        supplierInfo.supplierCurrency
                      )}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {dispatch &&
          isLocalSupplier &&
          supplierInfo &&
          (dispatchStatus === 'accepted' || dispatchStatus === 'outsourced') &&
          dispatch.supplierId !== supplierInfo.supplierId ? (
            <div
              className='d-flex justify-content-end'
              style={{ marginTop: '0.3rem', fontSize: '14px' }}
            >
              <button
                style={{ marginTop: '0.938rem' }}
                className='btn btn-anchor btn-search ml-3'
                type='button'
                onClick={() => setShowOutsourceModal(true)}
              >
                Edit outsourced price
              </button>
            </div>
          ) : (
            <></>
          )}
          {sentCharges.length ? (
            <>
              {sentCharges.map(item => {
                if (item.localSupplier && item.localSupplier.amount) {
                  totalAmount += parseFloat(item.localSupplier.originalPrice)
                  totalMargin += parseFloat(item.localSupplier.marginRate)
                  totalOutSourced += parseFloat(item.localSupplier.amount)
                }
                return (
                  <div
                    style={{ marginTop: '0.5em' }}
                    className='driver-service-card-body'
                  >
                    <div className='driver-service-additionlCharges'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <span
                            className='title mr-2'
                            style={{ whiteSpace: 'pre' }}
                          >{`Additional earning ${item.index}`}</span>
                          <TooltipOnHover
                            delay={200}
                            placement='right-start'
                            isMobileBrowser={false}
                            onMouseEnter={() => {}}
                            isPartner={true}
                            component={
                              <>
                                <span className='two-line-text'>
                                  {item.description}
                                </span>
                              </>
                            }
                          >
                            <img
                              className={'new-info-icon'}
                              style={{
                                marginLeft: '0.7rem',
                                marginTop: '0.25rem',
                                paddingBottom: '0px',
                                maxHeight: '13px',
                              }}
                              src='/images/icons/new-info-icon.png'
                              alt=''
                            />
                          </TooltipOnHover>
                        </div>
                        <span>{`${numberWithCommas(
                          getFormattedPrice(item.localSupplier.originalPrice)
                        )} ${supplierCurrency}`}</span>
                      </div>
                      {item.localSupplier ? (
                        <div className='d-flex justify-content-between  mb-2'>
                          <span className='title'>Outsourced price</span>
                          <span style={{ marginLeft: '8px' }}>
                            {supplierInfo &&
                              numberWithCommas(
                                getFormattedPrice(item.localSupplier.amount)
                              )}{' '}
                            {supplierInfo &&
                              getLabelFromValue(
                                defaultCurrencies,
                                supplierInfo.supplierCurrency
                              )}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.localSupplier ? (
                        <div className='d-flex justify-content-between  mb-2'>
                          <span className='title'>
                            Margin ({item.localSupplier.dispatchWeight}
                            %)
                          </span>
                          <span style={{ marginLeft: '8px' }}>
                            {supplierInfo &&
                              numberWithCommas(
                                getFormattedPrice(item.localSupplier.marginRate)
                              )}{' '}
                            {supplierInfo &&
                              getLabelFromValue(
                                defaultCurrencies,
                                supplierInfo.supplierCurrency
                              )}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })}

              <div
                className='d-flex justify-content-end '
                style={{ marginTop: '0.3rem', fontSize: '14px' }}
              >
                <button
                  style={{ marginTop: '0.938rem', marginBottom: '0.938rem' }}
                  className={`btn btn-anchor btn-search ml-3`}
                  type='button'
                  onClick={() => updateChargesModal(sentCharges, 'sent')}
                >
                  Edit earning(s)
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
          {notSentCharges.length ? (
            <>
              {notSentCharges.map(item => {
                totalAmount += parseFloat(item.rate)
                return (
                  <div
                    style={{ marginTop: '0.5em' }}
                    className='driver-service-card-body'
                  >
                    <div className='driver-service-additionlCharges'>
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                          <span
                            className='title mr-2'
                            style={{ whiteSpace: 'pre' }}
                          >{`Additional earning ${item.index}`}</span>
                          <TooltipOnHover
                            delay={200}
                            placement='bottom'
                            isMobileBrowser={isMobileBrowser}
                            onMouseEnter={() => {}}
                            isPartner={true}
                            popperConfig={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-20, 0], // Adjust these values as needed to move the popover slightly to the left
                                  },
                                },
                              ],
                            }}
                            component={
                              <>
                                <span className='two-line-text'>
                                  {item.description}
                                </span>
                              </>
                            }
                          >
                            <img
                              className={'new-info-icon'}
                              style={{
                                marginLeft: '0.7rem',
                                marginTop: '0.25rem',
                                paddingBottom: '0px',
                                maxHeight: '13px',
                              }}
                              src='/images/icons/new-info-icon.png'
                              alt=''
                            />
                          </TooltipOnHover>
                        </div>
                        <span>{`${numberWithCommas(
                          getFormattedPrice(item.amount)
                        )} ${supplierCurrency}`}</span>
                      </div>
                    </div>
                  </div>
                )
              })}

              {!dispatch ||
              (dispatch &&
                supplierInfo &&
                dispatch.supplierId !== supplierInfo.supplierId) ? (
                <div
                  className='d-flex justify-content-end'
                  style={{ marginTop: '0.3rem', fontSize: '14px' }}
                >
                  <button
                    style={{ marginTop: '0.938rem' }}
                    className='btn btn-anchor btn-search ml-3'
                    type='button'
                    onClick={() =>
                      updateChargesModal(notSentCharges, 'notSent')
                    }
                  >
                    Send earning(s) to partner
                  </button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {totalOutSourced ? (
            <>
              <div
                style={{ marginTop: '0.5em' }}
                className='driver-service-card-body'
              >
                <div style={{ padding: '1em', fontSize: '15px' }}>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                      <span
                        className='title mr-2'
                        style={{ whiteSpace: 'pre' }}
                      >
                        Total price
                      </span>
                    </div>
                    <span>{`${numberWithCommas(
                      getFormattedPrice(totalAmount)
                    )} ${supplierCurrency}`}</span>
                  </div>

                  <div className='d-flex justify-content-between  mb-2'>
                    <span className='title'>Total outsourced price</span>
                    <span style={{ marginLeft: '8px' }}>
                      {supplierInfo &&
                        numberWithCommas(
                          getFormattedPrice(totalOutSourced)
                        )}{' '}
                      {supplierInfo &&
                        getLabelFromValue(
                          defaultCurrencies,
                          supplierInfo.supplierCurrency
                        )}
                    </span>
                  </div>

                  <div className='d-flex justify-content-between  mb-2'>
                    <span className='title'>Total margin</span>
                    <span style={{ marginLeft: '8px' }}>
                      {supplierInfo &&
                        numberWithCommas(getFormattedPrice(totalMargin))}{' '}
                      {supplierInfo &&
                        getLabelFromValue(
                          defaultCurrencies,
                          supplierInfo.supplierCurrency
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <AdditionalChargesModal
            show={showChargesModal}
            onHide={() => {
              setShowChargesModal(false)
              setRenderedCharges([])
            }}
            supplierCurrency={supplierInfo.supplierCurrency}
            charges={renderedCharges}
            bookingId={bookingId}
            bookingPartner={bookingPartner}
            reloadList={reloadList}
          />
          <UpdateOutsourcePrice
            show={showOutsourceModal}
            onHide={() => {
              setShowOutsourceModal(false)
            }}
            partnerName={bookingPartner && bookingPartner.name}
            supplierCurrency={supplierInfo.supplierCurrency}
            bookingId={bookingId}
            updateBooking={reloadList}
            originalPrice={supplierInfo.buyingPrice}
            buyingPrice={
              supplierInfo.outsourceRate
                ? parseFloat(supplierInfo.outsourceRate.toFixed(2))
                : supplierInfo.dispatchWeight
                ? parseFloat(
                    supplierInfo.buyingPrice -
                      supplierInfo.buyingPrice *
                        (supplierInfo.dispatchWeight / 100)
                  ).toFixed(2)
                : supplierInfo.buyingPrice
            }
          />
        </SmallDown>
      </>
    )
  }
  const rednerPartner = () => {
    let totalAmount = parseFloat(buyingPrice)
    return (
      <>
        {!isMobileBrowser ? (
          <>
            <div className='driver-service-card-header'>
              <span>Price</span>
            </div>
            <div className='driver-service-card-body'>
              <div className='driver-service-additionlCharges'>
                <div className='d-flex justify-content-between'>
                  <span className='title'>{`${serviceType}`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(buyingPrice)
                  )} ${supplierCurrency}`}</span>
                </div>
              </div>
            </div>
            {charges.map((item, index) => {
              if (item.amount) {
                totalAmount += parseFloat(item.amount)
              }
              return (
                <div
                  style={{ marginTop: '0.5em' }}
                  className='driver-service-card-body'
                >
                  <div className='driver-service-additionlCharges'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex'>
                        <span
                          className='title mr-2'
                          style={{ whiteSpace: 'pre' }}
                        >{`Additional earning ${index + 1}`}</span>
                        <TooltipOnHover
                          delay={200}
                          placement='right-start'
                          isMobileBrowser={false}
                          onMouseEnter={() => {}}
                          isPartner={true}
                          component={
                            <>
                              <span className='two-line-text'>
                                {item.description}
                              </span>
                            </>
                          }
                        >
                          <img
                            className={'new-info-icon'}
                            style={{
                              marginLeft: '0.7rem',
                              marginTop: '0.25rem',
                              paddingBottom: '0px',
                              maxHeight: '13px',
                            }}
                            src='/images/icons/new-info-icon.png'
                            alt=''
                          />
                        </TooltipOnHover>
                      </div>
                      <span>{`${numberWithCommas(
                        getFormattedPrice(item.amount)
                      )} ${supplierCurrency}`}</span>
                    </div>
                  </div>
                </div>
              )
            })}
            {totalAmount ? (
              <>
                <div
                  style={{ marginTop: '0.5em' }}
                  className='driver-service-card-body'
                >
                  <div style={{ padding: '1em', fontSize: '15px' }}>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex'>
                        <span
                          className='title mr-2'
                          style={{ whiteSpace: 'pre' }}
                        >
                          Total price
                        </span>
                      </div>
                      <span>{`${numberWithCommas(
                        getFormattedPrice(totalAmount)
                      )} ${supplierCurrency}`}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className='driver-service-card-header'>
              <span>Price</span>
            </div>
            <div className='driver-service-card-body'>
              <div className='driver-service-additionlCharges'>
                <div className='d-flex justify-content-between'>
                  <span className='title'>{`${serviceType}`}</span>
                  <span>{`${numberWithCommas(
                    getFormattedPrice(buyingPrice)
                  )} ${supplierCurrency}`}</span>
                </div>
              </div>
            </div>
            {charges.map((item, index) => {
              if (item.amount) {
                totalAmount += parseFloat(item.amount)
              }
              return (
                <div
                  style={{ marginTop: '0.5em' }}
                  className='driver-service-card-body'
                >
                  <div className='driver-service-additionlCharges'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex'>
                        <span
                          className='title mr-2'
                          style={{ whiteSpace: 'pre' }}
                        >{`Additional earning ${index + 1}`}</span>
                        <TooltipOnHover
                          delay={200}
                          placement='bottom'
                          isMobileBrowser={isMobileBrowser}
                          onMouseEnter={() => {}}
                          isPartner={true}
                          popperConfig={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [-20, 0], // Adjust these values as needed to move the popover slightly to the left
                                },
                              },
                            ],
                          }}
                          component={
                            <>
                              <span className='two-line-text'>
                                {item.description}
                              </span>
                            </>
                          }
                        >
                          <img
                            className={'new-info-icon'}
                            style={{
                              marginLeft: '0.7rem',
                              marginTop: '0.25rem',
                              paddingBottom: '0px',
                              maxHeight: '13px',
                            }}
                            src='/images/icons/new-info-icon.png'
                            alt=''
                          />
                        </TooltipOnHover>
                      </div>
                      <span>{`${numberWithCommas(
                        getFormattedPrice(item.amount)
                      )} ${supplierCurrency}`}</span>
                    </div>
                  </div>
                </div>
              )
            })}
            {totalAmount ? (
              <>
                <div
                  style={{ marginTop: '0.5em' }}
                  className='driver-service-card-body'
                >
                  <div style={{ padding: '1em', fontSize: '15px' }}>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex'>
                        <span
                          className='title mr-2'
                          style={{ whiteSpace: 'pre' }}
                        >
                          Total price
                        </span>
                      </div>
                      <span>{`${numberWithCommas(
                        getFormattedPrice(totalAmount)
                      )} ${supplierCurrency}`}</span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {isLocalSupplier ? <>{renderLocalSupplier()}</> : <>{rednerPartner()}</>}
    </>
  )
}

export default PartnerPricingDetails
