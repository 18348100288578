import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { usePagination, DOTS } from 'hooks/usePagination'
import { paginationPerPageOptions } from '../constants/index'

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    setPagination,
    pageSize,
    setPageSize,
    className,
    list,
  } = props

  const [openDropdown, setOpenDropdown] = useState(false)

  const handleChangePerPageData = newPerPageNumber => {
    setPageSize(newPerPageNumber)
    setPagination(prev => ({
      ...prev,
      page: 1,
    }))
  }

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  let lastPage = paginationRange[paginationRange.length - 1]

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  // const prevList = usePrevious(list);

  const paginationInfo = () => {
    let firstItem = pageSize * (currentPage - 1) + 1
    let lastItem = firstItem + (list.length - 1)
    if (lastItem > totalCount) {
      lastItem = totalCount
    }
    if (firstItem === lastItem) {
      return `${firstItem} of ${totalCount}`
    }
    return `${firstItem}-${lastItem} of ${totalCount}`
  }

  return (
    <div className='d-flex align-items-center flex-column-reverse flex-md-row'>
      <div
        onClick={() => setOpenDropdown(prev => !prev)}
        className='pagination-info-container'
      >
        {paginationInfo()}
        <img src='/images/icons/dropdown-small-arrow.svg' alt='' />
        {openDropdown && paginationPerPageOptions.length ? (
          <div className='pagination-options-container'>
            {paginationPerPageOptions.map(opt => {
              return (
                <div
                  key={opt}
                  className={classnames('pagination-option', {
                    selected: pageSize === opt,
                  })}
                  onClick={() => handleChangePerPageData(opt)}
                >
                  {opt} per page
                </div>
              )
            })}
          </div>
        ) : null}
      </div>
      <ul
        className={classnames('pagination-container mb-0', {
          [className]: className,
        })}
      >
        <li
          className={classnames('pagination-item arrow', {
            disabled: currentPage === 1,
          })}
          onClick={() => onPageChange(1)}
        >
          {currentPage === 1 ? (
            <img
              src='/images/icons/inactive-double-arrow-left.svg'
              alt=''
              className='arrow-left'
            />
          ) : (
            <img
              src='/images/icons/active-double-arrow-left.svg'
              alt=''
              className='arrow-left'
            />
          )}
        </li>
        <li
          className={classnames('pagination-item arrow', {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          {currentPage === 1 ? (
            <img
              src='/images/icons/inactive-arrow-left.svg'
              alt=''
              className='arrow-left'
            />
          ) : (
            <img
              src='/images/icons/arrow-left.svg'
              alt=''
              className='arrow-left'
            />
          )}
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber + index} className='pagination-item dots'>
                &#8230;
              </li>
            )
          }

          return (
            <li
              key={pageNumber}
              className={classnames('pagination-item page', {
                selected: pageNumber === currentPage,
                'mr-2': pageNumber !== lastPage,
                'width-fit': pageNumber > 99,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          )
        })}

        <li
          className={classnames('pagination-item arrow', {
            disabled: currentPage === lastPage,
          })}
          onClick={() => onNext(currentPage + 1)}
        >
          {currentPage === lastPage ? (
            <img
              src='/images/icons/inactive-arrow-right.svg'
              alt=''
              className='arrow-right'
            />
          ) : (
            <img
              src='/images/icons/arrow-right.svg'
              alt=''
              className='arrow-right'
            />
          )}
        </li>
        <li
          className={classnames('pagination-item arrow', {
            disabled: currentPage === lastPage,
          })}
          onClick={() => onPageChange(lastPage)}
        >
          {currentPage === lastPage ? (
            <img
              src='/images/icons/inactive-double-arrow-right.svg'
              alt=''
              className='arrow-right'
            />
          ) : (
            <img
              src='/images/icons/active-double-arrow-right.svg'
              alt=''
              className='arrow-right'
            />
          )}
        </li>
      </ul>
    </div>
  )
}

export default Pagination
