import React, { useEffect, useRef, useCallback, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DefaultLayout from '../../../../components/layout/DefaultLayout'
import ChatRoom from './ChatRoom/ChatRoom'
import {
  createBooking,
  fetchNewChat,
  fetchNewChat_LS,
  getPrivateFileURL,
  markAsReadMessageInChatWithAdmin,
  unsetTyping,
  logger,
} from '../../../../actions'
import { SmallUp } from '../../../../components/Responsive/breakpoints'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import findIndex from 'lodash/findIndex'

const ChatClient = ({
  chatsList,
  markAsReadMessageInChatWithAdmin,
  unsetTyping,
  getPrivateFileURL,
  company,
  history,
  createBooking,
  fetchNewChat,
  fetchNewChat_LS,
  isPanel,
  isExpanded,
  closePanel,
  lastMessage,
  editMsg,
  vehicleSearch,
  logger,
  activeUserId,
}) => {
  const [activeChat, setActiveChat] = useState()
  const [page, setPage] = useState(1)
  const [isLoadingChats, setLoadingChats] = useState(false)
  const [isVehicleSearch, setIsVehicleSearch] = useState(null)
  const [currentPath, setCurrentPath] = useState(null)
  const listRef = useRef(0)
  useEffect(() => {
    if (vehicleSearch && vehicleSearch.isLateSearch) {
      setIsVehicleSearch(vehicleSearch)
    } else {
      setIsVehicleSearch(null)
    }
  }, [vehicleSearch])
  const readAllMessages = useCallback(
    (found = null) => {
      if (!activeChat && !found) {
        return
      }
      var chats =
        found &&
        activeChat &&
        activeChat.messages.length > found.messages.length
          ? activeChat
          : found &&
            activeChat &&
            activeChat.messages.length > found.messages.length
          ? found
          : activeChat || found
      chats.messages.forEach((msg, i) => {
        if (!msg.id) {
          return
        }
        if (!msg.readByUser) {
          markAsReadMessageInChatWithAdmin(chats.id, msg.id)
        }
      })
    },
    [markAsReadMessageInChatWithAdmin, activeChat]
  )

  useEffect(() => {
    if (window.location.pathname == '/support/new-booking') setCurrentPath(true)
    const fetch = async id => {
      try {
        // await fetchNewChat(id, page)
        await fetchNewChat_LS(id, page, activeUserId)
      } catch (err) {
        logger({ fileName: 'chatClient', error: err })
        console.log(err)
      }
      setLoadingChats(false)
    }
    const found = chatsList.find(chat => chat.type === 'chat_with_admin')
    if (!found) {
      return
    }
    if (isPanel && isExpanded) {
      readAllMessages()
    } else if (!isPanel && !isExpanded && listRef.current === 0) {
      readAllMessages()
    }
    if (activeUserId) {
      fetch(found.id)
    }
  }, [page, isPanel, isExpanded, listRef, activeUserId])

  const title = window.innerWidth < 991 ? 'Support 24/7' : ''

  useEffect(() => {
    const fetch = async id => {
      try {
        // await fetchNewChat(id, page)
        await fetchNewChat_LS(id, page, activeUserId)
      } catch (err) {
        logger({ fileName: 'chatClient', error: err })
        console.log(err)
      }
      setLoadingChats(false)
    }

    const found = chatsList.find(chat => chat.type === 'chat_with_admin')

    if (found && !found.messages && found.id) {
      fetch(found.id)
    }

    if (found && found.messages && activeChat != found) {
      if (editMsg) {
        const msgIndex = findIndex(found.messages, function(o) {
          return o.id === editMsg.id
        })
        if (msgIndex !== -1) {
          found.messages[msgIndex] = editMsg
          readAllMessages(found)
          setActiveChat(found)
        }
      } else {
        if (isPanel && isExpanded) {
          readAllMessages(found)
        } else if (!isPanel && !isExpanded && listRef.current === 0) {
          readAllMessages(found)
        }
        setActiveChat(found)
      }
    }
  }, [editMsg, chatsList, isPanel, isExpanded, listRef])

  function readMoreMessage() {
    if (!isLoadingChats && activeChat.totalMessages > page * 10) {
      setLoadingChats(true)
      setPage(page + 1)
    }
  }

  if (isPanel) {
    return (
      <div
        ref={listRef}
        id='support-chat'
        className={
          !currentPath
            ? `support-panel-wrapper ${
                isExpanded ? 'support-panel-visible' : 'support-panel-hidden'
              }`
            : `support-panel-wrapper ${
                isExpanded ? 'support-panel-hidden' : 'support-panel-visible'
              }`
        }
      >
        <div className='support-panel-titlebar'>
          {/* <div className='support-panel-title'>Support</div> */}
          <div className='support-panel-title'>Support 24/7</div>
          <div>
            <img
              className='support-panel-img'
              src='/images/icon_close.png'
              alt=''
              onClick={() => {
                if (currentPath) history.replace('/booking')
                else closePanel()
              }}
            />
          </div>
        </div>
        <SmallUp>
          <div className='support-chat'>
            <div className='client-chat-container'>
              {activeChat ? (
                <ChatRoom
                  companyLogo={!!company ? company.logo : null}
                  messages={activeChat.messages}
                  unread={activeChat.unreadByUserMessagesCount}
                  lastMessageDate={activeChat.updatedAt}
                  lastMessage={lastMessage}
                  attachedAdmin={activeChat.attachedAdmin}
                  chatId={activeChat.id}
                  chatUser={activeChat.user}
                  isTyping={activeChat.isTyping}
                  markAsRead={markAsReadMessageInChatWithAdmin}
                  unsetTyping={unsetTyping}
                  getPrivateFileURL={getPrivateFileURL}
                  history={history}
                  createBooking={createBooking}
                  isPanel={isPanel}
                  readMoreMessage={readMoreMessage}
                  currentPage={page}
                  isLoading={isLoadingChats}
                  vehicleSearch={isVehicleSearch}
                />
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>
        </SmallUp>
      </div>
    )
  }

  function updateWindowSize() {
    window.lastInnerWidth = window.innerWidth
    window.lastInnerHeight = window.innerHeight
  }

  function detectKeyboard() {
    // No orientation change, keyboard opening
    if (
      window.lastInnerHeight - window.innerHeight > 150 &&
      window.innerWidth === window.lastInnerWidth
    ) {
      var keyboardHeight = window.lastInnerHeight - window.innerHeight
      updateWindowSize()
      return keyboardHeight
    }

    // No orientation change, keyboard closing
    if (
      window.innerHeight - window.lastInnerHeight > 150 &&
      window.innerWidth === window.lastInnerWidth
    ) {
      var keyboardHeight = -1
      updateWindowSize()
      return keyboardHeight
    }

    // Orientation change or regular resize, no keyboard action
    var keyboardHeight = 0
    updateWindowSize()
    return keyboardHeight
  }

  function keyboardShift(keyboardHeight) {
    setTimeout(() => {
      document
        .getElementsByClassName('app-content')[0]
        .classList.add('keyboard-open')
    }, 200)
  }
  function removeKeyboardShift() {
    setTimeout(() => {
      document
        .getElementsByClassName('app-content')[0]
        .classList.remove('keyboard-open')
    }, 100)
  }

  var resizeTimeout
  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function() {
        resizeTimeout = null
        actualResizeHandler()
        // The actualResizeHandler will execute at a rate of 15fps
      }, 66)
    }
  }

  function actualResizeHandler() {
    var keyboardHeight = detectKeyboard()
    if (keyboardHeight > 0) {
      keyboardShift(keyboardHeight)
    } else if (keyboardHeight === -1) {
      removeKeyboardShift()
    }
  }

  updateWindowSize()
  window.addEventListener('resize', resizeThrottler, false)

  return (
    <DefaultLayout
      className='nested-layout light-theme client-chat-layout show-header-line'
      showClose
      title={title}
      hideSidebar={true}
      ref={listRef}
      isChatNotification
    >
      <div className='client-chat-container'>
        {activeChat ? (
          <ChatRoom
            companyLogo={company ? company.logo : ''}
            messages={activeChat.messages}
            unread={activeChat.unreadByUserMessagesCount}
            lastMessageDate={activeChat.updatedAt}
            lastMessage={lastMessage}
            attachedAdmin={activeChat.attachedAdmin}
            chatId={activeChat.id}
            chatUser={activeChat.user}
            isTyping={activeChat.isTyping}
            markAsRead={markAsReadMessageInChatWithAdmin}
            unsetTyping={unsetTyping}
            getPrivateFileURL={getPrivateFileURL}
            history={history}
            createBooking={createBooking}
            readMoreMessage={readMoreMessage}
            currentPage={page}
            isLoading={isLoadingChats}
            vehicleSearch={isVehicleSearch}
          />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </DefaultLayout>
  )
}

const mapStateToProps = ({ appWebsocket, auth, vehicleSearch }) => ({
  lastMessage: appWebsocket.lastMessage,
  editMsg: appWebsocket.editMsg,
  chatsList: appWebsocket.chatsList,
  company: auth.company,
  vehicleSearch: vehicleSearch,
  activeUserId: appWebsocket.activeUserId,
})

export default withRouter(
  connect(mapStateToProps, {
    markAsReadMessageInChatWithAdmin,
    unsetTyping,
    getPrivateFileURL,
    createBooking,
    fetchNewChat,
    fetchNewChat_LS,
    logger,
  })(ChatClient)
)
