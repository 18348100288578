import React from 'react'
import { Button, Image } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMessageClose } from '../actions'
import MatRaisedBtn from './MatRaisedBtn'
import { SmallDown, SmallUp } from './Responsive/breakpoints'

const SuccessMsg = ({
  className = null,
  alertData,
  setMessageClose,
  closeHandler,
  bookHandler,
  icon = '/images/icons/success_icon.png',
  isVerifyEmail = false,
  isRegisterEmail = false,
  isLogin = false,
  isPayment = false,
}) => {
  if (alertData === null) {
    return <></>
  }

  const onClose = () => {
    setMessageClose()
    if (closeHandler) {
      closeHandler()
    }
  }

  const onRenderBook = type => {
    setMessageClose()
    if (bookHandler) {
      bookHandler(type)
    }
  }

  const title = alertData.title || 'Operation has executed success!'
  const message = alertData.message || ''
  const textLink = alertData.textLink || 'Go back to Dashboard'
  const airportFrom = alertData.airportFrom
  const airportTo = alertData.airportTo
  const showDoorToDoor = airportFrom || airportTo
  const returnText =
    alertData.return && !showDoorToDoor ? 'Book a return transfer' : null

  return (
    <div className={className ? className : 'success-msg-modal'}>
      <div className='content-div'>
        <div className='content-wrap'>
          {!alertData.onlyMessage ? <Image src={icon} /> : <></>}
          <h4 className='success-msg-header'>{title}</h4>
          <p className='success-msg-description'>{message}</p>
          {returnText && (
            <div className='text-center'>
              <Button
                className='submit-btn'
                variant='brown'
                onClick={() => onRenderBook(1)}
              >
                {returnText}
              </Button>
            </div>
          )}

          {!isVerifyEmail &&
            !isRegisterEmail &&
            !isLogin &&
            !isPayment &&
            !alertData.onlyMessage && (
              <div className={returnText ? 'text-center mt-2' : 'text-center'}>
                <MatRaisedBtn title={textLink} onClick={onClose} />
              </div>
            )}
        </div>

        {showDoorToDoor ? (
          <div className='door-to-door-div'>
            <span>Book a door-to-door journey</span>
            {airportTo && airportTo.flightInfo && (
              <div className='door-to-door-cards'>
                <div className='door-to-door-card'>
                  <img src='/images/icons/flight.png'></img>
                  <span>Transfer at arrival</span>
                  <span>
                    An effortless airport transfer upon arrival at{' '}
                    {airportTo.flightInfo.arrival.iata} airport.
                  </span>
                  <SmallUp>
                    <div className='text-center book-btn'>
                      <Button
                        className='submit-btn'
                        variant='brown'
                        onClick={() => onRenderBook(2)}
                      >
                        Book
                      </Button>
                    </div>
                  </SmallUp>
                  <SmallDown>
                    <div className='book-text' onClick={() => onRenderBook(2)}>
                      <p className=' m-0'>Book now</p>
                      <img
                        src='/images/icons/arrow-right-gold.svg'
                        alt='arrow'
                      />
                    </div>
                  </SmallDown>
                </div>
                <div className='door-to-door-card'>
                  <img src='/images/icons/hour.png'></img>
                  <span>By the hour at arrival</span>
                  <span>
                    Flexible hourly chauffeur services while in{' '}
                    {airportTo.getCity && airportTo.getCity.arrivalInfo
                      ? airportTo.getCity.arrivalInfo.city
                      : ''}
                    .
                  </span>
                  <SmallUp>
                    <div className='text-center book-btn'>
                      <Button
                        className='submit-btn'
                        variant='brown'
                        onClick={() => onRenderBook(3)}
                      >
                        Book
                      </Button>
                    </div>
                  </SmallUp>
                  <SmallDown>
                    <div className='book-text' onClick={() => onRenderBook(3)}>
                      <p className=' m-0'>Book now</p>
                      <img
                        src='/images/icons/arrow-right-gold.svg'
                        alt='arrow'
                      />
                    </div>
                  </SmallDown>
                </div>
              </div>
            )}
            {airportFrom && (
              <div className='door-to-door-cards'>
                <div className='door-to-door-card'>
                  <img src='/images/icons/hour.png'></img>
                  <span>By the hour</span>
                  <span>
                    Flexible hourly chauffeur services while in{' '}
                    {airportFrom.city}.
                  </span>
                  <SmallUp>
                    <div className='text-center book-btn'>
                      <Button
                        className='submit-btn'
                        variant='brown'
                        onClick={() => onRenderBook(4)}
                      >
                        Book
                      </Button>
                    </div>
                  </SmallUp>
                  <SmallDown>
                    <div className='book-text' onClick={() => onRenderBook(4)}>
                      <p className=' m-0'>Book now</p>
                      <img
                        src='/images/icons/arrow-right-gold.svg'
                        alt='arrow'
                      />
                    </div>
                  </SmallDown>
                </div>
                <div className='door-to-door-card'>
                  <img src='/images/icons/return.png'></img>
                  <span>Return transfer</span>
                  <span>A seamless airport transfer upon departure.</span>
                  <SmallUp>
                    <div className='text-center book-btn'>
                      <Button
                        className='submit-btn'
                        variant='brown'
                        onClick={() => onRenderBook(1)}
                      >
                        Book
                      </Button>
                    </div>
                  </SmallUp>
                  <SmallDown>
                    <div className='book-text' onClick={() => onRenderBook(1)}>
                      <p className=' m-0'>Book now</p>
                      <img
                        src='/images/icons/arrow-right-gold.svg'
                        alt='arrow'
                      />
                    </div>
                  </SmallDown>
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ successMsg: { alertData } }) => {
  return {
    alertData,
  }
}

export default withRouter(
  connect(mapStateToProps, { setMessageClose })(SuccessMsg)
)
