import React from 'react'
import { Link } from 'react-router-dom'
import MatRaisedLink from './MatRaisedLink'

export default function NoData({
  className = '',
  imageClassName = '',
  title,
  image,
  width,
  description,
  pageLink,
  linkText,
  isPartner = false,
  isPayment = false,
  showPaymentForm = () => {},
  isTeamInvite = false,
  showInviteForm = () => {},
}) {
  return (
    <div
      className={`text-center no-data-wrap ${className}`}
      style={isPartner ? { marginTop: '95px' } : {}}
    >
      <div
        className={`no-data-img-wrap ${imageClassName}`}
        style={
          title === 'No referrals yet' ? { width: '64px', height: '64px' } : {}
        }
      >
        <img src={image} alt='' width={width || '50%'} />
      </div>
      <h4 className='no-data-header'>{title}</h4>
      <p className='no-data-description'>{description}</p>
      {pageLink && linkText ? (
        <MatRaisedLink className='no-data-link' node={Link} to={pageLink}>
          {linkText}
        </MatRaisedLink>
      ) : (
        <>
          {isPayment ? (
            <MatRaisedLink
              className={`btn btn-anchor btn-search align-self-start no-data-link`}
              node={Link}
              to={`/payments/add-payment-method`}
            >
              Add a payment method
            </MatRaisedLink>
          ) : (
            // <button
            //   style={{ marginTop: '7px' }}
            //   className={`btn btn-anchor btn-search align-self-start no-data-link`}
            //   type='button'
            //   onClick={() => showPaymentForm()}
            // >
            //   Add a payment method
            // </button>
            <>
              {isTeamInvite ? (
                <button
                  className={`add-link text-brown btn btn-anchor no-data-link`}
                  onClick={showInviteForm}
                >
                  {linkText}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
