import React, { useState, useMemo, useEffect, useCallback } from 'react'
import moment from 'moment'
import { connectedApiService as api } from '../../../../../index'
import { useParams } from 'react-router'
import { useGetTableData } from '../../../../../hooks'
import {
  getFormattedPrice,
  getFormattedTripInfo,
  getLabelFromValue,
  numberWithCommas,
  formatSentenceCase,
  isMobile,
} from '../../../../../helpers'
import { connect } from 'react-redux'
import { withRouter, useLocation, Link } from 'react-router-dom'
import {
  acceptOffer,
  acceptOfferOp,
  declineOffer,
  acceptPartnerBooking,
  generatePdfBoard,
  bookingChangesPartner,
  updateChangesStatusPartner,
  storeFlightIcao,
} from '../../../../../actions/partners'
import {
  cancelDispatchBooking,
  cancelDispatchBookingMobile,
  getBookingTimeZone,
} from '../../../../../actions/booking'
import {
  defaultBookingServicePartner,
  defaultCurrencies,
  partnerHostLive,
  partnerHostStaging,
} from '../../../../../constants'
import { SmallUp, SmallDown } from 'components/Responsive/breakpoints'
import ConfirmationModal from '../../../../../components/confirmationModal'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import LocationType from '../../../../../components/locationType'
import { Col, Row, Button, Badge, Modal } from 'react-bootstrap'
import DefaultDriverDesktopLayout from 'components/layout/DefaultDriverDesktopLayout'
import invoke from 'react-native-webview-invoke/browser'
import _ from 'lodash'
import Support from '../User/Support'
import PartnerAcceptedModal from '../../../../../components/PartnerAcceptedModal'
import {
  getContinent,
  getPrivatePartnerFileURL,
  logger,
  switchFlightApiKey,
} from '../../../../../actions'
import { UAParser } from 'ua-parser-js'
import DeclineOfferModal from 'components/DeclineOfferModal'
import AdditionalCharges from 'components/DriversApp/AdditionalCharges'
import { unitSystems } from 'constants/CountryList'
import DispatchPartnerModal from 'components/DriversApp/DispatchPartnerModal'
import { Formik } from 'formik'
import FormField from 'components/FormField'
import DispatchCancelModal from 'pages/App/SystemPages/Bookings/BookingDetails/DispatchCancelModal'
import DispatchPartnerModalMobile from 'components/DriversApp/DispatchPartnerModalMobile'
import AddLink from 'components/AddLink'
import AdditionalChargesModalMobile from 'components/DriversApp/AdditionalChargesModalMobile'
import AdditionalChargesModal from 'components/DriversApp/AdditionalChargesModal'
import PartnerPricingDetails from 'components/DriversApp/PartnerPricingDetails'
const Host = window.location.hostname
const dateFormatDetailed = 'ddd, Do MMM YYYY'
const timeFormat = 'LT'
const newTimeFormat = 'HH:mm'
const apiHost = process.env.REACT_APP_APIHOST

const formattedString = suppliers => {
  return suppliers.map((item, index, array) => {
    if (index === array.length - 1) {
      return item.name + '.'
    } else {
      return item.name + ', '
    }
  })
}

const isMobileBrowser = isMobile()
const BookingDetails = ({
  history,
  acceptOffer,
  acceptOfferOp,
  declineOffer,
  getBookingTimeZone,
  acceptPartnerBooking,
  generatePdfBoard,
  getPrivatePartnerFileURL,
  switchFlightApiKey,
  bookingChangesPartner,
  updateChangesStatusPartner,
  storeFlightIcao,
  getContinent,
  cancelDispatchBooking,
  cancelDispatchBookingMobile,
  isLocalSupplier,
  companyId,
}) => {
  const { bookingId, token } = useParams()

  const {
    data: { list },
    reloadList,
  } = useGetTableData(`external/partnerBooking/${bookingId}/${token}`)

  const listExists = useMemo(() => {
    return Array.isArray(list) ? list.length > 0 : !!list
  }, [list])

  const { data } = useGetTableData(`external/partner/${token}`)

  const {
    data: { list: tokenUser },
  } = useGetTableData(`external/partnerToken/${token}`)

  let location = useLocation()
  let currentLocation = location.pathname

  const [selectedTab, setSelectedTab] = useState(() => {
    return currentLocation && currentLocation.includes('trip') ? 1 : 0
  })

  const [driverExists, setDriverExists] = useState(false)
  const [updateOnTheWay, setUpdateOnTheWay] = useState(null)
  const [bookingTimeLeft, setBookingTimeLeft] = useState(null)
  const [updateOnLocation, setUpdateOnLocation] = useState(null)
  const [updateOnLocationAtStop, setUpdateOnLocationAtStop] = useState(null)
  const [updatePassengerOnboard, setUpdatePassengerOnboard] = useState(null)
  const [updateTenMinsAway, setUpdateTenMinsAway] = useState(null)
  const [updatePassengerDroppedOff, setUpdatePassengerDroppedOff] = useState(
    null
  )
  const [updateVehicleDelivered, setUpdateVehicleDelivered] = useState(null)
  const [updateDayCompleted, setUpdateDayCompleted] = useState(null)
  const [proSteps, setProSteps] = useState([])
  const [step, setStep] = useState(0)
  const [isSubmitting, setSubmitting] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [
    showDeclineConfirmationModal,
    setShowDeclineConfirmationModal,
  ] = useState(false)
  const [
    showDeclineChangesConfirmationModal,
    setShowDeclineChangesConfirmationModal,
  ] = useState(false)
  const [displayAlertText, setDisplayAlertText] = useState('')
  const [displayAlertTitle, setDisplayAlertTitle] = useState('')
  const [bookingInTwoHours, setBookingInTwoHours] = useState(false)
  const [bookingInOneDay, setBookingInOneDay] = useState(false)
  const [isCarRental, setIsCarRental] = useState(false)
  const [details, setDetails] = useState(null)
  const [displaySupport, setDisplaySupport] = useState(false)
  const [geoData, setGeoData] = useState(null)
  const [flightIcao, setFlightIcao] = useState(null)
  const [depFlightIcao, setDepFlightIcao] = useState(null)
  const [currentDay, setCurrentDay] = useState(1)
  const [bookingTimer, setBookingTimer] = useState(null)
  const [bookingTimerAfter, setBookingTimerAfter] = useState(null)
  const [showAcceptedModal, setShowAcceptedModal] = useState(false)
  const [showDeclinedModal, setShowDeclinedModal] = useState(false)
  const [showAccptedChangesModal, setShowAccptedChangesModal] = useState(false)
  const [showDeclinedChangesModal, setShowDeclinedChangesModal] = useState(
    false
  )
  const [showPdf, setShowPdf] = useState(false)
  const [pdfLink, setPdfLink] = useState('')
  const [passengerCapacity, setPassengerCapacity] = useState(null)
  const [luggageCapacity, setLuggageCapacity] = useState(null)
  const [bookingChanges, setBookingChanges] = useState([])
  const [thereAreNewChanges, setThereAreNewChanges] = useState(false)
  const [locationType, setLocationType] = useState(null)
  const [buttonLabels, setButtonLabels] = useState([
    'On the way',
    'On location',
    'Passenger on board',
    'Passenger dropped off',
  ])
  const [actionTypes, setActionTypes] = useState([
    'on_the_way',
    'on_location',
    'passenger_onboard',
    'passenger_dropped_off',
  ])
  const [continent, setContinent] = useState(null)
  const [dayCollapsibleArray, setDayCollapsibleArray] = useState([])
  const [showTenMinsAwayUpdate, setShowTenMinsAwayUpdate] = useState(false)

  //LocalSupplier
  const [showDispatchModal, setshowDispatchModal] = useState(false)
  const [opSupplierPrice, setOpSupplierPrice] = useState(0)
  const [suppliersList, setSuppliersList] = useState([])
  const [showDispatchCancelModal, setshowDispatchCancelModal] = useState(false)
  const [showAddChargeModal, setShowAddChargeModal] = useState(false)

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const url = `external/partnerToken/${token}`
        const { data } = await api.get(url)
        setDetails(data)
      } catch (err) {
        console.log('checkTokenValidity error', err)
        logger({ fileName: 'checkTokenValidity error', error: err })
      }
    }
    checkTokenValidity()
  }, [])

  useEffect(() => {
    const fetchFlightIcao = async (isPickUp = true) => {
      if (!list.flightCodes) {
        let flightApiInfo = await fetch(
          `https://api.aviationstack.com/v1/flights?flight_iata=${
            isPickUp
              ? list.airportPickUpInfo.flightNumber
              : list.airportDropOffInfo.flightNumber
          }&access_key=8aefba86e102b8a89cff6e0f0f748ed1`
        )
        let flightInfo = await flightApiInfo.json()
        if (
          flightInfo.error &&
          flightInfo.error.code === 'usage_limit_reached'
        ) {
          // if (switchKey && switchKey.AVIATION_STACK_250K) {
          //   await switchFlightApiKey()
          // }
          flightApiInfo = await fetch(
            `https://api.aviationstack.com/v1/flights?flight_iata=${
              isPickUp
                ? list.airportPickUpInfo.flightNumber
                : list.airportDropOffInfo.flightNumber
            }&access_key=bcd7be963dc936f8612b9de0b03e71db`
          )
          flightInfo = await flightApiInfo.json()
        }

        if (flightInfo.data && flightInfo.data.length !== 0) {
          const [data] = flightInfo.data
          const { flight } = data

          if (isPickUp) setFlightIcao(flight.icao)
          else setDepFlightIcao(flight.icao)

          if (
            (isPickUp &&
              list.airportPickUpInfo &&
              list.airportPickUpInfo.flightNumber) ||
            (!isPickUp &&
              list.airportDropOffInfo &&
              list.airportDropOffInfo.flightNumber)
          ) {
            let flightCodes = isPickUp
              ? { iata: list.airportPickUpInfo.flightNumber, icao: flight.icao }
              : {
                  iata: list.airportDropOffInfo.flightNumber,
                  icao: flight.icao,
                }
            await storeFlightIcao(
              {
                flightCodes: flightCodes,
                type: isPickUp ? 'pickUp' : 'dropOff',
              },
              bookingId
            )
          }
        }
      } else {
        if (isPickUp) {
          setFlightIcao(
            list.flightCodes.pickUp && list.flightCodes.pickUp.icao
              ? list.flightCodes.pickUp.icao
              : null
          )
        } else {
          setDepFlightIcao(
            list.flightCodes.dropOff && list.flightCodes.dropOff.icao
              ? list.flightCodes.dropOff.icao
              : null
          )
        }
      }
    }
    if (listExists) {
      if (list.type === 'car_rentals') setIsCarRental(true)
      if (list.airportPickUpInfo && list.airportPickUpInfo.flightNumber)
        fetchFlightIcao(true)
      if (list.airportDropOffInfo && list.airportDropOffInfo.flightNumber)
        fetchFlightIcao(false)
      if (list.driverTemp && list.driverTemp.fullName) {
        let driverName = list.driverTemp.fullName
        let names = driverName.toLowerCase().split(' ')
        for (let i = 0; i < names.length; i++) {
          names[i] = names[i].charAt(0).toUpperCase() + names[i].substring(1)
        }
        list.driverTemp.fullName = names.join(' ')
        list.driver = list.driverTemp
      }
      if (list.collectionAgentTemp && list.collectionAgentTemp.fullName) {
        let driverName = list.collectionAgentTemp.fullName
        let names = driverName.toLowerCase().split(' ')
        for (let i = 0; i < names.length; i++) {
          names[i] = names[i].charAt(0).toUpperCase() + names[i].substring(1)
        }
        list.collectionAgentTemp.fullName = names.join(' ')
        list.collectionAgent = list.collectionAgentTemp
      }
      if (list.driver && list.driver.fullName) {
        let driverName = list.driver.fullName
        let names = driverName.toLowerCase().split(' ')
        for (let i = 0; i < names.length; i++) {
          names[i] = names[i].charAt(0).toUpperCase() + names[i].substring(1)
        }
        list.driver.fullName = names.join(' ')
      }
      if (!list.chauffeurOfBooking && !list.partnerOfBooking) {
        if (document.referrer === '') {
          history.push(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/${token}/planned`
          )
        } else window.history.back()
      }
      if (list.globalVehicle) {
        setPassengerCapacity(list.globalVehicle.Passenger)
        setLuggageCapacity(list.globalVehicle.luggage)
      }
    }
  }, [list])

  useEffect(() => {
    try {
      async function fetchContinent() {
        // const { data } = {
        //   ...(await getContinent({ country: list.pickUpLocation.country })),
        // }
        const data = unitSystems.find(obj => {
          return list.pickUpLocation.country === obj.country
        })
        setContinent(data)
        return data
      }
      if (listExists && list.pickUpLocation && list.pickUpLocation.country) {
        const data = fetchContinent()
        setContinent(data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [list, getContinent])

  const changeTab = tab => {
    setSelectedTab(tab)
  }

  const onGetFile = async fileId => {
    try {
      const { url } = await getPrivatePartnerFileURL(fileId)
      if (url) {
        setSubmitting(false)
        setPdfLink(url)
        let parser = new UAParser()
        parser.setUA(navigator.userAgent)
        const browser = parser.getBrowser().name
        const result = parser.getOS()
        const name = result.name
        window.open(url, '_blank')
      }
    } catch (error) {
      console.log('onGetFile error', error)
      logger({ fileName: 'onGetFile error', error: error })
    }
  }

  const onAcceptBooking = async bookingId => {
    try {
      if (bookingId && supplierId) {
        if (list.isOperativeBranch) {
          let info = list.supplierInfo
          let values = {
            supplierId: supplierId,
            buyingPrice: info.buyingPrice,
          }
          await acceptOfferOp(values, bookingId)
        } else {
          await acceptOffer(bookingId, supplierId)
        }

        setShowAcceptedModal(true)
        setTimeout(function() {
          setShowAcceptedModal(false)
        }, 1500)
        reloadList()
      }
    } catch (error) {
      console.log('onAcceptBooking error', error)
      logger({ fileName: 'onAcceptBooking error', error: error })
    }
  }

  const updateStatus = async (changes, bookingId) => {
    await updateChangesStatusPartner(changes, bookingId)
  }

  useEffect(() => {
    if (listExists && list.bookingChanges && list.bookingChanges.length > 0) {
      const [changes] = list.bookingChanges
      setThereAreNewChanges(
        changes.some(
          item =>
            (item.status_partner === 'not_opened' ||
              item.status_partner === 'opened') &&
            item.status_admin === 'sent' &&
            item.type !== 'admin_additionalCharges'
        )
      )
      setBookingChanges(
        changes.filter(
          item =>
            item.status_admin === 'sent' &&
            item.type !== 'admin_additionalCharges'
        )
      )

      const notOpenedChanges = changes.filter(
        item =>
          item.status_admin === 'sent' && item.status_partner === 'not_opened'
      )
      if (notOpenedChanges && notOpenedChanges.length > 0)
        updateStatus({ bookingChanges: notOpenedChanges }, list._id)
    }
  }, [list])

  useEffect(() => {
    if (listExists && list.days && list.days.length) {
      const arr = dayCollapsibleArray
      if (arr && arr.length === 0) {
        for (var i in list.days) {
          arr.push(parseInt(i) === 0)
        }
      }
      setDayCollapsibleArray(arr)
    }

    if (listExists && isLocalSupplier) {
      const getDate = async () => {
        const { data } = await api.get(
          `booking/${list._id}/localSupplier/dispatchBooking`
        )
        setSuppliersList(data)
      }

      if (list.dispatchStatus !== 'not_dispatched') {
        getDate()
      }
    }

    //Supplier Dumb Price
    if (list && list.supplierInfo) {
      let info = list.supplierInfo

      setOpSupplierPrice(
        info.outsourceRate
          ? parseFloat(info.outsourceRate.toFixed(2))
          : info.dispatchWeight
          ? parseFloat(
              info.buyingPrice - info.buyingPrice * (info.dispatchWeight / 100)
            ).toFixed(2)
          : info.buyingPrice
      )
    }
  }, [list, isLocalSupplier])

  const onAcceptBookingChanges = async (
    bookingChanges,
    bookingId,
    supplierId
  ) => {
    try {
      if (bookingId) {
        let values = {
          action: 'accept',
          bookingChanges: bookingChanges,
          supplierId: supplierId,
        }
        setThereAreNewChanges(false)
        await bookingChangesPartner(values, bookingId)
        setShowAccptedChangesModal(true)
        setTimeout(
          function() {
            setShowAccptedChangesModal(false)
          }.bind(this),
          1500
        )
        reloadList()
      }
    } catch (error) {
      console.log('onAcceptBookingChanges', error)
      logger({ fileName: 'onAcceptBookingChanges', error: error })
    }
  }

  const onDeclineBookingChanges = async (
    bookingChanges,
    bookingId,
    supplierId
  ) => {
    try {
      if (bookingId) {
        let values = {
          action: 'decline',
          bookingChanges: bookingChanges,
          supplierId: supplierId,
        }
        setShowDeclineChangesConfirmationModal(false)
        setThereAreNewChanges(false)
        await bookingChangesPartner(values, bookingId)
        setShowDeclinedChangesModal(true)
        setTimeout(
          function() {
            setShowDeclinedChangesModal(false)
            history.push(
              `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${token}/new-bookings`
            )
          }.bind(this),
          1500
        )
      }
    } catch (error) {
      console.log('onDeclineBookingChanges', error)
      logger({ fileName: 'onDeclineBookingChanges', error: error })
    }
  }

  const onAcceptBookingDriver = async bookingId => {
    try {
      if (bookingId) {
        // // const geo = `51.4933,-0.1684`
        // const geo = `00.00-00.00`
        // let obj = {
        //   action: 'accepted',
        //   geoLocation: geo,
        //   isLiveLocation: false,
        // }
        // const res = await acceptPartnerBooking(obj, bookingId)
        // reloadList()
        navigator.geolocation.getCurrentPosition(
          async function(pos) {
            const geo = `${pos.coords.latitude},${pos.coords.longitude}`
            let obj = {
              action: 'accepted',
              geoLocation: geo,
              isLiveLocation: true,
            }
            const res = await acceptPartnerBooking(obj, bookingId)
            reloadList()
          },
          async function(error) {
            if (error.code === error.PERMISSION_DENIED) {
              const geo = `00.00-00.00`
              let obj = {
                action: 'accepted',
                geoLocation: geo,
                isLiveLocation: false,
              }
              const res = await acceptPartnerBooking(obj, bookingId)
              reloadList()
            }
          }
        )
      }
    } catch (error) {
      console.log('BbookingDetails onAcceptBookingDriver error', error)
      logger({
        fileName: 'BookingDetails onAcceptBookingDriver error',
        error: error,
      })
    }
  }

  const sendSMS = async () => {
    try {
      if (list.driver.phone) {
        const url = `external/sendSmsToDriver/${list.driver.phone}`
        await api.get(url)
        reloadList()
      }
    } catch (error) {
      console.log('sendSMS error', error)
      logger({ fileName: 'BookingDetails sendSMS error', error: error })
    }
  }

  const redirectBookings = e => {
    try {
      e.preventDefault()
      if (displaySupport && selectedTab === null) {
        setDisplaySupport(false)
        setSelectedTab(0)
      } else {
        if (document.referrer === '') {
          history.push(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/${token}/planned`
          )
        } else window.history.back()
      }
    } catch (error) {
      console.log('redirectBookings error', error)
      logger({ fileName: 'redirectBookings error', error: error })
    }
  }

  const checkBookingAfterADay = time => {
    if (time) {
      const hours = moment.utc(time).diff(moment(), 'hours')
      return hours > 24
    }
  }

  const assignChauffeur = (isAssignChauffeur = true) => {
    try {
      if (bookingId) {
        if (isAssignChauffeur) {
          history.push(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/chauffeur/${token}/${bookingId}`
          )
        } else {
          history.push(
            `${
              Host === partnerHostLive || Host === partnerHostStaging
                ? '/'
                : '/partner/'
            }driver/app/details/chauffeurAgent/${token}/${bookingId}`
          )
        }
      }
    } catch (error) {
      console.log('assignChauffeur error', error)
      logger({ fileName: 'assignChauffeur error', error: error })
    }
  }

  const displayPickUpInstructions = (
    isCarRental,
    isCommercial,
    isPrivate,
    isMeetGreet,
    isTrain
  ) => {
    if (isCommercial && !isMeetGreet)
      return isCarRental
        ? `The agent must meet the guest(s) in the Arrivals Hall with the name-board.
      `
        : `The chauffeur must meet the guest(s) in the Arrivals Hall with the name-board.`
    else if (isMeetGreet)
      return isCarRental
        ? `The agent must coordinate with the greeter and meet the guest(s) in the Arrivals Hall with the name-board. You will receive the greeter's details closer the pick-up time.
      `
        : `The chauffeur must coordinate with the greeter and meet the guest(s) in the Arrivals Hall with the name-board. You will receive the greeter's details closer the pick-up time.`
    else if (isPrivate)
      return isCarRental
        ? `The agent must deliver the vehicle on the tarmac by the aircraft (if allowed), or meet the guest(s) inside the private terminal.
      `
        : `The chauffeur must pick-up the guest(s) on the tarmac by the aircraft (if permitted), or inside the private terminal.`
    else if (isTrain)
      return isCarRental
        ? `The agent must meet the guest(s) on the train's platform with the name-board (if allowed), or at Arrivals.
      `
        : `The chauffeur must meet the guest(s) on the train's platform with the name-board (if allowed), or at Arrivals.`
  }

  const carRentalsDuration = () => {
    const calculateDuration = (pickupDateStr, dropoffDateStr) => {
      const pickupDate = new Date(pickupDateStr)
      const dropoffDate = new Date(dropoffDateStr)

      const timeDifferenceInMs = dropoffDate - pickupDate
      const timeDifferenceInMinutes = Math.floor(
        timeDifferenceInMs / (1000 * 60)
      )
      let days = Math.floor(timeDifferenceInMinutes / (60 * 24))
      const hours = Math.floor((timeDifferenceInMinutes % (60 * 24)) / 60)
      const minutes = timeDifferenceInMinutes % 60

      if (hours > 2 || (hours === 2 && minutes > 0)) {
        days++
      }
      return days
    }

    if (listExists) return calculateDuration(list.pickUpDate, list.dropOffDate)
  }

  const renderStatusUpdates = () => {
    if (
      bookingTimer &&
      bookingTimer.days === 0 &&
      ((bookingTimer.hours < 1 && !updateOnTheWay) ||
        (bookingTimer.hours === 0 &&
          bookingTimer.minutes < 15 &&
          updateOnTheWay &&
          !updateOnLocation))
    ) {
      return 'Update your status now'
    } else if (
      bookingTimerAfter &&
      bookingTimerAfter.days === 0 &&
      bookingTimerAfter.hours === 0 &&
      bookingTimerAfter.minutes > 30 &&
      updateOnTheWay &&
      updateOnLocation &&
      !updatePassengerOnboard
    ) {
      return 'Passenger still not on board?'
    } else return null
  }
  const generatePdf = async () => {
    setSubmitting(true)
    const { data } = await generatePdfBoard(list._id)
    if (data.nameBoard) {
      onGetFile(data.nameBoard)
    }
  }

  const setTrackFlight = invoke.bind('trackFlight')

  const getTrackFlight = async flightIcao => {
    const callTrackFlight = await setTrackFlight(flightIcao)
  }

  const setNameboard = invoke.bind('openLink')

  const getNameboard = async () => {
    setSubmitting(true)
    const { data } = await generatePdfBoard(list._id)
    setSubmitting(false)
    if (data.nameBoard) {
      const { url } = await getPrivatePartnerFileURL(data.nameBoard)
      const callGetNameboard = await setNameboard(url)
    }
  }

  useEffect(() => {
    if (!list) {
      return
    }

    if (
      list.type === 'airport_transfer' ||
      list.type === 'departure_transfer' ||
      list.type === 'return_transfer' ||
      list.type === 'chauffeur_services'
    ) {
      if (list.durationType !== 'daily' || !list.days) {
        if (showTenMinsAwayUpdate) {
          setProSteps([
            { id: 0, title: 'On my way' },
            { id: 1, title: 'On location' },
            { id: 2, title: 'On board' },
            { id: 3, title: '10 mins away' },
            { id: 4, title: 'Drop off' },
          ])
          setButtonLabels([
            'On the way',
            'On location',
            'Passenger on board',
            `I'm 10 mins away`,
            'Passenger dropped off',
          ])
          setActionTypes([
            'on_the_way',
            'on_location',
            'passenger_onboard',
            'ten_mins_away',
            'passenger_dropped_off',
          ])
        } else {
          setProSteps([
            { id: 0, title: 'On my way' },
            { id: 1, title: 'On location' },
            { id: 2, title: 'On board' },
            { id: 3, title: 'Drop off' },
          ])
        }
      } else {
        if (showTenMinsAwayUpdate) {
          setProSteps([
            { id: 0, title: 'On my way' },
            { id: 1, title: 'On location' },
            { id: 2, title: 'On board' },
            { id: 3, title: '10 mins away' },
            { id: 4, title: 'Drop off' },
            { id: 5, title: 'Mark day complete' },
          ])

          setButtonLabels([
            'On the way',
            'On location',
            'Passenger on board',
            `I'm 10 mins away`,
            'Passenger dropped off',
            'Mark day complete',
          ])
          setActionTypes([
            'on_the_way',
            'on_location',
            'passenger_onboard',
            'ten_mins_away',
            'passenger_dropped_off',
            'completed',
          ])
        } else {
          setProSteps([
            { id: 0, title: 'On my way' },
            { id: 1, title: 'On location' },
            { id: 2, title: 'On board' },
            { id: 3, title: 'Drop off' },
            { id: 4, title: 'Mark day complete' },
          ])

          setButtonLabels([
            'On the way',
            'On location',
            'Passenger on board',
            'Passenger dropped off',
            'Mark day complete',
          ])
          setActionTypes([
            'on_the_way',
            'on_location',
            'passenger_onboard',
            'passenger_dropped_off',
            'completed',
          ])
        }
      }
    } else if (isCarRental) {
      setProSteps([
        { id: 0, title: 'On my way' },
        { id: 1, title: 'On location' },
        { id: 2, title: 'Delivered' },
      ])

      setButtonLabels(["I'm on my way", 'I’m on location', 'Vehicle delivered'])
      setActionTypes(['on_the_way', 'on_location', 'delivered'])
    }
  }, [list, setProSteps])

  useEffect(() => {
    if (!list) {
      return
    }
    if (
      listExists &&
      list.bookingOperations &&
      list.bookingOperations.length > 0 &&
      list.bookingOperations[0].actions.length > 0
    ) {
      if (list.durationType !== 'daily' || !list.days) {
        const lastAction =
          list.bookingOperations[0].actions[
            list.bookingOperations[0].actions.length - 1
          ]
        const idx = actionTypes.findIndex(
          action => action === lastAction.status
        )
        if (idx !== -1) {
          setStep(idx + 1)
        }
      } else {
        var actions = list.bookingOperations[0].actions.filter(function(el) {
          return el.day === currentDay
        })

        if (actions.length > 0) {
          const lastAction = actions[actions.length - 1]
          const idx = actionTypes.findIndex(
            action => action === lastAction.status
          )
          if (idx !== -1) {
            setStep(idx + 1)
          }
        } else {
          var actions = list.bookingOperations[0].actions.filter(function(el) {
            return !el.day
          })
          if (actions.length > 0) {
            const lastAction = actions[actions.length - 1]
            const idx = actionTypes.findIndex(
              action => action === lastAction.status
            )
            if (idx !== -1) {
              setStep(idx + 1)
            } else {
              setStep(0)
            }
          } else {
            setStep(0)
          }
        }
      }
    }
  }, [actionTypes, setStep, list, currentDay])

  const nextStep = useCallback(async () => {
    setSubmitting(true)
    var duration = null
    if (
      listExists &&
      list.bookingOperations &&
      list.bookingOperations[0].actions.length
    ) {
      const lastAction =
        list.bookingOperations[0].actions[
          list.bookingOperations[0].actions.length - 1
        ]
      if (lastAction.status === 'accepted') {
        duration = null
      } else {
        const geo = `${list.pickUpLocation.lat},${list.pickUpLocation.lng}`
        const { data } = await getBookingTimeZone({
          eventTime: lastAction.time,
          coordinates: geo,
        })
        let offSet = data.location.rawOffset + data.location.dstOffset
        var timeToEvent = data.eventTimeStamp * 1000
        var currentTime = new Date(
          (Math.floor(new Date(data.localTimeStamp * 1000) / 1000) + offSet) *
            1000
        )
        var diffTime = currentTime - timeToEvent
        duration = moment.duration(diffTime, 'milliseconds')
      }
    }
    if (await checkStepStatus()) {
      setDisplayAlertText(
        'This action cannot be completed at this time. Please wait and try again when you are on your way.'
      )
      setDisplayAlertTitle('On the way')
      setShowConfirmationModal(true)
    } else if (
      duration &&
      duration.seconds() <= 30 &&
      duration.minutes() < 1 &&
      duration.hours() === 0 &&
      duration.days() === 0
    ) {
      if (
        (list.type === 'airport_transfer' ||
          list.type === 'departure_transfer' ||
          list.type === 'return_transfer' ||
          list.type === 'chauffeur_services') &&
        actionTypes[step] !== 'completed'
      ) {
        switch (actionTypes[step]) {
          case 'on_location':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when you are on location.'
            )
            setDisplayAlertTitle('On location')
            break
          case 'passenger_onboard':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when the passenger is on board.'
            )
            setDisplayAlertTitle('On board')
            break
          case 'ten_mins_away':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when you are 10 mins away from the destination.'
            )
            setDisplayAlertTitle('10 mins away')
            break
          case 'passenger_dropped_off':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when the passenger has been dropped off.'
            )
            setDisplayAlertTitle('Dropped off')
            break
          default:
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again later'
            )
            break
        }
      } else if (isCarRental) {
        switch (actionTypes[step]) {
          case 'on_location':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when you are on location.'
            )
            setDisplayAlertTitle('On location')
            break
          case 'delivered':
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again when vehicle has been delivered.'
            )
            setDisplayAlertTitle('Vehicle delivered')
            break
          default:
            setDisplayAlertText(
              'This action cannot be completed at this time. Please wait and try again later'
            )
            break
        }
      }
      setShowConfirmationModal(true)
    } else {
      navigator.geolocation.getCurrentPosition(
        async function(pos) {
          const geo = `${pos.coords.latitude},${pos.coords.longitude}`
          const bId = list._id
          await acceptPartnerBooking(
            {
              action: actionTypes[step],
              geoLocation: geo,
              isLiveLocation: true,
              ...(list.durationType === 'daily' && list.days
                ? { day: currentDay }
                : {}),
            },
            bId
          )
          setSubmitting(false)
          setStep(step + 1)
          reloadList()
        },
        async function(error) {
          if (error.code === error.PERMISSION_DENIED) {
            const bId = list._id
            const geo = `00.00-00.00`
            await acceptPartnerBooking(
              {
                action: actionTypes[step],
                geoLocation: geo,
                isLiveLocation: false,
                ...(list.durationType === 'daily' && list.days
                  ? { day: currentDay }
                  : {}),
              },
              bId
            )
            setSubmitting(false)
            setStep(step + 1)
            reloadList()
          }
        }
      )
      if (actionTypes[step] === 'on_the_way') {
        const geo = `${list.pickUpLocation.lat},${list.pickUpLocation.lng}`
        const { data } = await getBookingTimeZone({
          eventTime: list.pickUpDate,
          coordinates: geo,
        })
        let offSet = data.location.rawOffset + data.location.dstOffset
        var timeToEvent = data.eventTimeStamp * 1000
        var currentTime = new Date(
          (Math.floor(new Date(data.localTimeStamp * 1000) / 1000) + offSet) *
            1000
        )
        var diffTime = timeToEvent - currentTime

        var durationToEvent = moment
          .duration(diffTime, 'milliseconds')
          .asMilliseconds()

        var durationOfEvent = 0
        if (list.type === 'chauffeur_services') {
          if (list.durationType === 'hourly') {
            durationOfEvent = list.duration * 3600000
          } else {
            durationOfEvent = list.duration[0] * 3600000
          }
        } else {
          if (list.tripInfo) {
            durationOfEvent = list.tripInfo.duration * 1000
          }
        }

        var durationAfterEvent = 2700000 // 45 minutes

        let geolocationDuration =
          durationToEvent + durationOfEvent + durationAfterEvent
        const getLocationUpdates = invoke.bind('getLocationUpdates')
        const callGeolocation = await getLocationUpdates(
          geolocationDuration,
          bookingId
        )
      } else if (actionTypes[step] === 'passenger_dropped_off') {
        const removeLocationUpdates = invoke.bind('removeLocationUpdates')
        const removeGeoLocation = await removeLocationUpdates()
      }
    }
  }, [list, step, setStep, actionTypes])

  const onConfirmationModal = async type => {
    setShowConfirmationModal(false)
    setSubmitting(false)
  }
  const checkStepStatus = async () => {
    const geo = `${list.pickUpLocation.lat},${list.pickUpLocation.lng}`
    const { data } = await getBookingTimeZone({
      eventTime:
        list.durationType === 'daily' && list.days
          ? list.days[currentDay - 1].pickUpDate
          : list.pickUpDate,
      coordinates: geo,
    })

    let offSet = data.location.rawOffset + data.location.dstOffset
    var timeToEvent = data.eventTimeStamp * 1000
    var currentTime = new Date(
      (Math.floor(new Date(data.localTimeStamp * 1000) / 1000) + offSet) * 1000
    )
    var diffTime = timeToEvent - currentTime

    var duration = moment.duration(diffTime, 'milliseconds')
    if (duration && duration.days() === 0 && duration.hours() < 5) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    const checkDriverExists = async () => {
      const url = `external/findDriver/${list.driver.phone}`
      const { data } = await api.get(url)

      setDriverExists(data.valid)
    }
    const checkBookingOperations = async actions => {
      _.each(actions, item => {
        if (list.durationType !== 'daily' || !list.days) {
          if (item.status === 'on_the_way') setUpdateOnTheWay(item)
          if (item.status === 'on_location') setUpdateOnLocation(item)
          if (item.status === 'passenger_onboard')
            setUpdatePassengerOnboard(item)
          if (item.status === 'ten_mins_away') setUpdateTenMinsAway(item)
          if (item.status === 'passenger_dropped_off')
            setUpdatePassengerDroppedOff(item)
          if (item.status === 'delivered') setUpdateVehicleDelivered(item)
          if (item.status === 'on_location_at_stop')
            setUpdateOnLocationAtStop(item.stops)
        } else {
          let day = 1

          for (var i in list.days) {
            let currentDate = moment.utc(new Date())
            let dayDate = moment.utc(list.days[i].pickUpDate).startOf('day')

            if (currentDate.isSameOrAfter(dayDate)) {
              day = parseInt(i) + 1
              setCurrentDay(parseInt(i) + 1)
            }
          }

          if (item.day === day) {
            if (item.status === 'on_the_way') setUpdateOnTheWay(item)
            if (item.status === 'on_location') setUpdateOnLocation(item)
            if (item.status === 'passenger_onboard')
              setUpdatePassengerOnboard(item)
            if (item.status === 'ten_mins_away') setUpdateTenMinsAway(item)
            if (item.status === 'passenger_dropped_off')
              setUpdatePassengerDroppedOff(item)
            if (item.status === 'delivered') setUpdateVehicleDelivered(item)
            if (item.status === 'on_location_at_stop')
              setUpdateOnLocationAtStop(item.stops)
            if (item.status === 'completed') setUpdateDayCompleted(item)
          }
        }
      })
    }
    const fetchGeoData = async startTime => {
      const geo = `${list.pickUpLocation.lat},${list.pickUpLocation.lng}`
      const { data } = await getBookingTimeZone({
        eventTime: startTime,
        coordinates: geo,
      })
      setGeoData(data)
    }
    const fetchLocationType = async () => {
      let location = null
      if (list.dropOffLocation) {
        location = !(
          list.pickUpLocation.locationType || list.dropOffLocation.locationType
        )
          ? await LocationType(list.pickUpLocation, list.dropOffLocation)
          : null
      } else {
        location = !list.pickUpLocation.locationType
          ? await LocationType(list.pickUpLocation)
          : null
      }
      setLocationType(location)

      if (
        (location && location.dropOff && location.dropOff.isHotel) ||
        (list &&
          list.dropOffLocation.locationType &&
          list.dropOffLocation.locationType === 'isHotel')
      )
        setShowTenMinsAwayUpdate(list.tenMinsAwayDriverUpdate)
    }
    if (listExists && list.pickUpDate) fetchGeoData(list.pickUpDate)
    if (listExists && list.driver && list.driver.phone) checkDriverExists()
    if (
      listExists &&
      list.bookingOperations &&
      list.bookingOperations[0].actions
    )
      checkBookingOperations(list.bookingOperations[0].actions)
    if (listExists && list.pickUpLocation) fetchLocationType()
  }, [list])

  useEffect(() => {
    const checkBookingTime = async count => {
      let offSet = geoData.location.rawOffset + geoData.location.dstOffset
      var timeToEvent = geoData.eventTimeStamp * 1000
      var currentTime = new Date(
        (Math.floor(new Date(geoData.localTimeStamp * 1000) / 1000) + offSet) *
          1000
      )
      var diffTime = timeToEvent - currentTime - count * 1000
      var duration = moment.duration(diffTime, 'milliseconds')

      let months = duration.months()
      let days = duration.days()
      let hours = duration.hours()
      let minutes = duration.minutes()
      let seconds = duration.seconds()

      if (months > 0) {
        days += months * 30
      }

      if (days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0) {
        setBookingTimer({
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        })
      } else {
        var diffTimeAfter = currentTime - timeToEvent - count * 1000
        var durationAfter = moment.duration(diffTimeAfter, 'milliseconds')

        let monthsAfter = durationAfter.months()
        let daysAfter = durationAfter.days()
        let hoursAfter = durationAfter.hours()
        let minutesAfter = durationAfter.minutes()
        let secondsAfter = durationAfter.seconds()

        if (monthsAfter > 0) {
          daysAfter += monthsAfter * 30
        }

        setBookingTimerAfter({
          days: daysAfter,
          hours: hoursAfter,
          minutes: minutesAfter,
          seconds: secondsAfter,
        })
      }

      if (days === 0 && hours <= 2) setBookingInTwoHours(true)

      if (days < 1) setBookingInOneDay(true)

      if (hours >= 0 && minutes >= 0 && seconds >= 0) {
        if (hours < 10) hours = '0' + hours.toString()
        if (minutes < 10) minutes = '0' + minutes.toString()
        if (seconds < 10) seconds = '0' + seconds.toString()

        setBookingTimeLeft(`${hours}:${minutes}:${seconds}`)
      }
    }

    let count = 0

    const bookingInterval = setInterval(() => {
      if (listExists && geoData) {
        checkBookingTime(count)
        count += 1
      }
    }, 1000)

    return () => clearInterval(bookingInterval)
  }, [geoData])

  let formattedTripInfo = useMemo(() => {
    if (listExists) {
      if (isCarRental) {
        let duration = Math.ceil(
          (new Date(list.dropOffDate).getTime() -
            new Date(list.pickUpDate).getTime()) /
            3600 /
            24 /
            1000
        )
        let duration_total_hours = Math.ceil(
          (new Date(list.dropOffDate).getTime() -
            new Date(list.pickUpDate).getTime()) /
            3600 /
            1000
        )
        let nb_of_extra_hours = duration_total_hours - duration * 24
        if (nb_of_extra_hours !== 0) {
          let extraHours = nb_of_extra_hours + 24
          if (duration_total_hours > 2 && extraHours <= 2) {
            duration = duration - 1
          }
        }
        return {
          duration: duration === 1 ? '1 day' : `${duration} days`,
          distance: '0 km',
        }
      } else {
        if (list.tripInfo) return getFormattedTripInfo(list.tripInfo)
      }
    }
  }, [list])

  let supplierId = null

  if (data && data.list && data.list[0]) {
    supplierId = data.list[0]._id
  }

  const getAddressElementLength = (divIndex, eleIndex) => {
    let container = document.getElementsByClassName('partner-fields')[divIndex]

    if (container) {
      var selectedElement = container.getElementsByTagName('p')[eleIndex]

      if (selectedElement && selectedElement.offsetHeight) {
        if (selectedElement && selectedElement.offsetHeight > 70) return 3
        if (selectedElement && selectedElement.offsetHeight < 25) return 1
      }
      return 2
    }
    return 2
  }

  const renderDottedBar = (addressLength, hide) => {
    if (!hide) {
      return (
        <div
          className='dotted-container'
          style={
            addressLength === 3
              ? { height: '79px' }
              : addressLength === 1
              ? { height: '32px' }
              : { height: '56px' }
          }
        >
          <svg
            style={{
              transform: 'rotate(90deg)',
            }}
            width='300px'
            height='200px'
            viewBox='0 0 300 200'
            className='dotted-line'
          >
            <line
              x1='40'
              x2='260'
              y1='100'
              y2='100'
              stroke='#8b959e'
              stroke-width='2'
              stroke-linecap='round'
              stroke-dasharray='.001, 10'
            />
          </svg>
        </div>
      )
    }
  }

  const getHostLink = bookingId => {
    if (apiHost === 'https://staging.rolzo.com/api') {
      return `https://partners-staging.rolzo.com/${bookingId}/b`
    } else if (apiHost === 'https://business.rolzo.com/api') {
      return `https://partners.rolzo.com/${bookingId}/b`
    } else if (apiHost === 'http://localhost:3000') {
      return `http://localhost:3002/${bookingId}/b`
    }
  }

  const renderContent = () => {
    return (
      <>
        {/* {listExists && (locationType || list.pickUpLocation.locationType) && ( */}
        {listExists && (
          <div className='driver-booking-container' style={{ margin: 0 }}>
            {((list.supplierInfo && list.supplierInfo.status === 'accepted') ||
              list.driver) && (
              <div className='tabs-container' style={{ width: 'fit-content' }}>
                <div className='d-flex justify-content-start'>
                  <div
                    className='custom-tabs-nav-driver light-tabs nav'
                    style={{ borderBottom: 0 }}
                  >
                    <Button
                      onClick={() => changeTab(0)}
                      className={`nav-link ${selectedTab ? '' : 'active'}`}
                    >
                      Information
                    </Button>
                    <Button
                      onClick={() => changeTab(1)}
                      className={`nav-link ${!selectedTab ? '' : 'active'}`}
                    >
                      Trip updates
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <SmallUp>
              {/* Outsource banners */}
              {listExists && list.isOperativeBranch ? (
                <>
                  {(list.dispatchStatus === 'dispatched' ||
                    list.dispatchStatus === 'outsourced') &&
                  list.outsourcedSuppliers ? (
                    <>
                      <div className='partner-banner-desktop-warning'>
                        <div className='d-flex'>
                          <div
                            className='partnerContainer'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              alignContent: 'center',
                            }}
                          >
                            <img
                              style={{ height: '30px', marginRight: '1em' }}
                              src='/images/icons/partner/arrow-yellow.png'
                              alt=''
                            />
                            <p>
                              The booking was outsourced to{' '}
                              {formattedString(list.outsourcedSuppliers)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {list.dispatchStatus === 'accepted' &&
                      list.dispatch &&
                      list.supplierInfo &&
                      list.dispatch.supplierId !==
                        list.supplierInfo.supplierId &&
                      !list?.driver?.fullName ? (
                        <>
                          <div className='partner-banner-desktop-grey mt-4'>
                            <div className='d-flex'>
                              <div
                                className='partnerContainer'
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  alignContent: 'center',
                                }}
                              >
                                <img
                                  style={{
                                    // height: '40px',
                                    width: '1.75rem',
                                    height: '1.75rem',
                                    marginRight: '1em',
                                  }}
                                  src='/images/icons/partner/arrow-black.png'
                                  alt=''
                                />
                                <p>
                                  [Outsourced] The booking was accepted by{' '}
                                  {list.dispatch.name}.
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* Partner changes banner */}
              {/* {listExists &&
                list.status !== 'completed' &&
                list.status !== 'cancellation_accepted_by_agent' &&
                list.status !== 'cancelled_by_agent' &&
                list.supplierInfo &&
                list.supplierInfo.status === 'accepted' &&
                list.dispatch &&
                list.dispatch.supplierId === list.supplierInfo.supplierId &&
                tokenUser &&
                tokenUser.isPartner &&
                bookingChanges &&
                thereAreNewChanges && (
                  <>
                    <div className='partner-banner-desktop mt-4'>
                      <div className='d-flex'>
                        <div
                          className='imageContainer'
                          style={{
                            alignSelf: 'start',
                            marginTop: '20px',
                          }}
                        >
                          <img src='/images/icons/icon-warning.png' alt='' />
                        </div>
                        <div className='partnerContainer w-75'>
                          <p style={{ marginTop: '0.15rem' }}>
                            Action required
                          </p>
                          <span>
                            Please review and confirm the booking change(s):
                          </span>
                          <span className='mt-1'>
                            {bookingChanges
                              .filter(
                                item =>
                                  item.status_admin === 'sent' &&
                                  (item.status_partner === 'opened' ||
                                    item.status_partner === 'not_opened')
                              )
                              .map(info => {
                                let newText = info.message.replace(/<br>/g, ' ')
                                return (
                                  <span className='w-100'>{`— ${newText}`}</span>
                                )
                              })}
                          </span>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <Button
                          className='partner-btn-desktop-decline select-vehicle align-self-end'
                          style={{
                            fontWeight: '400',
                            textShadow: '0.25px 0 #a79779',
                          }}
                          onClick={() => {
                            setShowDeclineChangesConfirmationModal(true)
                          }}
                        >
                          Decline
                        </Button>
                        <Button
                          className='select-chauffeur align-self-end'
                          style={{
                            whiteSpace: 'pre',
                            marginLeft: '1em',
                            paddingRight: '1.7rem',
                            paddingLeft: '1.7rem',
                            marginRight: '0em',
                          }}
                          variant='brown'
                          onClick={() => {
                            onAcceptBookingChanges(
                              bookingChanges,
                              list._id,
                              supplierId
                            )
                          }}
                        >
                          {'Confirm change(s)'}
                        </Button>
                      </div>
                    </div>
                  </>
                )} */}
            </SmallUp>

            {/* Assign Chauffeur banner (before 24 hours or as soon as possilbe) */}
            {list.supplierInfo &&
            list.supplierInfo.status !== 'expired' &&
            list.supplierInfo.status !== 'declined' &&
            list.supplierInfo.status !== 'declined changes' &&
            list.status !== 'completed' &&
            list.status !== 'cancellation_accepted_by_agent' &&
            list.status !== 'cancelled_by_agent' &&
            list.dispatch &&
            (!isLocalSupplier ||
              (isLocalSupplier && companyId === list.dispatch.supplierId)) &&
            (!list.partnerChauffeurId || !list.partnerVehicleId) &&
            !list.driver ? (
              <>
                {!checkBookingAfterADay(list.pickUpDate) ? (
                  <>
                    {(!list.partnerChauffeurId || !list.partnerVehicleId) && (
                      <div className='partner-banner-desktop mt-4'>
                        <div className='d-flex'>
                          <div className='warning-imageContainer'>
                            <img src='/images/icons/icon-warning.png' alt='' />
                          </div>
                          <div className='partnerContainer'>
                            <p>Action required</p>
                            <span>
                              {(!list.partnerChauffeurId ||
                                !list.partnerVehicleId) &&
                                `The
                              ${
                                !list.partnerChauffeurId
                                  ? `${
                                      isCarRental
                                        ? 'agent delivering the vehicle'
                                        : 'chauffeur'
                                    }`
                                  : 'vehicle'
                              } must be assigned as soon as possible.`}
                            </span>
                          </div>
                        </div>
                        <Button
                          className='select-chauffeur'
                          style={{ marginRight: '0em' }}
                          variant='brown'
                          onClick={() => {
                            assignChauffeur()
                          }}
                        >
                          {!list.partnerChauffeurId
                            ? `Assign ${isCarRental ? 'agent' : 'chauffeur'}`
                            : 'Assign vehicle'}
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='partner-banner-desktop-gold mt-4'>
                    <div className='d-flex'>
                      <div className='imageContainerGold'>
                        <img
                          src='/images/icons/icon-chauffeur-gold.png'
                          alt=''
                        />
                      </div>
                      <div className='partnerContainer'>
                        <span>
                          {(!list.partnerChauffeurId ||
                            !list.partnerVehicleId) &&
                            `The
                              ${
                                !list.partnerChauffeurId
                                  ? `${
                                      isCarRental
                                        ? 'agent delivering the vehicle'
                                        : 'chauffeur'
                                    }`
                                  : 'vehicle'
                              } must be assigned 24 hours before the ${
                              isCarRental ? 'delivery.' : 'pick-up time.'
                            }`}
                        </span>
                      </div>
                    </div>
                    <Button
                      className='select-chauffeur'
                      style={{ marginRight: '0em' }}
                      variant='brown'
                      onClick={() => {
                        assignChauffeur()
                      }}
                    >
                      {!list.partnerChauffeurId
                        ? `Assign ${isCarRental ? 'agent' : 'chauffeur'}`
                        : 'Assign vehicle'}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {/* Assigned chauffeur/agent banner */}
                {list.status !== 'cancellation_accepted_by_agent' &&
                  list.status !== 'cancelled_by_agent' &&
                  list.supplierInfo &&
                  list.supplierInfo.status !== 'expired' &&
                  list.driver &&
                  list.driver.fullName && (
                    <div className='partner-banner-desktop-grey mt-4'>
                      <div className='d-flex'>
                        <div className='imageContainer'>
                          <img src='/images/icons/icon_chauffeur.png' alt='' />
                        </div>
                        <div className='partnerContainer'>
                          <p>
                            {isCarRental
                              ? 'Vehicle to be delivered by'
                              : 'Driven by'}{' '}
                            {list.driver.fullName.split(' ')[0]}
                            {list.dispatch &&
                            list.supplierInfo &&
                            list.isOperativeBranch &&
                            list.dispatch.supplierId !==
                              list.supplierInfo.supplierId
                              ? ` · Outsourced to ${list.dispatch.name}`
                              : ''}
                          </p>
                          <span>
                            {list.driver.phone} ·{' '}
                            {list.partnerVehicle || list.vehicleName} ·{' '}
                            {list.driver.plateNo
                              ? list.driver.plateNo.toUpperCase()
                              : ''}
                          </span>
                        </div>
                      </div>
                      {list.status !== 'completed' &&
                        tokenUser &&
                        tokenUser.isPartner && (
                          <img
                            style={{
                              // width: '3%',
                              // height: '2%',
                              // marginRight: '3%',
                              width: '1.75rem',
                              height: '1.75rem',
                              cursor: 'pointer',
                            }}
                            src='/images/edit_icon.png'
                            alt=''
                            onClick={() => {
                              assignChauffeur()
                            }}
                          />
                        )}
                    </div>
                  )}
              </>
            )}

            {/* Partner changes banner */}
            {listExists &&
              list.status !== 'completed' &&
              list.status !== 'cancellation_accepted_by_agent' &&
              list.status !== 'cancelled_by_agent' &&
              list.supplierInfo &&
              list.supplierInfo.status === 'accepted' &&
              list.dispatch &&
              list.dispatch.supplierId === list.supplierInfo.supplierId &&
              tokenUser &&
              tokenUser.isPartner &&
              bookingChanges &&
              thereAreNewChanges && (
                <>
                  <div className='partner-banner-desktop mt-4'>
                    <div className='d-flex'>
                      <div
                        className='imageContainer'
                        style={{
                          alignSelf: 'start',
                          marginTop: '20px',
                        }}
                      >
                        <img src='/images/icons/icon-warning.png' alt='' />
                      </div>
                      <div className='partnerContainer w-75'>
                        <p style={{ marginTop: '0.15rem' }}>Action required</p>
                        <span>
                          Please review and confirm the booking change(s):
                        </span>
                        <span className='mt-1'>
                          {bookingChanges
                            .filter(
                              item =>
                                item.status_admin === 'sent' &&
                                (item.status_partner === 'opened' ||
                                  item.status_partner === 'not_opened')
                            )
                            .map(info => {
                              let newText = info.message.replace(/<br>/g, ' ')
                              return (
                                <span className='w-100'>{`— ${newText}`}</span>
                              )
                            })}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex'>
                      <Button
                        className='partner-btn-desktop-decline select-vehicle align-self-end'
                        style={{
                          fontWeight: '400',
                          textShadow: '0.25px 0 #a79779',
                        }}
                        onClick={() => {
                          setShowDeclineChangesConfirmationModal(true)
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        className='select-chauffeur align-self-end'
                        style={{
                          whiteSpace: 'pre',
                          marginLeft: '1em',
                          paddingRight: '1.7rem',
                          paddingLeft: '1.7rem',
                          marginRight: '0em',
                        }}
                        variant='brown'
                        onClick={() => {
                          onAcceptBookingChanges(
                            bookingChanges,
                            list._id,
                            supplierId
                          )
                        }}
                      >
                        {'Confirm change(s)'}
                      </Button>
                    </div>
                  </div>
                </>
              )}

            {/* Assign Agent banner (before 24 hours or as soon as possilbe) */}
            {isCarRental &&
            list.supplierInfo &&
            list.supplierInfo.status !== 'expired' &&
            list.supplierInfo.status !== 'declined' &&
            list.supplierInfo.status !== 'declined changes' &&
            list.status !== 'completed' &&
            list.status !== 'cancellation_accepted_by_agent' &&
            list.status !== 'cancelled_by_agent' &&
            list.dispatch &&
            (!isLocalSupplier ||
              (isLocalSupplier && companyId === list.dispatch.supplierId)) &&
            !list.partnerCollectionId &&
            !list.collectionAgent ? (
              <>
                {!checkBookingAfterADay(list.pickUpDate) ? (
                  <>
                    {!list.partnerCollectionId && (
                      <div className='partner-banner-desktop mt-2'>
                        <div className='d-flex'>
                          <div className='imageContainer'>
                            <img src='/images/icons/icon-warning.png' alt='' />
                          </div>
                          <div className='partnerContainer'>
                            <p>Action required</p>
                            <span>
                              The agent collecting the vehicle must be assigned
                              as soon as possible.
                            </span>
                          </div>
                        </div>
                        <Button
                          className='select-chauffeur'
                          style={{ marginRight: '0em' }}
                          variant='brown'
                          onClick={() => {
                            assignChauffeur(false)
                          }}
                        >
                          Assign agent
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className='partner-banner-desktop-gold mt-2'>
                    <div className='d-flex'>
                      <div className='imageContainerGold'>
                        <img
                          src='/images/icons/icon-chauffeur-gold.png'
                          alt=''
                        />
                      </div>
                      <div className='partnerContainer'>
                        <span>
                          The agent collecting the vehicle must be assigned 24
                          hours before collection.
                        </span>
                      </div>
                    </div>
                    <Button
                      className='select-chauffeur'
                      style={{ marginRight: '0em' }}
                      variant='brown'
                      onClick={() => {
                        assignChauffeur(false)
                      }}
                    >
                      Assign agent
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {isCarRental &&
                  list.status !== 'cancellation_accepted_by_agent' &&
                  list.status !== 'cancelled_by_agent' &&
                  list.collectionAgent &&
                  list.collectionAgent.fullName && (
                    <div className='partner-banner-desktop-grey mt-2'>
                      <div className='d-flex'>
                        <div className='imageContainer'>
                          <img src='/images/icons/icon_chauffeur.png' alt='' />
                        </div>
                        <div className='partnerContainer'>
                          <p>
                            Vehicle to be collected by{' '}
                            {list.collectionAgent.fullName.split(' ')[0]}
                          </p>
                          <span>
                            {list.collectionAgent.phone} ·{' '}
                            {list.partnerVehicle || list.vehicleName} ·{' '}
                            {list.collectionAgent.plateNo
                              ? list.collectionAgent.plateNo.toUpperCase()
                              : ''}
                          </span>
                        </div>
                      </div>
                      {list.status !== 'completed' &&
                        tokenUser &&
                        tokenUser.isPartner && (
                          <img
                            style={{
                              width: '3%',
                              height: '2%',
                              // marginRight: '3%',
                              cursor: 'pointer',
                            }}
                            src='/images/edit_icon.png'
                            alt=''
                            onClick={() => {
                              assignChauffeur(false)
                            }}
                          />
                        )}
                    </div>
                  )}
              </>
            )}

            {/* Expired banner */}
            {list.supplierInfo && list.supplierInfo.status === 'expired' && (
              <div className='partner-banner-desktop mt-4'>
                <div className='d-flex'>
                  <div className='imageContainer'>
                    <img src='/images/icons/icon-warning.png' alt='' />
                  </div>
                  <div className='partnerContainer'>
                    <span>This offer is no longer available.</span>
                  </div>
                </div>
              </div>
            )}

            {selectedTab === 0 && (
              <>
                {isSubmitting && <LoadingSpinner />}
                <div
                  style={
                    list.dispatchStatus === 'outsourced'
                      ? { marginTop: '0px' }
                      : {}
                  }
                  className={
                    list.dispatch || list.driver
                      ? 'driver-info-layout-banner'
                      : 'driver-info-layout'
                  }
                >
                  <div className='driver-info-column'>
                    <div className='driver-service-card'>
                      <div className='driver-service-card-header'>
                        <span>Service</span>
                        {bookingTimer &&
                          list.status !== 'cancellation_accepted_by_agent' &&
                          list.status !== 'cancelled_by_agent' &&
                          ((list.supplierInfo &&
                            list.supplierInfo.status !== 'expired' &&
                            list.supplierInfo.status !== 'declined' &&
                            list.supplierInfo.status !== 'declined changes') ||
                            !list.supplierInfo) && (
                            <div
                              className='partner-countdown'
                              style={
                                checkBookingAfterADay(list.pickUpDate)
                                  ? { color: 'black' }
                                  : { color: '#d0021b' }
                              }
                            >
                              {!!bookingTimer.days && (
                                <>
                                  <span>{bookingTimer.days}</span>
                                  <span>d</span>
                                </>
                              )}
                              {!!bookingTimer.hours && (
                                <>
                                  <span>{bookingTimer.hours}</span>
                                  <span>h</span>
                                </>
                              )}
                              <span>{bookingTimer.minutes}</span>
                              <span>m</span>
                              <span>{bookingTimer.seconds}</span>
                              <span>s</span>
                            </div>
                          )}
                      </div>

                      {list.type !== 'car_rentals' ? (
                        <div className='driver-service-card-body'>
                          <div className='driver-service-card-item'>
                            <span>Type</span>
                            <span>
                              {getLabelFromValue(
                                defaultBookingServicePartner,
                                list.type
                              )}
                            </span>
                          </div>
                          {(list.durationType !== 'daily' || !list.days) && (
                            <div className='driver-service-card-item'>
                              <span>Date and time</span>
                              <span>{`
              ${moment.utc(list.pickUpDate).format(dateFormatDetailed)},
              ${moment.utc(list.pickUpDate).format('HH:mm')}
            `}</span>
                            </div>
                          )}
                          <div className='driver-service-card-item'>
                            <span>Vehicle</span>
                            <span>
                              {list.globalVehicleTemp
                                ? `${list.globalVehicleTemp.brandName} ${list.globalVehicleTemp.modelName}`
                                : list.globalVehicle
                                ? `${list.globalVehicle.brandName} ${list.globalVehicle.modelName}`
                                : ''}
                              <span
                                style={{ fontSize: '13px', color: 'black' }}
                              >
                                <img
                                  className='mr-1 ml-2'
                                  style={{ width: '15px' }}
                                  src={`/images/icons/team.svg`}
                                  alt=''
                                />
                                {passengerCapacity &&
                                typeof passengerCapacity === 'number'
                                  ? passengerCapacity
                                  : 0}
                                <img
                                  className='mr-1 ml-2'
                                  style={{ width: '15px' }}
                                  src='/images/icons/portfolio.svg'
                                  alt=''
                                />
                                {luggageCapacity &&
                                typeof luggageCapacity === 'number'
                                  ? luggageCapacity
                                  : 0}
                              </span>
                            </span>
                          </div>
                          {list.durationType === 'daily' && list.days && (
                            <div className='driver-service-card-item'>
                              <span>Number of days</span>
                              <span>{list.days.length} days</span>
                            </div>
                          )}
                          {list.durationType !== 'daily' || !list.days ? (
                            <div
                              className='driver-service-card-item'
                              style={{ display: 'block' }}
                            >
                              {list.pickUpLocation && (
                                <div className='d-flex'>
                                  <span className='from-to-title'>From</span>
                                  <span className='from-to-content'>
                                    {list.pickUpLocation.fullAddress}
                                  </span>
                                </div>
                              )}
                              {list.stops && list.stops.length ? (
                                <>
                                  {list.stops.map((stop, index) => {
                                    return (
                                      <div
                                        style={{
                                          marginTop: '1em',
                                          marginBottom: '1em',
                                        }}
                                      >
                                        <div className='d-flex'>
                                          <span className='from-to-title'>
                                            {`Stop ${index + 1} location`}
                                          </span>
                                          <span className='from-to-content'>
                                            {stop.location.fullAddress}
                                          </span>
                                        </div>
                                        <div className='d-flex'>
                                          <span className='from-to-title'>
                                            {`Stop ${index + 1} duration`}
                                          </span>
                                          <span className='from-to-content'>
                                            {stop.duration.label}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </>
                              ) : (
                                <></>
                              )}
                              {list.dropOffLocation && (
                                <div className='d-flex'>
                                  <span className='from-to-title'>To</span>
                                  <span className='from-to-content'>
                                    {list.dropOffLocation.fullAddress}
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <>
                              {list.days.map(day => {
                                return (
                                  <div
                                    className='driver-service-card-item'
                                    style={{ display: 'block' }}
                                  >
                                    {day.pickUpDate && (
                                      <div className='d-flex mb-3'>
                                        <span className='from-to-title'>
                                          Date and time
                                        </span>
                                        <span className='from-to-content'>
                                          {`
              ${moment.utc(day.pickUpDate).format(dateFormatDetailed)},
              ${moment.utc(day.pickUpDate).format('HH:mm')}
            `}
                                        </span>
                                      </div>
                                    )}
                                    {day.hours && (
                                      <div className='d-flex mb-3'>
                                        <span className='from-to-title'>
                                          Duration
                                        </span>
                                        <span className='from-to-content'>
                                          {day.hours.label}
                                        </span>
                                      </div>
                                    )}
                                    {day.pickUpLocation && (
                                      <div className='d-flex mb-3'>
                                        <span className='from-to-title'>
                                          From
                                        </span>
                                        <span className='from-to-content'>
                                          {day.pickUpLocation.fullAddress}
                                        </span>
                                      </div>
                                    )}
                                    {day.stops && day.stops.length ? (
                                      <>
                                        {day.stops.map((stop, idx) => {
                                          return (
                                            <div
                                              style={{
                                                marginTop: '1em',
                                                marginBottom: '1em',
                                              }}
                                            >
                                              <div className='d-flex'>
                                                <span className='from-to-title'>
                                                  {`Stop ${idx + 1} location`}
                                                </span>
                                                <span className='from-to-content'>
                                                  {stop.location.fullAddress}
                                                </span>
                                              </div>
                                              <div className='d-flex'>
                                                <span className='from-to-title'>
                                                  {`Stop ${idx + 1} duration`}
                                                </span>
                                                <span className='from-to-content'>
                                                  {stop.duration.label}
                                                </span>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {day.dropOffLocation && (
                                      <div className='d-flex'>
                                        <span className='from-to-title'>
                                          To
                                        </span>
                                        <span className='from-to-content'>
                                          {day.dropOffLocation.fullAddress}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )
                              })}
                            </>
                          )}
                          {(list.type === 'airport_transfer' ||
                            list.type === 'departure_transfer' ||
                            list.type === 'return_transfer') && (
                            <div
                              className='driver-service-card-item'
                              style={{ fontSize: '15px' }}
                            >
                              <span>Distance</span>
                              <span>
                                {formattedTripInfo && formattedTripInfo.distance
                                  ? numberWithCommas(formattedTripInfo.distance)
                                  : '0 km'}
                              </span>
                            </div>
                          )}
                          {(list.durationType !== 'daily' || !list.days) && (
                            <div
                              className='driver-service-card-item'
                              style={{ fontSize: '15px' }}
                            >
                              <span>Time</span>
                              <span>
                                {list.type === 'chauffeur_services'
                                  ? list.duration.length > 0
                                    ? `${list.duration.length} day${
                                        list.duration?.length > 1 ? 's' : ''
                                      }`
                                    : `${list.duration} hour${
                                        list.duration > 1 ? 's' : ''
                                      }`
                                  : formattedTripInfo &&
                                    formattedTripInfo.duration
                                  ? formattedTripInfo.duration
                                  : '0 min'}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className='driver-service-card-body'>
                          <div className='driver-service-card-item'>
                            <span>Type</span>
                            <span>
                              {getLabelFromValue(
                                defaultBookingServicePartner,
                                list.type
                              )}
                            </span>
                          </div>
                          <div className='driver-service-card-item'>
                            <span>Vehicle</span>
                            <span>
                              {list.globalVehicle &&
                                list.globalVehicle.brandName}{' '}
                              {list.globalVehicle &&
                                list.globalVehicle.modelName}
                              <span
                                style={{ fontSize: '13px', color: 'black' }}
                              >
                                <img
                                  className='mr-1 ml-2'
                                  style={{ width: '15px' }}
                                  src={`/images/icons/team.svg`}
                                  alt=''
                                />
                                {passengerCapacity &&
                                typeof passengerCapacity === 'number'
                                  ? passengerCapacity
                                  : 0}
                                <img
                                  className='mr-1 ml-2'
                                  style={{ width: '15px' }}
                                  src='/images/icons/portfolio.svg'
                                  alt=''
                                />
                                {luggageCapacity &&
                                typeof luggageCapacity === 'number'
                                  ? luggageCapacity
                                  : 0}
                              </span>
                            </span>
                          </div>
                          <div className='driver-service-card-item'>
                            <span>Delivery date and time</span>
                            <span>{`
              ${moment.utc(list.pickUpDate).format(dateFormatDetailed)},
              ${moment.utc(list.pickUpDate).format('HH:mm')}
            `}</span>
                          </div>

                          <div
                            className='driver-service-card-item'
                            style={{ display: 'block' }}
                          >
                            {list.pickUpLocation && (
                              <div className='d-flex'>
                                <span
                                  style={{
                                    display: 'inline-Block',
                                    whiteSpace: 'pre',
                                  }}
                                  className='from-to-title'
                                >
                                  Delivery location
                                </span>
                                <span className='from-to-content'>
                                  {list.pickUpLocation.fullAddress}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className='driver-service-card-item'>
                            <span>Collection date and time</span>
                            <span>{`
              ${moment.utc(list.dropOffDate).format(dateFormatDetailed)},
              ${moment.utc(list.dropOffDate).format('HH:mm')}
            `}</span>
                          </div>
                          <div
                            className='driver-service-card-item'
                            style={{ display: 'block' }}
                          >
                            {list.dropOffLocation && (
                              <div className='d-flex'>
                                <span
                                  style={{
                                    display: 'inline-Block',
                                    whiteSpace: 'pre',
                                  }}
                                  className='from-to-title'
                                >
                                  Collection location
                                </span>
                                <span className='from-to-content'>
                                  {list.dropOffLocation.fullAddress}
                                </span>
                              </div>
                            )}
                          </div>
                          <div
                            className='driver-service-card-item'
                            style={{ fontSize: '15px' }}
                          >
                            <span>Duration</span>
                            <span>
                              {carRentalsDuration()} day
                              {carRentalsDuration() > 1 ? 's' : ''}
                            </span>
                          </div>
                          <div
                            className='driver-service-card-item'
                            style={{ fontSize: '15px' }}
                          >
                            <span>
                              {continent && continent.unitSystem !== 'KM'
                                ? 'Miles included'
                                : 'Kilometres included'}
                            </span>
                            <span>
                              {list.locationRates.dailyMileage ||
                              list.locationRates.dailyMileage !== 0
                                ? continent && continent.unitSystem !== 'KM'
                                  ? `${list.locationRates.dailyMileage} miles per day `
                                  : `${Math.round(
                                      list.locationRates.dailyMileage / 0.62137
                                    )} km per day`
                                : continent && continent.unitSystem !== 'KM'
                                ? 'Unlimited miles'
                                : 'Unlimited km'}
                            </span>
                          </div>
                          {list.locationRates ? (
                            <>
                              <div
                                className='driver-service-card-item'
                                style={{ fontSize: '15px' }}
                              >
                                <span>
                                  {continent && continent.unitSystem !== 'KM'
                                    ? 'Extra mile'
                                    : 'Extra km'}
                                </span>
                                <span>
                                  {`${
                                    continent && continent.unitSystem !== 'KM'
                                      ? list.locationRates.extraMile.toFixed(2)
                                      : (
                                          list.locationRates.extraMile / 1.60934
                                        ).toFixed(2)
                                  } ${getLabelFromValue(
                                    defaultCurrencies,
                                    list.supplierInfo.supplierCurrency
                                  )} ${
                                    continent && continent.unitSystem !== 'KM'
                                      ? 'per mile'
                                      : 'per km'
                                  }`}
                                </span>
                              </div>
                              <div
                                className='driver-service-card-item'
                                style={{ fontSize: '15px' }}
                              >
                                <span>Security deposit</span>
                                <span>
                                  {`${numberWithCommas(
                                    getFormattedPrice(
                                      list.locationRates.deposit
                                    )
                                  )} ${getLabelFromValue(
                                    defaultCurrencies,
                                    list.supplierInfo.supplierCurrency
                                  )}`}
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                    {list.passengerInfo ? (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Passenger</span>
                          {list.passengerInfo && list.passengerInfo.isVip && (
                            <img
                              style={{ width: '24px', height: '20px' }}
                              src='/images/icons/icon-vip.png'
                              alt=''
                            />
                          )}
                        </div>

                        <div className='driver-service-card-body'>
                          {(list.supplierInfo &&
                            list.supplierInfo.status === 'accepted') ||
                          list.driver ||
                          list.isOperativeBranch ? (
                            <>
                              <div className='driver-service-card-item'>
                                <span>Passenger name</span>
                                <span>
                                  {list?.passengerInfo?.title
                                    ? list.passengerInfo.title
                                    : list.driversInfo && list.driversInfo[0]
                                    ? list.driversInfo[0].title
                                    : ''}{' '}
                                  {list?.passengerInfo?.lastName
                                    ? `${list.passengerInfo.lastName}`
                                    : list.driversInfo && list.driversInfo[0]
                                    ? `${list.driversInfo[0].lastName}`
                                    : ''}
                                </span>
                              </div>
                              <div className='driver-service-card-item'>
                                <span>Phone number</span>
                                <span>
                                  {list.passengerInfo &&
                                  list.passengerInfo.phone
                                    ? list.passengerInfo.phone
                                    : list.driversInfo && list.driversInfo[0]
                                    ? list.driversInfo[0].phone
                                    : ''}
                                </span>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {list.passengerInfo && (
                            <>
                              <div className='driver-service-card-item'>
                                <span>Number of passenger(s)</span>
                                <span>
                                  {list.passengerInfo &&
                                    (list.passengerInfo.passenger === 0
                                      ? 0
                                      : list.passengerInfo.passenger)}
                                </span>
                              </div>
                              <div
                                className='driver-service-card-item'
                                style={{ fontSize: '15px' }}
                              >
                                <span>Number of luggage</span>
                                <span>
                                  {list.passengerInfo &&
                                    (list.passengerInfo.luggage === 0
                                      ? 0
                                      : list.passengerInfo.luggage)}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className='driver-info-column'>
                    {list.supplierInfo &&
                      list.supplierInfo.status === 'accepted' &&
                      list.driversInfo &&
                      list.driversInfo.map((item, index) => {
                        return (
                          <div className='driver-service-card'>
                            <div className='driver-service-card-header'>
                              <span>
                                {index ? 'Additional driver' : 'Driver'}
                              </span>
                              {item && item.isVip && (
                                <img
                                  style={{ width: '24px', height: '20px' }}
                                  src='/images/icons/icon-vip.png'
                                />
                              )}
                            </div>
                            <div className='driver-service-card-body'>
                              <div className='driver-service-card-item'>
                                <span>Name</span>
                                <span>
                                  {item.title ? item.title : ''}{' '}
                                  {item.lastName ? item.lastName : ''}
                                </span>
                              </div>
                              <div className='driver-service-card-item'>
                                <span>Age</span>
                                <span>{item.driverAge}</span>
                              </div>
                              <div className='driver-service-card-item'>
                                <span>Phone number</span>
                                <span>{item.phone ? item.phone : ''}</span>
                              </div>
                              {!item.passportFileId &&
                                !item.drivingLicenceFrontFileId &&
                                !item.drivingLicenceBackFileId && (
                                  <div className='driver-docs-pending'>
                                    Driver documents <span>[PENDING]</span>
                                  </div>
                                )}
                              {item.driverDocFileId && (
                                <div className='name-board mt-3'>
                                  <Button
                                    className='partner-btn-desktop-secondary select-vehicle'
                                    variant='brown'
                                    onClick={() => {
                                      onGetFile(item.driverDocFileId)
                                    }}
                                  >
                                    Download driver documents
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    {list.seatInfo &&
                      (list.seatInfo.babySeat ||
                        list.seatInfo.childSeat ||
                        list.seatInfo.boosterSeat) && (
                        <div className='driver-service-card'>
                          <div className='driver-service-card-header'>
                            <span>Child seat</span>
                          </div>
                          <div className='driver-service-card-body'>
                            {list.seatInfo.babySeat ? (
                              <>
                                <div className='driver-service-card-item-seats'>
                                  <div className='driver-service-card-item-left'>
                                    <img src='/images/icons/baby_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc'>
                                      <span>Baby seat</span>
                                      <span>Age: Up to 1 year old</span>
                                      <span>Weight: Under 9 kg/20 lbs</span>
                                    </div>
                                  </div>
                                  <span className='driver-service-card-item-quantity'>
                                    {list.seatInfo.babySeat}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {list.seatInfo.childSeat ? (
                              <>
                                <div className='driver-service-card-item-seats'>
                                  <div className='driver-service-card-item-left'>
                                    <img src='/images/icons/child_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc'>
                                      <span>Child seat</span>
                                      <span>Age: 1-3 years old</span>
                                      <span>
                                        Weight: Under 9-18 kg/20-40 lbs
                                      </span>
                                    </div>
                                  </div>
                                  <span className='driver-service-card-item-quantity'>
                                    {list.seatInfo.childSeat}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {list.seatInfo.boosterSeat ? (
                              <>
                                <div className='driver-service-card-item-seats'>
                                  <div className='driver-service-card-item-left'>
                                    <img src='/images/icons/booster_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc'>
                                      <span>Booster seat</span>
                                      <span>Age: 3-11 years old</span>
                                      <span>
                                        Weight: Under 18-45 kg/ 40-100 lbs
                                      </span>
                                    </div>
                                  </div>
                                  <span className='driver-service-card-item-quantity'>
                                    {list.seatInfo.boosterSeat}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )}
                    {((list.airportPickUpInfo &&
                      list.airportPickUpInfo.flightNumber) ||
                      (list.airportDropOffInfo &&
                        list.airportDropOffInfo.flightNumber)) && (
                      <div
                        className='driver-service-card'
                        style={{ fontSize: '15px' }}
                      >
                        <div className='driver-service-card-header'>
                          <span>Flight information</span>
                        </div>
                        <div className='driver-service-card-body'>
                          {list.airportPickUpInfo &&
                            list.airportPickUpInfo.flightNumber && (
                              <>
                                <div className='driver-service-card-item'>
                                  <span>Arrival flight number</span>
                                  <span>
                                    {list.airportPickUpInfo.flightNumber.toUpperCase()}
                                  </span>
                                </div>
                                <div className='name-board'>
                                  <a
                                    href={
                                      flightIcao
                                        ? `https://flightaware.com/live/flight/${flightIcao}`
                                        : ``
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {bookingInOneDay && flightIcao ? (
                                      <Button
                                        className={`${
                                          list.airportDropOffInfo &&
                                          list.airportDropOffInfo.flightNumber
                                            ? 'mb-2'
                                            : ''
                                        } partner-btn-desktop-secondary select-vehicle`}
                                        variant='brown'
                                      >
                                        Track flight
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </a>
                                </div>
                              </>
                            )}
                          {list.airportDropOffInfo &&
                            list.airportDropOffInfo.flightNumber && (
                              <>
                                <div
                                  className='driver-service-card-item'
                                  style={
                                    list.airportPickUpInfo &&
                                    list.airportPickUpInfo.flightNumber
                                      ? { marginTop: 0 }
                                      : {}
                                  }
                                >
                                  <span>Departure flight number</span>
                                  <span>
                                    {list.airportDropOffInfo.flightNumber.toUpperCase()}
                                  </span>
                                </div>
                                <div className='name-board'>
                                  <a
                                    href={
                                      depFlightIcao
                                        ? `https://flightaware.com/live/flight/${depFlightIcao}`
                                        : ``
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {bookingInOneDay && depFlightIcao ? (
                                      <Button
                                        className='partner-btn-desktop-secondary select-vehicle'
                                        variant='brown'
                                      >
                                        Track flight
                                      </Button>
                                    ) : (
                                      <></>
                                    )}
                                  </a>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    )}
                    {((list.airportPickUpInfo &&
                      !!list.airportPickUpInfo.tailNumber) ||
                      (list.airportDropOffInfo &&
                        !!list.airportDropOffInfo.tailNumber)) && (
                      <div
                        className='driver-service-card'
                        style={{ fontSize: '15px' }}
                      >
                        <div className='driver-service-card-header'>
                          <span>Flight information</span>
                        </div>
                        <div className='driver-service-card-body'>
                          {list.airportPickUpInfo &&
                            !!list.airportPickUpInfo.tailNumber && (
                              <>
                                <div className='driver-service-card-item'>
                                  <span>Arrival tail number</span>
                                  <span>
                                    {list.airportPickUpInfo.tailNumber.toUpperCase()}
                                  </span>
                                </div>
                                {!!list.airportPickUpInfo.fbo && (
                                  <div className='driver-service-card-item'>
                                    <span>FBO</span>
                                    <span>{list.airportPickUpInfo.fbo}</span>
                                  </div>
                                )}
                              </>
                            )}
                          {list.airportDropOffInfo &&
                            !!list.airportDropOffInfo.tailNumber && (
                              <>
                                <div
                                  className='driver-service-card-item'
                                  style={
                                    list.airportPickUpInfo &&
                                    !!list.airportPickUpInfo.tailNumber
                                      ? { marginTop: 0 }
                                      : {}
                                  }
                                >
                                  <span>Departure tail number</span>
                                  <span>
                                    {list.airportDropOffInfo.tailNumber.toUpperCase()}
                                  </span>
                                </div>
                                {!!list.airportDropOffInfo.fbo && (
                                  <div className='driver-service-card-item'>
                                    <span>FBO</span>
                                    <span>{list.airportDropOffInfo.fbo}</span>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    )}
                    {list.trainPickUpInfo && list.trainPickUpInfo.trainNumber && (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Train information</span>
                        </div>
                        <div className='driver-service-card-body'>
                          <div className='driver-service-card-item'>
                            <span>Train number</span>
                            <span>
                              {list.trainPickUpInfo.trainNumber.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {list.trainDropOffInfo &&
                      list.trainDropOffInfo.trainNumber && (
                        <div className='driver-service-card'>
                          <div className='driver-service-card-header'>
                            <span>Train information</span>
                          </div>
                          <div className='driver-service-card-body'>
                            <div className='driver-service-card-item'>
                              <span>Train number</span>
                              <span>
                                {list.trainDropOffInfo.trainNumber.toUpperCase()}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    {list.shipPickUpInfo && list.shipPickUpInfo.shipType && (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Ship information</span>
                        </div>
                        {list.shipPickUpInfo.shipType === 'yacht' && (
                          <div className='driver-service-card-body'>
                            <div className='driver-service-card-item'>
                              <span>Yacht name</span>
                              <span>{list.shipPickUpInfo.yachtName}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Berth/Dock number</span>
                              <span>{list.shipPickUpInfo.dockNumber}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Caption phone number</span>
                              <span>{list.shipPickUpInfo.captainNumber}</span>
                            </div>
                          </div>
                        )}
                        {list.shipPickUpInfo.shipType === 'cruise' && (
                          <div className='driver-service-card-body'>
                            <div className='driver-service-card-item'>
                              <span>Vessel name</span>
                              <span>{list.shipPickUpInfo.vesselName}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Cruise line</span>
                              <span>{list.shipPickUpInfo.cruiseLine}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {list.shipDropOffInfo && list.shipDropOffInfo.shipType && (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Ship information</span>
                        </div>
                        {list.shipDropOffInfo.shipType === 'yacht' && (
                          <div className='driver-service-card-body'>
                            <div className='driver-service-card-item'>
                              <span>Yacht name</span>
                              <span>{list.shipDropOffInfo.yachtName}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Berth/Dock number</span>
                              <span>{list.shipDropOffInfo.dockNumber}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Caption phone number</span>
                              <span>{list.shipDropOffInfo.captainNumber}</span>
                            </div>
                          </div>
                        )}
                        {list.shipDropOffInfo.shipType === 'cruise' && (
                          <div className='driver-service-card-body'>
                            <div className='driver-service-card-item'>
                              <span>Vessel name</span>
                              <span>{list.shipDropOffInfo.vesselName}</span>
                            </div>
                            <div className='driver-service-card-item'>
                              <span>Cruise line</span>
                              <span>{list.shipDropOffInfo.cruiseLine}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {(list.airportPickUpInfo || list.trainPickUpInfo) && (
                      <div className='driver-service-card'>
                        <div
                          className='driver-service-card-header'
                          style={{ fontSize: '15px' }}
                        >
                          <span>Pick-up instructions</span>
                        </div>
                        <p className='mt-3' style={{ fontSize: '15px' }}>
                          {displayPickUpInstructions(
                            isCarRental,
                            list.airportPickUpInfo &&
                              list.airportPickUpInfo.flightType === 'commercial'
                              ? true
                              : false,
                            list.airportPickUpInfo &&
                              list.airportPickUpInfo.flightType === 'private'
                              ? true
                              : false,
                            list.meetAndAssist && list.meetAndAssist.arrival
                              ? true
                              : false,
                            list.trainPickUpInfo ? true : false
                          )}
                        </p>
                        {((list.supplierInfo &&
                          list.supplierInfo.status === 'accepted') ||
                          list.driver) &&
                          ((list.airportPickUpInfo &&
                            list.airportPickUpInfo.flightType ===
                              'commercial') ||
                            list.trainPickUpInfo) && (
                            <div className='name-board'>
                              <Button
                                className='partner-btn-desktop-secondary select-vehicle'
                                variant='brown'
                                onClick={() => {
                                  generatePdf()
                                }}
                              >
                                Get PDF name-board
                              </Button>
                            </div>
                          )}
                      </div>
                    )}
                    {list.serviceNotes && list.serviceNotes !== '' ? (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Notes</span>
                        </div>
                        <p className='mt-3'>{list.serviceNotes}</p>
                      </div>
                    ) : list.services_location_notes &&
                      list.services_location_notes !== '' ? (
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Notes</span>
                        </div>
                        <p className='mt-3'>{list.services_location_notes}</p>
                      </div>
                    ) : (
                      <></>
                    )}

                    {list.addInfo !== '' && list.isOperativeBranch ? (
                      <>
                        <div className='driver-service-card'>
                          <div className='driver-service-card-header'>
                            <span>Client notes</span>
                          </div>
                          <p className='mt-3'>{list.addInfo}</p>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      className='driver-service-card'
                      style={{ fontSize: '15px' }}
                    >
                      <div className='driver-service-card-header'>
                        <Badge href='#' variant='important badge-outlined'>
                          IMPORTANT INFORMATION
                        </Badge>
                      </div>
                      <div
                        className='important-info'
                        style={{ fontSize: '15px' }}
                      >
                        {isCarRental ? (
                          <>
                            <div>Do not show price on the rental agreement</div>
                            <div> ROLZO will pay for the rental fees</div>
                            <div>
                              The driver will pay for the security deposit at
                              delivery
                            </div>
                            <div>
                              The agent delivering and collecting the vehicle
                              must speak English fluently
                            </div>
                          </>
                        ) : (
                          <>
                            <div> ROLZO will pay for all services</div>
                            <div>The chauffeur must speak English fluently</div>
                            <div>
                              The vehicle must include water bottles, wipes, and
                              mints
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {list.supplierInfo &&
                    list.supplierInfo.buyingPrice &&
                    !isMobileBrowser ? (
                      <div className='driver-service-card'>
                        <PartnerPricingDetails
                          serviceType={getLabelFromValue(
                            defaultBookingServicePartner,
                            list.type
                          )}
                          supplierInfo={list.supplierInfo}
                          dispatch={list.dispatch}
                          charges={list.partnerCharges}
                          bookingId={bookingId}
                          bookingPartner={list.dispatch}
                          reloadList={reloadList}
                          isLocalSupplier={list.isOperativeBranch}
                          dispatchStatus={list.dispatchStatus}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    {!list.dispatch &&
                    // !list.driver &&
                    list.supplierInfo &&
                    list.supplierInfo.status !== 'expired' &&
                    list.supplierInfo.status !== 'declined' &&
                    list.supplierInfo.status !== 'declined changes' &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' ? (
                      <div className='partner-accept-container'>
                        {!list.isOperativeBranch ||
                        (list.isOperativeBranch &&
                          list.dispatchStatus !== 'outsourced') ? (
                          <p className='partner-terms'>
                            By clicking “Accept offer”, you agree to our{' '}
                            <span>
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://dotcom-staging.rolzo.com/partners-terms'
                                alt=''
                              >
                                Terms of Service
                              </a>
                              .
                            </span>
                          </p>
                        ) : (
                          <></>
                        )}

                        <div className='d-flex'>
                          {!list.isOperativeBranch ? (
                            <>
                              <Button
                                className={`${
                                  isLocalSupplier
                                    ? 'partner-btn-decline-red'
                                    : 'partner-btn-desktop-decline'
                                } mr-2 select-vehicle`}
                                onClick={() => {
                                  setShowDeclineConfirmationModal(true)
                                }}
                              >
                                Decline
                              </Button>
                              <Button
                                className='select-btn select-vehicle partner-btn-desktop'
                                variant='brown'
                                onClick={() => {
                                  onAcceptBooking(list._id)
                                }}
                              >
                                Accept offer
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          {list.isOperativeBranch ? (
                            <>
                              {list.dispatchStatus === 'outsourced' &&
                              list.outsourcedSuppliers ? (
                                <div style={{ marginTop: '1rem' }}>
                                  <Button
                                    className={`partner-cancel-dispatch mr-2 select-vehicle`}
                                    onClick={() => {
                                      setshowDispatchCancelModal(true)
                                    }}
                                  >
                                    Cancel outsource
                                  </Button>
                                  <Button
                                    className='select-btn select-vehicle partner-btn-desktop'
                                    variant='brown'
                                    onClick={() => {
                                      setshowDispatchModal(true)
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  <Button
                                    className={`partner-cancel-dispatch mr-2 select-vehicle`}
                                    style={{ minWidth: '140px' }}
                                    variant='brown'
                                    onClick={() => {
                                      setshowDispatchModal(true)
                                    }}
                                  >
                                    Outsource
                                  </Button>
                                  <Button
                                    className='select-btn select-vehicle partner-btn-desktop'
                                    variant='brown'
                                    onClick={() => {
                                      onAcceptBooking(list._id)
                                    }}
                                  >
                                    Accept offer
                                  </Button>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className='partner-accept-container'>
                        {list.isOperativeBranch ? (
                          <div className=''>
                            <Button
                              className={`partner-cancel-dispatch mr-2 mt-2 select-vehicle`}
                              onClick={() => {
                                setshowDispatchCancelModal(true)
                              }}
                            >
                              Cancel dispatch
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {list.isOperativeBranch ? (
                  <>
                    <Formik
                      enableReinitialize={true}
                      initialValues={null}
                      validateOnBlur={false}
                      validateOnChange={false}
                      isInitialValid
                    >
                      <DispatchPartnerModal
                        onHide={() => {
                          setshowDispatchModal(false)
                        }}
                        show={showDispatchModal && !isMobileBrowser}
                        currentItem={list}
                        updateBooking={reloadList}
                        localCurrency={list.supplierInfo.supplierCurrency}
                        dispatchedSuppliers={suppliersList}
                        supplierCurrency={list.supplierInfo.supplierCurrency}
                        buyingPrice={opSupplierPrice}
                        originalPrice={list.supplierInfo.buyingPrice}
                        bookingId={bookingId}
                        isApiBooking={list.apiBooking ? true : false}
                        serviceNotes={
                          list.serviceNotes
                            ? list.serviceNotes
                            : list.services_location_notes
                            ? list.services_location_notes
                            : ''
                        }
                      />
                    </Formik>
                    <DispatchCancelModal
                      onHide={() => {
                        setshowDispatchCancelModal(false)
                      }}
                      show={showDispatchCancelModal}
                      oneSupplier={false}
                      supplierInfo={list.supplierInfo}
                      handleCancelDispatch={onCancelDispatch}
                      islocalSupplier={true}
                      isMobileBrowser={isMobileBrowser}
                    />
                    <AdditionalChargesModal
                      show={showAddChargeModal}
                      onHide={() => {
                        setShowAddChargeModal(false)
                      }}
                      supplierCurrency={list.supplierInfo.supplierCurrency}
                      isLocalSupplier={list.isOperativeBranch}
                    />
                  </>
                ) : (
                  <></>
                )}
                <DeclineOfferModal
                  onHide={() => {
                    setShowDeclineConfirmationModal(false)
                  }}
                  show={showDeclineConfirmationModal}
                  bookingId={bookingId}
                  supplierId={supplierId}
                  afterDecline={() => {
                    setShowDeclineConfirmationModal(false)
                    setShowDeclinedModal(true)
                    setTimeout(
                      function() {
                        setShowDeclinedModal(false)
                        history.push(
                          `${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/new-bookings`
                        )
                      }.bind(this),
                      1500
                    )
                  }}
                />
                <ConfirmationModal
                  onHide={() => {
                    setShowDeclineChangesConfirmationModal(false)
                  }}
                  handleFunction={() => {
                    onDeclineBookingChanges(
                      bookingChanges,
                      list._id,
                      supplierId
                    )
                  }}
                  show={showDeclineChangesConfirmationModal}
                  confirmationText={
                    'Are you sure you want to decline the booking change(s)?'
                  }
                  declineChanges
                />
              </>
            )}

            {selectedTab === 1 &&
              list.driver &&
              (!list.supplierInfo ||
                (list.supplierInfo &&
                  list.supplierInfo.status !== 'expired' &&
                  list.supplierInfo.status !== 'declined' &&
                  list.supplierInfo.status !== 'declined changes')) &&
              list.status !== 'cancellation_accepted_by_agent' &&
              list.status !== 'cancelled_by_agent' &&
              !list.isDriverAndNotAccepted && (
                <>
                  <div
                    className={
                      list.dispatch || list.driver
                        ? 'driver-info-layout-banner'
                        : 'driver-info-layout'
                    }
                  >
                    <div className='driver-info-column'>
                      <div className='driver-service-card'>
                        <div className='driver-service-card-header'>
                          <span>Chauffeur's trip updates</span>
                        </div>
                        <div className='driver-service-card-body-container'>
                          <div class='step'>
                            <div class='v-stepper'>
                              <div
                                class={
                                  updateOnTheWay
                                    ? 'update-circle-filled'
                                    : 'update-circle'
                                }
                              />
                              <div
                                className={
                                  updateOnTheWay
                                    ? 'vertical-filled'
                                    : 'vertical-dashed'
                                }
                              ></div>
                            </div>
                            <div className='driver-service-card-container'>
                              <div
                                className={
                                  updateOnTheWay
                                    ? 'driver-service-card-item-updates updates-complete indicator-black'
                                    : bookingInTwoHours
                                    ? 'driver-service-card-item-updates indicator-red'
                                    : 'driver-service-card-item-updates'
                                }
                              >
                                <span>1</span>
                                <span
                                  style={
                                    bookingInTwoHours && !updateOnTheWay
                                      ? { color: '#d0021b' }
                                      : {}
                                  }
                                >
                                  {listExists &&
                                  !list.chauffeurOfBooking &&
                                  list.partnerOfBooking ? (
                                    <>
                                      {bookingInTwoHours && !updateOnTheWay
                                        ? `Is ${
                                            list.driver.fullName.split(' ')[0]
                                          } is on the way?`
                                        : `${
                                            list.driver.fullName.split(' ')[0]
                                          } is on the way`}
                                    </>
                                  ) : (
                                    <>
                                      {bookingInTwoHours && !updateOnTheWay
                                        ? `Are you on the way?`
                                        : `You are on the way`}
                                    </>
                                  )}
                                </span>
                                <span
                                  style={
                                    bookingInTwoHours && !updateOnTheWay
                                      ? { color: '#d0021b' }
                                      : {}
                                  }
                                >
                                  {!checkBookingAfterADay(list.pickUpDate) &&
                                    !updateOnTheWay &&
                                    bookingTimeLeft}
                                </span>
                                {updateOnTheWay && (
                                  <img
                                    style={{
                                      justifySelf: 'flex-end',
                                      alignSelf: 'center',
                                    }}
                                    width={20}
                                    src='/images/icons/icon-checklist-black.png'
                                    alt=''
                                  ></img>
                                )}
                              </div>
                              {updateOnTheWay && updateOnTheWay.time && (
                                <span className='status-update-timestamp'>
                                  {moment
                                    .utc(updateOnTheWay.time)
                                    .format(newTimeFormat)}
                                </span>
                              )}
                            </div>
                          </div>

                          <div class='step'>
                            <div class='v-stepper'>
                              <div
                                class={
                                  updateOnLocation
                                    ? 'update-circle-filled'
                                    : 'update-circle'
                                }
                              />
                              <div
                                className={
                                  updateOnLocation
                                    ? 'vertical-filled'
                                    : 'vertical-dashed'
                                }
                              ></div>
                            </div>
                            <div className='driver-service-card-container'>
                              <div
                                className={`driver-service-card-item-updates 
                              ${updateOnLocation &&
                                'updates-complete indicator-black'}
                            `}
                              >
                                <span> 2 </span>
                                <span
                                  style={
                                    updateOnTheWay && !updateOnLocation
                                      ? { color: '#d0021b' }
                                      : {}
                                  }
                                >
                                  {listExists &&
                                  !list.chauffeurOfBooking &&
                                  list.partnerOfBooking ? (
                                    <>
                                      {' '}
                                      {updateOnTheWay && !updateOnLocation
                                        ? `Has ${
                                            list.driver.fullName.split(' ')[0]
                                          } arrived?`
                                        : `${
                                            list.driver.fullName.split(' ')[0]
                                          } has arrived`}
                                    </>
                                  ) : (
                                    <>
                                      {updateOnTheWay && !updateOnLocation
                                        ? `Have you arrived?`
                                        : `You have arrived`}
                                    </>
                                  )}
                                </span>
                                <span
                                  style={
                                    bookingInTwoHours && !updateOnLocation
                                      ? { color: '#d0021b' }
                                      : {}
                                  }
                                >
                                  {!checkBookingAfterADay(list.pickUpDate) &&
                                    !updateOnLocation &&
                                    updateOnTheWay &&
                                    bookingTimeLeft}
                                </span>
                                {updateOnLocation && (
                                  <img
                                    style={{
                                      justifySelf: 'flex-end',
                                      alignSelf: 'center',
                                    }}
                                    width={20}
                                    src='/images/icons/icon-checklist-black.png'
                                    alt=''
                                  ></img>
                                )}
                              </div>
                              {updateOnLocation && updateOnLocation.time && (
                                <span className='status-update-timestamp'>
                                  {moment
                                    .utc(updateOnLocation.time)
                                    .format(newTimeFormat)}
                                </span>
                              )}
                            </div>
                          </div>

                          {!isCarRental ? (
                            <>
                              <div class='step'>
                                <div class='v-stepper'>
                                  <div
                                    class={
                                      updatePassengerOnboard
                                        ? 'update-circle-filled'
                                        : 'update-circle'
                                    }
                                  ></div>
                                  <div
                                    className={
                                      updatePassengerOnboard
                                        ? 'vertical-filled'
                                        : 'vertical-dashed'
                                    }
                                  ></div>
                                </div>
                                <div className='driver-service-card-container'>
                                  <div
                                    className={`driver-service-card-item-updates 
                            ${updatePassengerOnboard &&
                              'updates-complete indicator-black'}
                          `}
                                  >
                                    <span> 3 </span>
                                    <span
                                      style={
                                        updateOnTheWay &&
                                        updateOnLocation &&
                                        !updatePassengerOnboard
                                          ? { color: '#d0021b' }
                                          : {}
                                      }
                                    >
                                      {updateOnTheWay &&
                                      updateOnLocation &&
                                      !updatePassengerOnboard
                                        ? 'Is the passenger on board?'
                                        : 'The passenger is on board'}
                                    </span>
                                    {updatePassengerOnboard && (
                                      <img
                                        style={{
                                          justifySelf: 'flex-end',
                                          alignSelf: 'center',
                                        }}
                                        width={20}
                                        src='/images/icons/icon-checklist-black.png'
                                        alt=''
                                      ></img>
                                    )}
                                  </div>
                                  {updatePassengerOnboard &&
                                    updatePassengerOnboard.time && (
                                      <span className='status-update-timestamp'>
                                        {moment
                                          .utc(updatePassengerOnboard.time)
                                          .format(newTimeFormat)}
                                      </span>
                                    )}
                                </div>
                              </div>

                              {showTenMinsAwayUpdate ? (
                                <div class='step'>
                                  <div class='v-stepper'>
                                    <div
                                      class={
                                        updateTenMinsAway
                                          ? 'update-circle-filled'
                                          : 'update-circle'
                                      }
                                    ></div>
                                    <div
                                      className={
                                        updatePassengerOnboard
                                          ? 'vertical-filled'
                                          : 'vertical-dashed'
                                      }
                                    ></div>
                                  </div>
                                  <div className='driver-service-card-container'>
                                    <div
                                      className={`driver-service-card-item-updates 
                            ${updateTenMinsAway &&
                              'updates-complete indicator-black'}
                          `}
                                    >
                                      <span> 4 </span>
                                      <span
                                        style={
                                          updateOnTheWay &&
                                          updateOnLocation &&
                                          updatePassengerOnboard &&
                                          !updateTenMinsAway
                                            ? { color: '#d0021b' }
                                            : {}
                                        }
                                      >
                                        {updateOnTheWay &&
                                        updateOnLocation &&
                                        updatePassengerOnboard &&
                                        !updateTenMinsAway
                                          ? '10 mins away from destination?'
                                          : '10 mins away from destination'}
                                      </span>
                                      {updateTenMinsAway && (
                                        <img
                                          style={{
                                            justifySelf: 'flex-end',
                                            alignSelf: 'center',
                                          }}
                                          width={20}
                                          src='/images/icons/icon-checklist-black.png'
                                          alt=''
                                        ></img>
                                      )}
                                    </div>
                                    {updateTenMinsAway &&
                                      updateTenMinsAway.time && (
                                        <span className='status-update-timestamp'>
                                          {moment
                                            .utc(updateTenMinsAway.time)
                                            .format(newTimeFormat)}
                                        </span>
                                      )}
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div class='step'>
                                <div class='v-stepper'>
                                  <div
                                    class={
                                      updatePassengerDroppedOff
                                        ? 'update-circle-filled'
                                        : 'update-circle'
                                    }
                                  ></div>
                                </div>
                                <>
                                  <div className='driver-service-card-container'>
                                    <div
                                      className={`driver-service-card-item-updates 
                            ${updatePassengerDroppedOff &&
                              'updates-complete indicator-black'}
                          `}
                                    >
                                      <span>
                                        {showTenMinsAwayUpdate ? 5 : 4}
                                      </span>
                                      <span
                                        style={
                                          updateOnTheWay &&
                                          updateOnLocation &&
                                          updatePassengerOnboard &&
                                          ((updateTenMinsAway &&
                                            showTenMinsAwayUpdate) ||
                                            (!updateTenMinsAway &&
                                              !showTenMinsAwayUpdate)) &&
                                          !updatePassengerDroppedOff
                                            ? { color: '#d0021b' }
                                            : {}
                                        }
                                      >
                                        {updateOnTheWay &&
                                        updateOnLocation &&
                                        updatePassengerOnboard &&
                                        ((updateTenMinsAway &&
                                          showTenMinsAwayUpdate) ||
                                          (!updateTenMinsAway &&
                                            !showTenMinsAwayUpdate)) &&
                                        !updatePassengerDroppedOff
                                          ? list.dropOffLocation
                                            ? `Was the passenger dropped off?`
                                            : `Was the passenger dropped off?`
                                          : list.dropOffLocation
                                          ? `The passenger was dropped off`
                                          : `The passenger was dropped off`}
                                      </span>
                                      {updatePassengerDroppedOff && (
                                        <img
                                          style={{
                                            justifySelf: 'flex-end',
                                            alignSelf: 'center',
                                          }}
                                          width={20}
                                          src='/images/icons/icon-checklist-black.png'
                                          alt=''
                                        ></img>
                                      )}
                                    </div>
                                    {updatePassengerDroppedOff &&
                                      updatePassengerDroppedOff.time && (
                                        <span className='status-update-timestamp'>
                                          {moment
                                            .utc(updatePassengerDroppedOff.time)
                                            .format(newTimeFormat)}
                                        </span>
                                      )}
                                  </div>
                                </>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class='step'>
                                <div class='v-stepper'>
                                  <div
                                    class={
                                      updateVehicleDelivered
                                        ? 'update-circle-filled'
                                        : 'update-circle'
                                    }
                                  ></div>
                                  <div
                                    className={
                                      updateVehicleDelivered
                                        ? 'vertical-filled'
                                        : 'vertical-dashed'
                                    }
                                  ></div>
                                </div>
                                <div className='driver-service-card-container'>
                                  <div
                                    className={`driver-service-card-item-updates 
                            ${updateVehicleDelivered &&
                              'updates-complete indicator-black'}
                          `}
                                  >
                                    <span> 3 </span>
                                    <span
                                      style={
                                        updateOnTheWay &&
                                        updateOnLocation &&
                                        !updateVehicleDelivered
                                          ? { color: '#d0021b' }
                                          : {}
                                      }
                                    >
                                      {updateOnTheWay &&
                                      updateOnLocation &&
                                      !updateVehicleDelivered
                                        ? 'Was the vehicle delivered?'
                                        : 'The vehicle was delivered'}
                                    </span>
                                    {updateVehicleDelivered && (
                                      <img
                                        style={{
                                          justifySelf: 'flex-end',
                                          alignSelf: 'center',
                                        }}
                                        width={20}
                                        src='/images/icons/icon-checklist-black.png'
                                        alt=''
                                      ></img>
                                    )}
                                  </div>
                                  {updateVehicleDelivered &&
                                    updateVehicleDelivered.time && (
                                      <span className='status-update-timestamp'>
                                        {moment
                                          .utc(updateVehicleDelivered.time)
                                          .format(newTimeFormat)}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          style={{ marginBottom: '0em' }}
                          className='driver-service-card-body-container'
                        >
                          <div className='driver-service-card-container'>
                            <div
                              style={{
                                marginLeft: '0.7rem',
                                marginRight: '0.7rem',
                                display: 'block',
                              }}
                              className={'driver-service-card-item-updates'}
                            >
                              <label>Link</label>
                              <a
                                style={{ wordWrap: 'break-word' }}
                                href={getHostLink(bookingId)}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-brown pointer-link'
                              >
                                {getHostLink(bookingId)}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Download app on desktop */}
                    <div className='driver-info-column'>
                      <>
                        {((isCarRental &&
                          (!updateOnTheWay ||
                            !updateOnLocation ||
                            !updateVehicleDelivered)) ||
                          (!isCarRental &&
                            (!updateOnTheWay ||
                              !updateOnLocation ||
                              !updatePassengerOnboard ||
                              !updatePassengerDroppedOff))) &&
                          bookingInTwoHours &&
                          !isLocalSupplier && (
                            <div className='driver-service-card'>
                              <div className='driver-service-card-header'>
                                <span className='caution'>
                                  <span className='caution-symbol'>!</span>
                                  Action required
                                </span>
                              </div>
                              <p className='partner-app-para'>
                                The chauffeur must complete a trip update via
                                the App.
                              </p>
                            </div>
                          )}
                        {!driverExists && (
                          <div className='driver-service-card'>
                            <div className='driver-service-card-header-app'>
                              <img
                                style={{ width: '18%' }}
                                src='/images/icons/icon-app.png'
                                alt=''
                              />
                              <span>
                                ROLZO Partner App for{' '}
                                {isCarRental ? 'agents' : 'chauffeur'}
                              </span>
                            </div>
                            <div>
                              {isCarRental ? (
                                <p className='partner-app-para'>
                                  {formatSentenceCase(list.driver.fullName)} is
                                  required to download the ROLZO Partner App to
                                  be able to complete the real-time delivery
                                  status updates. Please note that no price will
                                  be displayed to the agent.
                                </p>
                              ) : (
                                <p className='partner-app-para'>
                                  {formatSentenceCase(list.driver.fullName)} is
                                  required to download the ROLZO Partner App for
                                  chauffeurs to be able to accept the booking
                                  and complete real-time trip status updates.
                                  Please note that no price will be displayed to
                                  the chauffeur.
                                </p>
                              )}
                            </div>
                            <Button
                              className='partner-btn-desktop-secondary select-vehicle'
                              variant='brown'
                              onClick={() => sendSMS()}
                            >
                              Send app download link by SMS
                            </Button>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                  {/* <div className='driver-info-column'>
                    <>
                      {((isCarRental &&
                        (!updateOnTheWay ||
                          !updateOnLocation ||
                          !updateVehicleDelivered)) ||
                        (!isCarRental &&
                          (!updateOnTheWay ||
                            !updateOnLocation ||
                            !updatePassengerOnboard ||
                            !updatePassengerDroppedOff))) &&
                        bookingInTwoHours &&
                        !isLocalSupplier && (
                          <div className='driver-service-card'>
                            <div className='driver-service-card-header'>
                              <span className='caution'>
                                <span className='caution-symbol'>!</span>
                                Action required
                              </span>
                            </div>
                            <p className='partner-app-para'>
                              The chauffeur must complete a trip update via the
                              App.
                            </p>
                          </div>
                        )}
                      {!driverExists && (
                        <div className='driver-service-card'>
                          <div className='driver-service-card-header-app'>
                            <img src='/images/icons/icon-app.png' />
                            <span>
                              ROLZO Partner App for{' '}
                              {isCarRental ? 'agents' : 'chauffeur'}
                            </span>
                          </div>
                          <div>
                            {isCarRental ? (
                              <p className='partner-app-para'>
                                {formatSentenceCase(list.driver.fullName)} is
                                required to download the ROLZO Partner App to be
                                able to complete the real-time delivery status
                                updates. Please note that no price will be
                                displayed to the agent.
                              </p>
                            ) : (
                              <p className='partner-app-para'>
                                {formatSentenceCase(list.driver.fullName)} is
                                required to download the ROLZO Partner App for
                                chauffeurs to be able to accept the booking and
                                complete real-time trip status updates. Please
                                note that no price will be displayed to the
                                chauffeur.
                              </p>
                            )}
                          </div>
                          <Button
                            className='partner-btn-desktop-secondary select-vehicle'
                            variant='brown'
                            onClick={() => sendSMS()}
                          >
                            Send app download link by SMS
                          </Button>
                        </div>
                      )}
                    </>
                  </div> */}
                </>
              )}
          </div>
        )}
      </>
    )
  }

  const renderModalContent = () => {
    return (
      <PartnerAcceptedModal
        isBookingAccepted={showAcceptedModal}
        isBookingDeclined={showDeclinedModal}
        isBookingChangesAccpted={showAccptedChangesModal}
        isBookingChangesDeclined={showDeclinedChangesModal}
      />
    )
  }

  const onCancelDispatch = async () => {
    try {
      //cancel dispatch for all partners
      if (isMobileBrowser) {
        await cancelDispatchBookingMobile(bookingId)
      } else {
        await cancelDispatchBooking(bookingId)
      }
      reloadList()
      setshowDispatchCancelModal(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderLocalSupplierActions = list => {
    // Check if the status is one of the completed types
    const isCompletedStatus = [
      'completed',
      'cancellation_accepted_by_agent',
      'cancelled_by_agent',
    ].includes(list.status)

    if (isCompletedStatus) {
      return null // Or return some default UI if needed
    }

    // Handling for outsourced bookings
    if (list.dispatchStatus === 'outsourced' && list.outsourcedSuppliers) {
      return (
        <div className='driver-booking-cta-footer'>
          <Button
            className='select-btn select-vehicle mb-2'
            style={{
              width: '100%',
              fontWeight: '900',
              fontSize: '16px',
              textShadow: '0.25px 0 #ffffff',
            }}
            variant='brown'
            onClick={() => setshowDispatchModal(true)}
          >
            Edit
          </Button>
          <Button
            className='partner-btn-desktop-tertiary select-vehicle'
            style={{
              width: '100%',
              fontWeight: '900',
              textShadow: '0.25px 0 #a79779',
            }}
            onClick={() => setshowDispatchCancelModal(true)}
          >
            Cancel outsource
          </Button>
        </div>
      )
    }

    // Assign vehicle or chaffuer
    if (
      list.supplierInfo &&
      list.supplierInfo.status !== 'expired' &&
      list.supplierInfo.status !== 'declined' &&
      list.supplierInfo.status !== 'declined changes' &&
      list.dispatchStatus === 'accepted'
    ) {
      return (
        <div className='driver-booking-cta-footer'>
          {(!list.partnerVehicleId || !list.partnerChauffeurId) &&
          !list.driver &&
          !list.dispatchChauffeurAssigned &&
          (!list.isOperativeBranch ||
            (list.dispatch.supplierId === list.supplierInfo.supplierId &&
              list.isOperativeBranch)) ? (
            <Button
              className='select-btn select-vehicle mb-2'
              style={{
                width: '100%',
                fontWeight: '900',
                fontSize: '16px',
                textShadow: '0.25px 0 #ffffff',
              }}
              variant='brown'
              onClick={() => {
                assignChauffeur()
              }}
            >
              {list.partnerChauffeurId
                ? 'Assign vehicle'
                : `Assign ${isCarRental ? 'agent' : 'chauffeur'}`}
            </Button>
          ) : (
            <></>
          )}
          <Button
            className='partner-btn-desktop-tertiary select-vehicle'
            style={{
              width: '100%',
              fontWeight: '900',
              textShadow: '0.25px 0 #a79779',
            }}
            onClick={() => {
              setshowDispatchCancelModal(true)
            }}
          >
            Cancel dispatch
          </Button>
        </div>
      )
    }

    // Default action buttons for non-outsourced or pending
    return (
      <div className='driver-booking-cta-footer'>
        <Button
          className='select-btn select-vehicle mb-2'
          style={{
            width: '100%',
            fontWeight: '900',
            fontSize: '16px',
            textShadow: '0.25px 0 #ffffff',
          }}
          variant='brown'
          onClick={() => onAcceptBooking(list._id)}
        >
          Accept offer
        </Button>
        <Button
          className='partner-btn-desktop-tertiary select-vehicle'
          style={{
            width: '100%',
            fontWeight: '900',
            textShadow: '0.25px 0 #a79779',
          }}
          onClick={() => setshowDispatchModal(true)}
        >
          Outsource
        </Button>
      </div>
    )
  }

  if (showDispatchModal && isMobileBrowser) {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={null}
        validateOnBlur={false}
        validateOnChange={false}
        isInitialValid
      >
        <DispatchPartnerModalMobile
          onHide={() => {
            setshowDispatchModal(false)
          }}
          show={showDispatchModal && !isMobileBrowser}
          currentItem={list}
          updateBooking={reloadList}
          localCurrency={list.supplierInfo.supplierCurrency}
          dispatchedSuppliers={suppliersList}
          supplierCurrency={list.supplierInfo.supplierCurrency}
          buyingPrice={opSupplierPrice}
          originalPrice={list.supplierInfo.buyingPrice}
          bookingId={bookingId}
          isApiBooking={list.apiBooking ? true : false}
          serviceNotes={
            list.serviceNotes
              ? list.serviceNotes
              : list.services_location_notes
              ? list.services_location_notes
              : ''
          }
        />
      </Formik>
    )
  }

  // if (showAddChargeModal && isMobileBrowser) {
  //   return (
  //     <AdditionalChargesModalMobile
  //       onHide={() => {
  //         setShowAddChargeModal(false)
  //       }}
  //     />
  //   )
  // }

  return (
    <>
      {/* {listExists &&
      list.number &&
      (locationType || list.pickUpLocation.locationType) ? ( */}
      {listExists && list.number ? (
        <>
          <SmallUp>
            <DefaultDriverDesktopLayout
              data={list}
              renderContent={
                showAcceptedModal || showAccptedChangesModal
                  ? renderModalContent
                  : showDeclinedModal || showDeclinedChangesModal
                  ? renderModalContent
                  : renderContent
              }
            />
          </SmallUp>
          <SmallDown>
            <>
              {!showAcceptedModal &&
              !showDeclinedModal &&
              !showAccptedChangesModal &&
              !showDeclinedChangesModal ? (
                <div style={{ height: '100vh' }}>
                  <div
                    className={
                      !displaySupport
                        ? 'driver-booking-container-header-booking'
                        : 'driver-booking-container-header-booking-support'
                    }
                  >
                    <img
                      className='driver-back-icon mx-50%'
                      src='/images/icon-back.png'
                      onClick={e => {
                        redirectBookings(e)
                      }}
                      alt=''
                    />
                    <span style={{ paddingTop: '0.2em' }}>
                      Booking #{list.number}
                    </span>
                    <img
                      className=''
                      src={'/images/icons/support-icon.png'}
                      style={
                        displaySupport
                          ? { visibility: 'hidden' }
                          : { height: '5%', width: '5%', paddingTop: '0.2rem' }
                      }
                      onClick={() => {
                        history.push(
                          `${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/${bookingId}/support`
                        )
                        // setDisplaySupport(!displaySupport)
                        // if (!displaySupport) setSelectedTab(null)
                        // else setSelectedTab(0)
                      }}
                      alt=''
                    />
                  </div>
                  <div
                    className='driver-booking-container'
                    style={
                      selectedTab === 0
                        ? { paddingBottom: '10em', marginTop: '5em' }
                        : selectedTab === 1
                        ? { marginTop: '5em' }
                        : {}
                    }
                  >
                    {!displaySupport ? (
                      <>
                        {((list.supplierInfo &&
                          list.supplierInfo.status === 'accepted') ||
                          list.driver) && (
                          <div className='tabs-container'>
                            <div className='d-flex justify-content-start'>
                              <div className='custom-tabs-nav-driver light-tabs nav'>
                                <Button
                                  onClick={() => changeTab(0)}
                                  className={`nav-link ${
                                    selectedTab ? '' : 'active'
                                  }`}
                                  style={{
                                    width: '48%',
                                    top: '1px',
                                  }}
                                >
                                  Information
                                </Button>
                                <Button
                                  onClick={() => changeTab(1)}
                                  className={`nav-link ${
                                    !selectedTab ? '' : 'active'
                                  }`}
                                  style={{
                                    width: 'calc(100vw - 13em)',
                                    top: '1px',
                                    position: 'absolute',
                                    left: '12rem',
                                  }}
                                >
                                  Trip updates
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <Support token={token} bookingId={bookingId} />
                      </>
                    )}

                    {/* Outsource banner (mobile) */}
                    {listExists && list.isOperativeBranch ? (
                      <>
                        {(list.dispatchStatus === 'dispatched' ||
                          list.dispatchStatus === 'outsourced') &&
                        list.outsourcedSuppliers ? (
                          <>
                            <div className='partner-banner-mobile-warning mt-4'>
                              <div className='d-flex'>
                                <div
                                  className='partnerContainer'
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                  }}
                                >
                                  <img
                                    style={{
                                      // height: '40px',
                                      width: '1.75rem',
                                      height: '1.75rem',
                                      marginRight: '1em',
                                    }}
                                    src='/images/icons/partner/arrow-yellow.png'
                                    alt=''
                                  />
                                  <p>
                                    The booking was outsourced to{' '}
                                    {formattedString(list.outsourcedSuppliers)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {list.dispatchStatus === 'accepted' &&
                            list.dispatch &&
                            list.supplierInfo &&
                            list.dispatch.supplierId !==
                              list.supplierInfo.supplierId &&
                            !list?.driver?.fullName ? (
                              <>
                                <div className='partner-banner-mobile-grey mt-4'>
                                  <div className='d-flex'>
                                    <div
                                      className='partnerContainer'
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                      }}
                                    >
                                      <img
                                        style={{
                                          // height: '40px',
                                          width: '1.75rem',
                                          height: '1.75rem',
                                          marginRight: '1em',
                                        }}
                                        src='/images/icons/partner/arrow-black.png'
                                        alt=''
                                      />
                                      <p>
                                        [Outsourced] The booking was accepted by{' '}
                                        {list.dispatch.name}.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Partner changes banner (mobile) */}
                    {/* {listExists &&
                    list.status !== 'completed' &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    list.supplierInfo &&
                    list.supplierInfo.status === 'accepted' &&
                    tokenUser &&
                    tokenUser.isPartner &&
                    bookingChanges &&
                    thereAreNewChanges ? (
                      <>
                        <div className='partner-banner-mobile mt-4'>
                          <div>
                            <div
                              className='partnerContainer'
                              style={{ fontSize: '16px' }}
                            >
                              <div className='d-flex mb-3'>
                                <div
                                  className='imageContainer'
                                  style={{
                                    alignSelf: 'start',
                                  }}
                                >
                                  <img
                                    src='/images/icons/icon-warning.png'
                                    alt=''
                                  />
                                </div>
                                <p style={{ marginTop: '0.1rem' }}>
                                  Action required
                                </p>
                              </div>
                              <span>
                                Please review and confirm the booking change(s):
                              </span>
                              <span className='mt-1'>
                                {bookingChanges
                                  .filter(
                                    item =>
                                      item.status_admin === 'sent' &&
                                      (item.status_partner === 'opened' ||
                                        item.status_partner === 'not_opened')
                                  )
                                  .map(info => {
                                    let newText = info.message.replace(
                                      /<br>/g,
                                      ' '
                                    )
                                    return (
                                      <span className='w-100'>{`— ${newText}`}</span>
                                    )
                                  })}
                              </span>
                            </div>
                            <div
                              className='d-flex justify-content-center'
                              style={{ gridGap: '1em' }}
                            >
                              <Button
                                className='partner-btn-mobile-decline select-vehicle align-self-end mb-3'
                                style={{
                                  fontWeight: '900',
                                  textShadow: '0.25px 0 #a79779',
                                }}
                                onClick={() => {
                                  setShowDeclineChangesConfirmationModal(true)
                                }}
                              >
                                Decline
                              </Button>
                              <Button
                                className='select-chauffeur align-self-end  mb-3'
                                style={{
                                  whiteSpace: 'pre',
                                  width: '100%',
                                  marginRight: '15px',
                                  fontWeight: '900',
                                  textShadow: '0.25px 0 #ffffff',
                                }}
                                variant='brown'
                                onClick={() => {
                                  onAcceptBookingChanges(
                                    bookingChanges,
                                    list._id,
                                    supplierId
                                  )
                                }}
                              >
                                {'Confirm change(s)'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}

                    {/* Assign chauffeur/agent banner (before 24 hours or as soon as possilbe) (mobile) */}
                    {(selectedTab === 0 || selectedTab === 1) &&
                      list.supplierInfo &&
                      list.supplierInfo.status !== 'expired' &&
                      list.supplierInfo.status !== 'declined' &&
                      list.supplierInfo.status !== 'declined changes' &&
                      list.status !== 'completed' &&
                      list.status !== 'cancellation_accepted_by_agent' &&
                      list.status !== 'cancelled_by_agent' &&
                      list.dispatch &&
                      !list.dispatchChauffeurAssigned &&
                      (!list.partnerChauffeurId || !list.partnerVehicleId) &&
                      !list.driver && (
                        <>
                          {list.dispatch &&
                            !list.partnerChauffeurId && [
                              checkBookingAfterADay(list.pickUpDate) ? (
                                <div className='partner-banner-grey-assign mt-4'>
                                  <div className='imageContainer'>
                                    <img
                                      src='/images/icons/icon-chauffeur-gold.png'
                                      alt=''
                                    />
                                  </div>
                                  <div className='partnerContainer'>
                                    <span>
                                      {isCarRental
                                        ? 'Assign agent 24 hours before delivery.'
                                        : 'Assign chauffeur 24 hours before pick-up.'}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className='partner-banner mt-4'>
                                  <div className='imageContainer'>
                                    <img
                                      src='/images/icons/icon-warning.png'
                                      alt=''
                                    />
                                  </div>
                                  <div className='partnerContainer'>
                                    <span>
                                      {isCarRental
                                        ? 'The agent is not yet assigned.'
                                        : 'The chauffeur is not yet assigned.'}
                                    </span>
                                  </div>
                                </div>
                              ),
                            ]}
                        </>
                      )}

                    {/* Assigned chauffeur/agent banner  (mobile) */}
                    {selectedTab === 0 &&
                      list.status !== 'cancellation_accepted_by_agent' &&
                      list.status !== 'cancelled_by_agent' &&
                      list.supplierInfo &&
                      list.supplierInfo.status !== 'expired' &&
                      !list.isDriverAndNotAccepted &&
                      list.driver &&
                      list.driver.fullName && (
                        <div className='partner-banner-grey mt-4'>
                          <div className='driven-by'>
                            <div className='imageContainer'>
                              <img
                                src='/images/icons/icon_chauffeur.png'
                                alt=''
                              />
                            </div>
                            <div className='partnerContainer'>
                              <p style={{ fontWeight: '900' }}>
                                {isCarRental
                                  ? 'Vehicle to be delivered by'
                                  : 'Driven by'}{' '}
                                {list.driver.fullName.split(' ')[0]}
                                {list.dispatch &&
                                list.supplierInfo &&
                                list.isOperativeBranch &&
                                list.dispatch.supplierId !==
                                  list.supplierInfo.supplierId
                                  ? ` · Outsourced to ${list.dispatch.name}`
                                  : ''}
                              </p>
                              <p>{list.driver.phone}</p>
                              <span>
                                {list.partnerVehicle || list.vehicleName} ·{' '}
                                {list.driver.plateNo
                                  ? list.driver.plateNo.toUpperCase()
                                  : ''}
                              </span>
                            </div>
                          </div>
                          {list.status !== 'completed' &&
                            tokenUser &&
                            tokenUser.isPartner && (
                              <img
                                style={{
                                  // width: '25px',
                                  // marginRight: '4%',
                                  width: '1.75rem',
                                  height: '1.75rem',
                                  alignSelf: 'center',
                                  cursor: 'pointer',
                                }}
                                src='/images/edit_icon.png'
                                alt=''
                                onClick={() => {
                                  assignChauffeur()
                                }}
                              />
                            )}
                        </div>
                      )}

                    {/* Partner changes banner (mobile) */}
                    {listExists &&
                    list.status !== 'completed' &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    list.supplierInfo &&
                    list.supplierInfo.status === 'accepted' &&
                    tokenUser &&
                    tokenUser.isPartner &&
                    bookingChanges &&
                    thereAreNewChanges ? (
                      <>
                        <div className='partner-banner-mobile mt-4'>
                          <div>
                            <div
                              className='partnerContainer'
                              style={{ fontSize: '16px' }}
                            >
                              <div className='d-flex mb-3'>
                                <div
                                  className='imageContainer'
                                  style={{
                                    alignSelf: 'start',
                                  }}
                                >
                                  <img
                                    src='/images/icons/icon-warning.png'
                                    alt=''
                                  />
                                </div>
                                <p style={{ marginTop: '0.1rem' }}>
                                  Action required
                                </p>
                              </div>
                              <span>
                                Please review and confirm the booking change(s):
                              </span>
                              <span className='mt-1'>
                                {bookingChanges
                                  .filter(
                                    item =>
                                      item.status_admin === 'sent' &&
                                      (item.status_partner === 'opened' ||
                                        item.status_partner === 'not_opened')
                                  )
                                  .map(info => {
                                    let newText = info.message.replace(
                                      /<br>/g,
                                      ' '
                                    )
                                    return (
                                      <span className='w-100'>{`— ${newText}`}</span>
                                    )
                                  })}
                              </span>
                            </div>
                            <div
                              className='d-flex justify-content-center'
                              style={{ gridGap: '1em' }}
                            >
                              <Button
                                className='partner-btn-mobile-decline select-vehicle align-self-end mx-0'
                                style={{
                                  fontWeight: '900',
                                  textShadow: '0.25px 0 #a79779',
                                }}
                                onClick={() => {
                                  setShowDeclineChangesConfirmationModal(true)
                                }}
                              >
                                Decline
                              </Button>
                              <Button
                                className='select-chauffeur align-self-end mx-0'
                                style={{
                                  whiteSpace: 'pre',
                                  width: '100%',
                                  marginRight: '15px',
                                  fontWeight: '900',
                                  textShadow: '0.25px 0 #ffffff',
                                }}
                                variant='brown'
                                onClick={() => {
                                  onAcceptBookingChanges(
                                    bookingChanges,
                                    list._id,
                                    supplierId
                                  )
                                }}
                              >
                                {'Confirm change(s)'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Expired banner (mobile) */}
                    {list.supplierInfo &&
                      list.supplierInfo.status === 'expired' && (
                        <div className='partner-banner-mobile mt-4'>
                          <div className='d-flex'>
                            <div className='imageContainer'>
                              <img
                                src='/images/icons/icon-warning.png'
                                alt=''
                              />
                            </div>
                            <div className='partnerContainer'>
                              <span>This offer is no longer available.</span>
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedTab === 0 ? (
                      <>
                        {isSubmitting && <LoadingSpinner />}
                        <div className='driver-booking-card'>
                          <div
                            class='card-top-indicator'
                            style={
                              list.driver &&
                              !list.dispatch &&
                              tokenUser &&
                              !tokenUser.isPartner
                                ? list.status ===
                                    'cancellation_accepted_by_agent' ||
                                  list.status === 'cancelled_by_agent'
                                  ? { backgroundColor: '#d0021b' }
                                  : list.status === 'completed'
                                  ? { backgroundColor: 'black' }
                                  : {}
                                : list.supplierInfo && list.supplierInfo.status
                                ? list.status ===
                                    'cancellation_accepted_by_agent' ||
                                  list.status === 'cancelled_by_agent'
                                  ? { backgroundColor: '#d0021b' }
                                  : list.status === 'completed'
                                  ? { backgroundColor: 'black' }
                                  : list.supplierInfo.status === 'accepted'
                                  ? { backgroundColor: '#417505' }
                                  : list.isOperativeBranch &&
                                    list.dispatchStatus === 'accepted'
                                  ? { backgroundColor: '#417505' }
                                  : list.isOperativeBranch &&
                                    list.dispatchStatus === 'outsourced'
                                  ? { backgroundColor: '#1e2b8e' }
                                  : list.supplierInfo.status === 'dispatched' ||
                                    list.supplierInfo.status === 'opened'
                                  ? {
                                      backgroundColor: 'rgb(245, 166, 35)',
                                    }
                                  : list.supplierInfo.status === 'expired' ||
                                    list.supplierInfo.status === 'declined'
                                  ? { backgroundColor: '#d0021b' }
                                  : {}
                                : {}
                            }
                          ></div>
                          <div className='driver-booking-header'>
                            <div className='driver-title-partner'>
                              <span>#{list.number}</span>
                              <span style={{ fontWeight: '900' }}>
                                {getLabelFromValue(
                                  defaultBookingServicePartner,
                                  list.type
                                )}
                              </span>
                              {tokenUser &&
                              !tokenUser.isPartner &&
                              list.driver ? (
                                <Badge
                                  href='#'
                                  variant={
                                    list.status ===
                                      'cancellation_accepted_by_agent' ||
                                    list.status === 'cancelled_by_agent'
                                      ? `cancelled-dispatch badge-outlined mt-1 ml-3`
                                      : list.status === 'completed'
                                      ? 'completed-dispatch badge-outlined mt-1 ml-3'
                                      : !list.isDriverAndNotAccepted
                                      ? `accepted-dispatch badge-outlined mt-1 ml-3`
                                      : list.isOperativeBranch &&
                                        list.dispatchStatus === 'outsourced' &&
                                        list.outsourcedSuppliers
                                      ? 'outsourced-dispatch badge-outlined'
                                      : list.isOperativeBranch &&
                                        list.dispatchStatus === 'accepted'
                                      ? `accepted-dispatch badge-outlined mt-1 ml-3`
                                      : `new-booking badge-outlined mt-1 ml-3`
                                  }
                                  style={{
                                    display: 'flex',
                                    alignSelf: 'baseline',
                                  }}
                                >
                                  {list.status ===
                                    'cancellation_accepted_by_agent' ||
                                  list.status === 'cancelled_by_agent'
                                    ? 'CANCELLED'
                                    : list.status === 'completed'
                                    ? 'COMPLETED'
                                    : !list.isDriverAndNotAccepted
                                    ? 'ACCEPTED'
                                    : isLocalSupplier &&
                                      list.dispatchStatus === 'outsourced' &&
                                      list.outsourcedSuppliers
                                    ? 'OUTSOURCED'
                                    : list.isOperativeBranch &&
                                      list.dispatchStatus === 'accepted'
                                    ? `ACCEPTED`
                                    : 'PENDING'}
                                </Badge>
                              ) : (
                                [
                                  list.supplierInfo &&
                                    list.supplierInfo.status && (
                                      <Badge
                                        href='#'
                                        variant={
                                          list.status ===
                                            'cancellation_accepted_by_agent' ||
                                          list.status === 'cancelled_by_agent'
                                            ? `cancelled-dispatch badge-outlined mt-1 ml-3`
                                            : list.status === 'completed'
                                            ? 'completed-dispatch badge-outlined mt-1 ml-3'
                                            : list.supplierInfo.status ===
                                              'accepted'
                                            ? `accepted-dispatch badge-outlined mt-1 ml-3`
                                            : isLocalSupplier &&
                                              list.dispatchStatus ===
                                                'outsourced'
                                            ? 'outsourced-dispatch badge-outlined'
                                            : list.isOperativeBranch &&
                                              list.dispatchStatus === 'accepted'
                                            ? `accepted-dispatch badge-outlined mt-1 ml-3`
                                            : list.supplierInfo.status ===
                                                'dispatched' ||
                                              list.supplierInfo.status ===
                                                'opened'
                                            ? `new-booking badge-outlined mt-1 ml-3`
                                            : list.supplierInfo.status ===
                                                'expired' ||
                                              list.supplierInfo.status ===
                                                'declined'
                                            ? `expired-dispatch badge-outlined mt-1 ml-3`
                                            : ``
                                        }
                                        style={{
                                          display: 'flex',
                                          alignSelf: 'baseline',
                                        }}
                                      >
                                        {list.status ===
                                          'cancellation_accepted_by_agent' ||
                                        list.status === 'cancelled_by_agent'
                                          ? 'CANCELLED'
                                          : list.status === 'completed'
                                          ? 'COMPLETED'
                                          : list.supplierInfo.status ===
                                            'accepted'
                                          ? 'ACCEPTED'
                                          : isLocalSupplier &&
                                            list.dispatchStatus ===
                                              'outsourced' &&
                                            list.outsourcedSuppliers
                                          ? 'OUTSOURCED'
                                          : list.isOperativeBranch &&
                                            list.dispatchStatus === 'accepted'
                                          ? 'ACCEPTED'
                                          : list.supplierInfo.status ===
                                              'dispatched' ||
                                            list.supplierInfo.status ===
                                              'opened'
                                          ? 'PENDING'
                                          : list.supplierInfo.status ===
                                            'expired'
                                          ? `EXPIRED`
                                          : list.supplierInfo.status ===
                                            'declined'
                                          ? 'DECLINED'
                                          : ``}
                                      </Badge>
                                    ),
                                ]
                              )}
                            </div>
                          </div>
                          <div
                            className='driver-booking-body'
                            style={
                              list.driver &&
                              ((tokenUser &&
                                tokenUser.isDriver &&
                                !list.dispatch) ||
                                (tokenUser &&
                                  tokenUser.isDriver &&
                                  !tokenUser.isPartner &&
                                  list.dispatch))
                                ? {
                                    padding: '1em 1em 2em 1em',
                                    border:
                                      '1.5px solid rgba(139, 149, 158, 0.2)',
                                    borderBottomRightRadius: '10px',
                                    borderBottomLeftRadius: '10px',
                                  }
                                : { padding: '1em 1em 2em 1em' }
                            }
                          >
                            <div className='driver-content'>
                              {list.type !== 'car_rentals' ? (
                                <>
                                  {((list.supplierInfo &&
                                    list.supplierInfo.status === 'accepted') ||
                                    list.driver) && (
                                    <div className='passenger-name'>
                                      {list.passengerInfo &&
                                        list.passengerInfo.title}{' '}
                                      {list.passengerInfo &&
                                        list.passengerInfo.lastName}
                                      <span>
                                        {list.passengerInfo &&
                                          list.passengerInfo.isVip && (
                                            <img
                                              style={{
                                                marginLeft: '10px',
                                                width: '24px',
                                                height: '20px',
                                              }}
                                              src='/images/icons/icon-vip.png'
                                              alt=''
                                            />
                                          )}
                                      </span>
                                    </div>
                                  )}
                                  <div className='passenger-details'>
                                    <img src='/images/icons/team.svg' />
                                    <span>
                                      {list.passengerInfo &&
                                        (list.passengerInfo.passenger === 0
                                          ? 0
                                          : list.passengerInfo.passenger)}
                                    </span>
                                    <img
                                      src='/images/icons/portfolio.svg'
                                      style={{
                                        marginLeft: '10px',
                                        width: '6.5% important',
                                      }}
                                      alt=''
                                    />
                                    <span>
                                      {list.passengerInfo &&
                                        (list.passengerInfo.luggage === 0
                                          ? 0
                                          : list.passengerInfo.luggage)}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {((list.supplierInfo &&
                                    list.supplierInfo.status === 'accepted') ||
                                    list.driver) && (
                                    <div className='passenger-name'>
                                      {list.driversInfo &&
                                        list.driversInfo[0] &&
                                        list.driversInfo[0].title}{' '}
                                      {list.driversInfo &&
                                        list.driversInfo[0] &&
                                        list.driversInfo[0].lastName}
                                      <span>
                                        {list.driversInfo[0] &&
                                          list.driversInfo[0].isVip && (
                                            <img
                                              style={{
                                                marginLeft: '10px',
                                                width: '24px',
                                                height: '20px',
                                              }}
                                              src='/images/icons/icon-vip.png'
                                              alt=''
                                            />
                                          )}
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                              {(list.durationType !== 'daily' ||
                                !list.days) && (
                                <div className='date'>
                                  <img src='/images/icon_calendar.png' alt='' />
                                  <span>
                                    {`
              ${moment.utc(list.pickUpDate).format(dateFormatDetailed)},
              ${moment.utc(list.pickUpDate).format('HH:mm')}
            `}
                                  </span>
                                </div>
                              )}
                              <div
                                className={`${
                                  list.durationType !== 'daily' || !list.days
                                    ? 'vehicle'
                                    : 'vehicle mt-4'
                                }`}
                              >
                                {' '}
                                <img
                                  style={{ width: '21px !important' }}
                                  src='/images/icon_car.png'
                                  alt=''
                                />
                                <span>
                                  {list.globalVehicle &&
                                    list.globalVehicle.brandName}{' '}
                                  {list.globalVehicle &&
                                    list.globalVehicle.modelName}
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      color: 'black',
                                      marginLeft: '0px',
                                    }}
                                  >
                                    <img
                                      className='mr-1 ml-2'
                                      style={{ width: '15px' }}
                                      src={`/images/icons/team.svg`}
                                      alt=''
                                    />
                                    {passengerCapacity &&
                                    typeof passengerCapacity === 'number'
                                      ? passengerCapacity
                                      : 0}
                                    <img
                                      className='mr-1 ml-2'
                                      style={{ width: '15px' }}
                                      src='/images/icons/portfolio.svg'
                                      alt=''
                                    />
                                    {luggageCapacity &&
                                    typeof luggageCapacity === 'number'
                                      ? luggageCapacity
                                      : 0}
                                  </span>
                                </span>
                              </div>
                              {list.durationType !== 'daily' || !list.days ? (
                                <>
                                  <div className='partner-location-container mt-4'>
                                    <div className='partner-indicator'>
                                      <span class='circle-filled-pu'></span>
                                      {renderDottedBar(
                                        getAddressElementLength(0, 0),
                                        !list.dropOffLocation
                                      )}
                                      {list.stops && list.stops.length ? (
                                        <>
                                          {list.stops.map((stop, index) => {
                                            return (
                                              <>
                                                <span class='circle-empty'></span>
                                                {renderDottedBar(
                                                  getAddressElementLength(
                                                    0,
                                                    index + 1
                                                  )
                                                )}
                                              </>
                                            )
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {list.dropOffLocation && (
                                        <span class='circle-filled'></span>
                                      )}
                                    </div>
                                    <div className='partner-fields'>
                                      <p style={{ marginTop: '-8px' }}>
                                        {list.pickUpLocation &&
                                          list.pickUpLocation.fullAddress}
                                      </p>
                                      {list.stops && list.stops.length ? (
                                        <>
                                          {list.stops.map((stop, index) => {
                                            return (
                                              <p
                                                style={{
                                                  whiteSpace: 'break-spaces',
                                                }}
                                              >
                                                {`${stop.location &&
                                                  stop.location
                                                    .fullAddress}  (${stop.duration &&
                                                  stop.duration.label})`}
                                              </p>
                                            )
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {list.dropOffLocation && (
                                        <p>
                                          {list.dropOffLocation.fullAddress}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className='mb-2'>
                                  {list.days.map((day, index) => (
                                    <div className='mt-2'>
                                      <div className='date-daily'>
                                        <img
                                          src='/images/icon_calendar.png'
                                          alt=''
                                        />
                                        <span>
                                          {`
              ${moment.utc(day.pickUpDate).format('Do MMM YYYY')},
              ${moment.utc(day.pickUpDate).format('HH:mm')} (${day.hours.label})
            `}
                                        </span>
                                        <img
                                          src={
                                            dayCollapsibleArray[index]
                                              ? '/images/icons/chevron-up.svg'
                                              : '/images/icons/chevron-down.svg'
                                          }
                                          alt=''
                                          onClick={() => {
                                            const arr = [...dayCollapsibleArray]
                                            arr[index] = !arr[index]
                                            setDayCollapsibleArray(arr)
                                          }}
                                          style={{
                                            marginLeft: 'auto',
                                            width: '18px',
                                          }}
                                        />
                                      </div>
                                      <div
                                        style={
                                          dayCollapsibleArray[index]
                                            ? {
                                                display: 'block',
                                              }
                                            : {
                                                display: 'none',
                                              }
                                        }
                                      >
                                        {' '}
                                        <div className='partner-location-container mt-4'>
                                          <div className='partner-indicator'>
                                            <span class='circle-filled-pu'></span>
                                            {renderDottedBar(
                                              getAddressElementLength(index, 0),
                                              !day.dropOffLocation
                                            )}
                                            {day.stops && day.stops.length ? (
                                              <>
                                                {day.stops.map((stop, idx) => {
                                                  return (
                                                    <>
                                                      <span class='circle-empty'></span>
                                                      {renderDottedBar(
                                                        getAddressElementLength(
                                                          index,
                                                          idx + 1
                                                        )
                                                      )}
                                                    </>
                                                  )
                                                })}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {day.dropOffLocation && (
                                              <span class='circle-filled'></span>
                                            )}
                                          </div>
                                          <div className='partner-fields'>
                                            <p style={{ marginTop: '-8px' }}>
                                              {day.pickUpLocation &&
                                                day.pickUpLocation.fullAddress}
                                            </p>
                                            {day.stops && day.stops.length ? (
                                              <>
                                                {day.stops.map((stop, idx) => {
                                                  return (
                                                    <p
                                                      style={{
                                                        whiteSpace:
                                                          'break-spaces',
                                                      }}
                                                    >
                                                      {`${stop.location &&
                                                        stop.location
                                                          .fullAddress}  (${stop.duration &&
                                                        stop.duration.label})`}
                                                    </p>
                                                  )
                                                })}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {day.dropOffLocation && (
                                              <p>
                                                {
                                                  day.dropOffLocation
                                                    .fullAddress
                                                }
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {list.type === 'chauffeur_services' ? (
                                <div className='vehicle mt-3'>
                                  <img
                                    src='/images/icon-time.png'
                                    className='clock-img'
                                  />

                                  <span className='partner-duration'>
                                    {list.durationType === 'daily' &&
                                    list.days &&
                                    list.days.length
                                      ? `${list.days.length} days`
                                      : `${list.duration} hour${
                                          list.duration > 1 ? 's' : ''
                                        }`}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {formattedTripInfo &&
                                    formattedTripInfo.duration && (
                                      <div className='vehicle'>
                                        <img
                                          src='/images/icon-time.png'
                                          className='clock-img'
                                        />
                                        <span className='partner-duration'>
                                          {formattedTripInfo &&
                                            formattedTripInfo.duration}
                                        </span>
                                      </div>
                                    )}
                                </>
                              )}
                              {list.airportPickUpInfo &&
                                list.airportPickUpInfo.flightNumber && (
                                  <>
                                    <div className='flight'>
                                      <img src='/images/icons/Icon_Airport_Partner.png' />
                                      <span>
                                        {list.airportPickUpInfo.flightNumber.toUpperCase()}
                                      </span>
                                      {bookingInOneDay && flightIcao ? (
                                        <span
                                          className='pdf-board'
                                          onClick={() => {
                                            getTrackFlight(flightIcao)
                                          }}
                                        >
                                          Track flight
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                )}
                              {list.airportPickUpInfo &&
                                !!list.airportPickUpInfo.tailNumber && (
                                  <>
                                    <div className='flight'>
                                      <img src='/images/icons/Icon_Airport_Partner.png' />
                                      <span>
                                        {list.airportPickUpInfo.tailNumber.toUpperCase()}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {list.airportPickUpInfo &&
                                !!list.airportPickUpInfo.fbo && (
                                  <>
                                    <div className='pickup-block'>
                                      <img src='/images/icon_pu.png' />
                                      <span className='partner-arrival'>
                                        {list.airportPickUpInfo.fbo}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {((list.supplierInfo &&
                                list.supplierInfo.status === 'accepted') ||
                                list.driver) &&
                                ((list.airportPickUpInfo &&
                                  list.airportPickUpInfo.flightType ===
                                    'commercial') ||
                                  list.trainPickUpInfo) && (
                                  <div className='nameboard'>
                                    <img src='/images/icon-nameboard.png' />
                                    <span
                                      onClick={() => {
                                        getNameboard()
                                      }}
                                    >
                                      Get PDF name-board
                                    </span>
                                  </div>
                                )}
                              {list.trainPickUpInfo &&
                                list.trainPickUpInfo.trainNumber && (
                                  <>
                                    <div className='train'>
                                      <img src='/images/icon-train.png' />
                                      <span>
                                        {list.trainPickUpInfo.trainNumber.toUpperCase()}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {/* {list.shipPickUpInfo &&
                                list.shipPickUpInfo.shipType && (
                                  <>
                                    <div className='train'>
                                      <img src='/images/mapIcons/Icon_Port.png' />{' '}
                                      {list.shipPickUpInfo.shipType ===
                                        'cruise' && (
                                        <span>
                                          {list.shipPickUpInfo.vesselName}
                                        </span>
                                      )}
                                      {list.shipPickUpInfo.shipType ===
                                        'yacht' && (
                                        <span>
                                          {list.shipPickUpInfo.yachtName}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                )} */}
                              {(list.airportPickUpInfo ||
                                list.trainPickUpInfo) && (
                                <div className='pickup-block'>
                                  <img src='/images/icon_pu.png' />
                                  <span className='partner-arrival'>
                                    {displayPickUpInstructions(
                                      isCarRental,
                                      list.airportPickUpInfo &&
                                        list.airportPickUpInfo.flightType ===
                                          'commercial'
                                        ? true
                                        : false,
                                      list.airportPickUpInfo &&
                                        list.airportPickUpInfo.flightType ===
                                          'private'
                                        ? true
                                        : false,
                                      list.meetAndAssist &&
                                        list.meetAndAssist.arrival
                                        ? true
                                        : false,
                                      list.trainPickUpInfo ? true : false
                                    )}
                                  </span>
                                </div>
                              )}
                              {list.airportDropOffInfo &&
                                list.airportDropOffInfo.flightNumber && (
                                  <>
                                    <div className='flight'>
                                      <img src='/images/icons/Icon_Airport_Partner.png' />
                                      <span>
                                        {list.airportDropOffInfo.flightNumber.toUpperCase()}
                                      </span>
                                      {bookingInOneDay && depFlightIcao ? (
                                        <span
                                          className='pdf-board'
                                          onClick={() => {
                                            getTrackFlight(depFlightIcao)
                                          }}
                                        >
                                          Track flight
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                )}
                              {list.airportDropOffInfo &&
                                !!list.airportDropOffInfo.tailNumber && (
                                  <>
                                    <div className='flight'>
                                      <img src='/images/icons/Icon_Airport_Partner.png' />
                                      <span>
                                        {list.airportDropOffInfo.tailNumber.toUpperCase()}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {list.airportDropOffInfo &&
                                !!list.airportDropOffInfo.fbo && (
                                  <>
                                    <div className='pickup-block'>
                                      <img src='/images/icon_pu.png' alt='' />
                                      <span className='partner-arrival'>
                                        {list.airportDropOffInfo.fbo}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {list.trainDropOffInfo &&
                                list.trainDropOffInfo.trainNumber && (
                                  <>
                                    <div className='train'>
                                      <img
                                        src='/images/icon-train.png'
                                        alt=''
                                      />
                                      <span>
                                        {list.trainDropOffInfo.trainNumber.toUpperCase()}
                                      </span>
                                    </div>
                                  </>
                                )}
                              {/* {list.shipDropOffInfo &&
                                list.shipDropOffInfo.shipType && (
                                  <>
                                    <div className='train'>
                                      <img src='/images/mapIcons/Icon_Port.png' />
                                      {list.shipDropOffInfo.shipType ===
                                        'cruise' && (
                                        <span>
                                          {list.shipDropOffInfo.vesselName}
                                        </span>
                                      )}
                                      {list.shipDropOffInfo.shipType ===
                                        'yacht' && (
                                        <span>
                                          {list.shipDropOffInfo.yachtName}
                                        </span>
                                      )}
                                    </div>
                                  </>
                                )} */}
                            </div>
                          </div>
                          {list.supplierInfo ? (
                            <>
                              {list.supplierInfo.buyingPrice &&
                              isMobileBrowser ? (
                                <>
                                  {/* <AdditionalCharges
                                    serviceType={getLabelFromValue(
                                      defaultBookingServicePartner,
                                      list.type
                                    )}
                                    charges={list.partnerCharges}
                                    supplierInfo={list.supplierInfo}
                                    isLocalSupplier={list.isOperativeBranch}
                                    outsourceRate={
                                      list.supplierInfo.outsourceRate
                                    }
                                    marginRate={list.supplierInfo.marginRate}
                                    dispatchWeight={
                                      list.supplierInfo.dispatchWeight
                                    }
                                    desktop={'mobile'}
                                  /> */}
                                  <div
                                    className='driver-service-card mt-0'
                                    style={{
                                      borderTopLeftRadius: '0px',
                                      borderTopRightRadius: '0px',
                                    }}
                                  >
                                    <PartnerPricingDetails
                                      serviceType={getLabelFromValue(
                                        defaultBookingServicePartner,
                                        list.type
                                      )}
                                      supplierInfo={list.supplierInfo}
                                      dispatch={list.dispatch}
                                      charges={list.partnerCharges}
                                      bookingId={bookingId}
                                      bookingPartner={list.dispatch}
                                      reloadList={reloadList}
                                      isLocalSupplier={list.isOperativeBranch}
                                      dispatchStatus={list.dispatchStatus}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className='driver-booking-price'>
                                  {numberWithCommas(
                                    getFormattedPrice(
                                      list.supplierInfo.buyingPrice
                                    )
                                  )}{' '}
                                  {getLabelFromValue(
                                    defaultCurrencies,
                                    list.supplierInfo.supplierCurrency
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        {list.serviceNotes && list.serviceNotes !== '' ? (
                          <div className='driver-service-card'>
                            <div className='driver-service-card-header'>
                              <span>Notes</span>
                            </div>
                            <p className='mt-3'>{list.serviceNotes}</p>
                          </div>
                        ) : list.services_location_notes &&
                          list.services_location_notes !== '' ? (
                          <div className='driver-service-card'>
                            <div className='driver-service-card-header'>
                              <span>Notes</span>
                            </div>
                            <p className='mt-3'>
                              {list.services_location_notes}
                            </p>
                          </div>
                        ) : (
                          <></>
                        )}

                        {list.addInfo !== '' && list.isOperativeBranch ? (
                          <>
                            <div className='driver-service-card'>
                              <div className='driver-service-card-header'>
                                <span>Client notes</span>
                              </div>
                              <p className='mt-3'>{list.addInfo}</p>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {list.seatInfo &&
                          (list.seatInfo.babySeat ||
                            list.seatInfo.childSeat ||
                            list.seatInfo.boosterSeat) && (
                            <div className='driver-service-card'>
                              <div
                                className='driver-service-card-header'
                                style={{
                                  fontSize: '16px',
                                  marginBottom: '20px',
                                }}
                              >
                                Child seat
                              </div>
                              {list.seatInfo.babySeat ? (
                                <>
                                  <div className='driver-service-card-item-seat'>
                                    <img src='/images/icons/baby_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc-mob'>
                                      <span>
                                        Baby seat x {list.seatInfo.babySeat}
                                      </span>
                                      <span>Age: Up to 1 year old</span>
                                      <span>Weight: Under 9 kg/20 lbs</span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {list.seatInfo.childSeat ? (
                                <>
                                  <div className='driver-service-card-item-seat'>
                                    <img src='/images/icons/child_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc-mob'>
                                      <span>
                                        Child seat x {list.seatInfo.childSeat}
                                      </span>
                                      <span>Age: 1-3 years old</span>
                                      <span>
                                        Weight: Under 9-18 kg/20-40 lbs
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {list.seatInfo.boosterSeat ? (
                                <>
                                  <div className='driver-service-card-item-seat'>
                                    <img src='/images/icons/booster_seat_icon.png' />
                                    <div className='driver-service-card-item-with-desc-mob'>
                                      <span>
                                        Booster seat x{' '}
                                        {list.seatInfo.boosterSeat}
                                      </span>
                                      <span>Age: 3-11 years old</span>
                                      <span>
                                        Weight: Under 18-45 kg/ 40-100 lbs
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}

                        <div className='driver-service-card'>
                          <div className='driver-service-card-header'>
                            <Badge href='#' variant='important badge-outlined'>
                              IMPORTANT INFORMATION
                            </Badge>
                          </div>
                          <div className='important-info'>
                            {isCarRental ? (
                              <>
                                <div>
                                  Do not show price on the rental agreement
                                </div>
                                <div> ROLZO will pay for the rental fees</div>
                                <div>
                                  The driver will pay for the security deposit
                                  at delivery
                                </div>
                                <div>
                                  The agent delivering and collecting the
                                  vehicle must speak English fluently
                                </div>
                              </>
                            ) : (
                              <>
                                <div> ROLZO will pay for all services</div>
                                <div>
                                  The chauffeur must speak English fluently
                                </div>
                                <div>
                                  The vehicle must include water bottles, wipes,
                                  and mints
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <Modal
                          show={showPdf}
                          onHide={() => setShowPdf(false)}
                          dialogClassName='modal-90w modal-padding'
                          size='lg'
                          aria-labelledby='contained-modal-title-vcenter'
                          centered
                        >
                          <Modal.Body>
                            <embed
                              src={pdfLink}
                              type='application/pdf'
                              height='100%'
                              width='100%'
                            ></embed>
                          </Modal.Body>
                        </Modal>
                      </>
                    ) : selectedTab === 1 ? (
                      <>
                        {list.driver &&
                          (!list.supplierInfo ||
                            (list.supplierInfo &&
                              list.supplierInfo.status !== 'expired')) &&
                          list.status !== 'cancellation_accepted_by_agent' &&
                          list.status !== 'cancelled_by_agent' &&
                          !list.isDriverAndNotAccepted &&
                          !updateDayCompleted && (
                            <div className='driver-info-layout-mobile'>
                              {isSubmitting && <LoadingSpinner />}
                              <div className='driver-info-column'>
                                {((isCarRental &&
                                  (!updateOnTheWay ||
                                    !updateOnLocation ||
                                    !updateVehicleDelivered)) ||
                                  (!isCarRental &&
                                    (!updateOnTheWay ||
                                      !updateOnLocation ||
                                      !updatePassengerOnboard ||
                                      !updatePassengerDroppedOff))) &&
                                  bookingInTwoHours &&
                                  tokenUser &&
                                  tokenUser.isDriver &&
                                  renderStatusUpdates() && (
                                    <div className='partner-banner mt-4'>
                                      <div className='imageContainer'>
                                        <img
                                          src='/images/icons/icon-warning.png'
                                          alt=''
                                        />
                                      </div>
                                      <div className='partnerContainer'>
                                        <span>{renderStatusUpdates()}</span>
                                      </div>
                                    </div>
                                  )}
                                {list.durationType === 'daily' &&
                                  list.days &&
                                  updateOnTheWay &&
                                  updateOnLocation &&
                                  updatePassengerOnboard &&
                                  updatePassengerDroppedOff && (
                                    <div className='partner-banner mt-4'>
                                      <div className='imageContainer'>
                                        <img
                                          src='/images/icons/icon-warning.png'
                                          alt=''
                                        />
                                      </div>
                                      <div className='partnerContainer'>
                                        <span>
                                          Please mark the day as completed.
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                <div
                                  className='driver-service-card'
                                  style={{
                                    border: 'none',
                                    background: 'none',
                                    padding: '0.5em 0.5em 5.5em 0.5em',
                                  }}
                                >
                                  {list.durationType === 'daily' && list.days && (
                                    <p>
                                      Day {currentDay} (
                                      {moment
                                        .utc(
                                          list.days[currentDay - 1].pickUpDate
                                        )
                                        .format(dateFormatDetailed)}
                                      )
                                    </p>
                                  )}
                                  <div className='driver-service-card-body-container'>
                                    <div class='step'>
                                      <div class='v-stepper'>
                                        <div
                                          class={
                                            updateOnTheWay
                                              ? 'update-circle-filled'
                                              : 'update-circle'
                                          }
                                        />
                                        <div
                                          className={
                                            updateOnTheWay
                                              ? 'vertical-filled'
                                              : 'vertical-dashed'
                                          }
                                        ></div>
                                      </div>
                                      <div className='driver-service-card-container'>
                                        <div
                                          className={
                                            updateOnTheWay
                                              ? 'driver-service-card-item-updates updates-complete indicator-black'
                                              : bookingInTwoHours
                                              ? 'driver-service-card-item-updates indicator-red'
                                              : 'driver-service-card-item-updates'
                                          }
                                        >
                                          <span>1</span>
                                          <span
                                            style={
                                              bookingInTwoHours &&
                                              !updateOnTheWay
                                                ? { color: '#d0021b' }
                                                : {}
                                            }
                                          >
                                            {listExists &&
                                            !list.chauffeurOfBooking &&
                                            list.partnerOfBooking ? (
                                              <>
                                                {bookingInTwoHours &&
                                                !updateOnTheWay
                                                  ? `Is ${
                                                      list.driver.fullName.split(
                                                        ' '
                                                      )[0]
                                                    } is on the way?`
                                                  : `${
                                                      list.driver.fullName.split(
                                                        ' '
                                                      )[0]
                                                    } is on the way`}
                                              </>
                                            ) : (
                                              <>
                                                {bookingInTwoHours &&
                                                !updateOnTheWay
                                                  ? `Are you on the way?`
                                                  : `You are on the way`}
                                              </>
                                            )}
                                          </span>
                                          <span
                                            style={
                                              bookingInTwoHours &&
                                              !updateOnTheWay
                                                ? { color: '#d0021b' }
                                                : {}
                                            }
                                          >
                                            {!checkBookingAfterADay(
                                              list.pickUpDate
                                            ) &&
                                              !updateOnTheWay &&
                                              bookingTimeLeft}
                                          </span>
                                          {updateOnTheWay && (
                                            <img
                                              alt=''
                                              style={{
                                                marginLeft: '10px',
                                                alignSelf: 'center',
                                              }}
                                              width={20}
                                              src='/images/icons/icon-checklist-black.png'
                                            ></img>
                                          )}
                                        </div>
                                        {updateOnTheWay &&
                                          updateOnTheWay.time && (
                                            <span className='status-update-timestamp'>
                                              {moment
                                                .utc(updateOnTheWay.time)
                                                .format(newTimeFormat)}
                                            </span>
                                          )}
                                      </div>
                                    </div>

                                    <div class='step'>
                                      <div class='v-stepper'>
                                        <div
                                          class={
                                            updateOnLocation
                                              ? 'update-circle-filled'
                                              : 'update-circle'
                                          }
                                        />
                                        <div
                                          className={
                                            updateOnLocation
                                              ? 'vertical-filled'
                                              : 'vertical-dashed'
                                          }
                                        ></div>
                                      </div>
                                      <div className='driver-service-card-container'>
                                        <div
                                          className={`driver-service-card-item-updates 
                                  ${updateOnLocation &&
                                    'updates-complete indicator-black'}
                                `}
                                        >
                                          <span> 2 </span>
                                          <span
                                            style={
                                              updateOnTheWay &&
                                              !updateOnLocation
                                                ? { color: '#d0021b' }
                                                : {}
                                            }
                                          >
                                            {listExists &&
                                            !list.chauffeurOfBooking &&
                                            list.partnerOfBooking ? (
                                              <>
                                                {' '}
                                                {updateOnTheWay &&
                                                !updateOnLocation
                                                  ? `Has ${
                                                      list.driver.fullName.split(
                                                        ' '
                                                      )[0]
                                                    } arrived?`
                                                  : `${
                                                      list.driver.fullName.split(
                                                        ' '
                                                      )[0]
                                                    } has arrived`}
                                              </>
                                            ) : (
                                              <>
                                                {updateOnTheWay &&
                                                !updateOnLocation
                                                  ? `Have you arrived?`
                                                  : `You have arrived`}
                                              </>
                                            )}
                                          </span>
                                          <span
                                            style={
                                              bookingInTwoHours &&
                                              !updateOnLocation
                                                ? { color: '#d0021b' }
                                                : {}
                                            }
                                          >
                                            {!checkBookingAfterADay(
                                              list.pickUpDate
                                            ) &&
                                              !updateOnLocation &&
                                              updateOnTheWay &&
                                              bookingTimeLeft}{' '}
                                          </span>
                                          {updateOnLocation && (
                                            <img
                                              style={{
                                                marginLeft: '10px',
                                                alignSelf: 'center',
                                              }}
                                              width={20}
                                              src='/images/icons/icon-checklist-black.png'
                                              alt=''
                                            ></img>
                                          )}
                                        </div>
                                        {updateOnLocation &&
                                          updateOnLocation.time && (
                                            <span className='status-update-timestamp'>
                                              {moment
                                                .utc(updateOnLocation.time)
                                                .format(newTimeFormat)}
                                            </span>
                                          )}
                                      </div>
                                    </div>

                                    {!isCarRental ? (
                                      <>
                                        <div class='step'>
                                          <div class='v-stepper'>
                                            <div
                                              class={
                                                updatePassengerOnboard
                                                  ? 'update-circle-filled'
                                                  : 'update-circle'
                                              }
                                            ></div>
                                            <div
                                              className={
                                                updatePassengerOnboard
                                                  ? 'vertical-filled'
                                                  : 'vertical-dashed'
                                              }
                                            ></div>
                                          </div>
                                          <div className='driver-service-card-container'>
                                            <div
                                              className={`driver-service-card-item-updates 
                                ${updatePassengerOnboard &&
                                  'updates-complete indicator-black'}
                              `}
                                            >
                                              <span> 3 </span>
                                              <span
                                                style={
                                                  updateOnTheWay &&
                                                  updateOnLocation &&
                                                  !updatePassengerOnboard
                                                    ? { color: '#d0021b' }
                                                    : {}
                                                }
                                              >
                                                {updateOnTheWay &&
                                                updateOnLocation &&
                                                !updatePassengerOnboard
                                                  ? 'Is the passenger on board?'
                                                  : 'The passenger is on board'}
                                              </span>
                                              {updatePassengerOnboard && (
                                                <img
                                                  style={{
                                                    marginLeft: '17px',
                                                    alignSelf: 'center',
                                                  }}
                                                  width={20}
                                                  src='/images/icons/icon-checklist-black.png'
                                                  alt=''
                                                ></img>
                                              )}
                                            </div>
                                            {updatePassengerOnboard &&
                                              updatePassengerOnboard.time && (
                                                <span className='status-update-timestamp'>
                                                  {moment
                                                    .utc(
                                                      updatePassengerOnboard.time
                                                    )
                                                    .format(newTimeFormat)}
                                                </span>
                                              )}
                                          </div>
                                        </div>

                                        {showTenMinsAwayUpdate ? (
                                          <div class='step'>
                                            <div class='v-stepper'>
                                              <div
                                                class={
                                                  updateTenMinsAway
                                                    ? 'update-circle-filled'
                                                    : 'update-circle'
                                                }
                                              ></div>
                                              <div
                                                className={
                                                  updateTenMinsAway
                                                    ? 'vertical-filled'
                                                    : 'vertical-dashed'
                                                }
                                              ></div>
                                            </div>
                                            <div className='driver-service-card-container'>
                                              <div
                                                className={`driver-service-card-item-updates 
                                ${updateTenMinsAway &&
                                  'updates-complete indicator-black'}
                              `}
                                              >
                                                <span> 4 </span>
                                                <span
                                                  style={
                                                    updateOnTheWay &&
                                                    updateOnLocation &&
                                                    updatePassengerOnboard &&
                                                    !updateTenMinsAway
                                                      ? { color: '#d0021b' }
                                                      : {}
                                                  }
                                                >
                                                  {updateOnTheWay &&
                                                  updateOnLocation &&
                                                  updatePassengerOnboard &&
                                                  !updateTenMinsAway
                                                    ? '10 mins away from destination?'
                                                    : '10 mins away from destination'}
                                                </span>
                                                {updateTenMinsAway && (
                                                  <img
                                                    style={{
                                                      marginLeft: '10px',
                                                      alignSelf: 'center',
                                                    }}
                                                    width={20}
                                                    src='/images/icons/icon-checklist-black.png'
                                                    alt=''
                                                  ></img>
                                                )}
                                              </div>
                                              {updateTenMinsAway &&
                                                updateTenMinsAway.time && (
                                                  <span className='status-update-timestamp'>
                                                    {moment
                                                      .utc(
                                                        updateTenMinsAway.time
                                                      )
                                                      .format(newTimeFormat)}
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}

                                        <div class='step'>
                                          <div class='v-stepper'>
                                            <div
                                              class={
                                                updatePassengerDroppedOff
                                                  ? 'update-circle-filled'
                                                  : 'update-circle'
                                              }
                                            ></div>
                                          </div>
                                          <div className='driver-service-card-container'>
                                            <div
                                              className={`driver-service-card-item-updates 
                                ${updatePassengerDroppedOff &&
                                  'updates-complete indicator-black'}
                              `}
                                            >
                                              {showTenMinsAwayUpdate ? 5 : 4}{' '}
                                              <span
                                                style={
                                                  updateOnTheWay &&
                                                  updateOnLocation &&
                                                  updatePassengerOnboard &&
                                                  ((updateTenMinsAway &&
                                                    showTenMinsAwayUpdate) ||
                                                    (!updateTenMinsAway &&
                                                      !showTenMinsAwayUpdate)) &&
                                                  !updatePassengerDroppedOff
                                                    ? { color: '#d0021b' }
                                                    : {}
                                                }
                                              >
                                                {updateOnTheWay &&
                                                updateOnLocation &&
                                                updatePassengerOnboard &&
                                                ((updateTenMinsAway &&
                                                  showTenMinsAwayUpdate) ||
                                                  (!updateTenMinsAway &&
                                                    !showTenMinsAwayUpdate)) &&
                                                !updatePassengerDroppedOff
                                                  ? list.dropOffLocation
                                                    ? `Was the passenger dropped off?`
                                                    : `Was the passenger dropped off?`
                                                  : list.dropOffLocation
                                                  ? `The passenger was dropped off`
                                                  : `The passenger was dropped off`}
                                              </span>
                                              {updatePassengerDroppedOff && (
                                                <img
                                                  style={{
                                                    marginLeft: '10px',
                                                    alignSelf: 'center',
                                                  }}
                                                  width={20}
                                                  src='/images/icons/icon-checklist-black.png'
                                                  alt=''
                                                ></img>
                                              )}
                                            </div>
                                            {updatePassengerDroppedOff &&
                                              updatePassengerDroppedOff.time && (
                                                <span className='status-update-timestamp'>
                                                  {moment
                                                    .utc(
                                                      updatePassengerDroppedOff.time
                                                    )
                                                    .format(newTimeFormat)}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div class='step'>
                                          <div class='v-stepper'>
                                            <div
                                              class={
                                                updateVehicleDelivered
                                                  ? 'update-circle-filled'
                                                  : 'update-circle'
                                              }
                                            ></div>
                                            <div
                                              className={
                                                updateVehicleDelivered
                                                  ? 'vertical-filled'
                                                  : 'vertical-dashed'
                                              }
                                            ></div>
                                          </div>
                                          <div className='driver-service-card-container'>
                                            <div
                                              className={`driver-service-card-item-updates 
                                ${updateVehicleDelivered &&
                                  'updates-complete indicator-black'}
                              `}
                                            >
                                              <span> 3 </span>
                                              <span
                                                style={
                                                  updateOnTheWay &&
                                                  updateOnLocation &&
                                                  !updateVehicleDelivered
                                                    ? { color: '#d0021b' }
                                                    : {}
                                                }
                                              >
                                                {updateOnTheWay &&
                                                updateOnLocation &&
                                                !updateVehicleDelivered
                                                  ? 'Was the vehicle delivered?'
                                                  : 'The vehicle was delivered'}
                                              </span>
                                              {updateVehicleDelivered && (
                                                <img
                                                  style={{
                                                    marginLeft: '10px',
                                                    alignSelf: 'center',
                                                  }}
                                                  width={20}
                                                  src='/images/icons/icon-checklist-black.png'
                                                  alt=''
                                                ></img>
                                              )}
                                            </div>
                                            {updateVehicleDelivered &&
                                              updateVehicleDelivered.time && (
                                                <span className='status-update-timestamp'>
                                                  {moment
                                                    .utc(
                                                      updateVehicleDelivered.time
                                                    )
                                                    .format(newTimeFormat)}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    style={{ marginBottom: '0em' }}
                                    className='driver-service-card-body-container'
                                  >
                                    <div className='driver-service-card-container'>
                                      <div
                                        style={{ display: 'block' }}
                                        className={
                                          'driver-service-card-item-updates'
                                        }
                                      >
                                        <label>Link</label>
                                        <a
                                          style={{ wordWrap: 'break-word' }}
                                          href={getHostLink(bookingId)}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='text-brown pointer-link'
                                        >
                                          {getHostLink(bookingId)}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ConfirmationModal
                                onHide={() => {
                                  setShowConfirmationModal(false)
                                  setSubmitting(false)
                                }}
                                handleFunction={onConfirmationModal}
                                show={showConfirmationModal}
                                confirmationText={displayAlertText}
                                confirmationTitle={displayAlertTitle}
                                changeConfirmationTitle={true}
                                driver={true}
                              />
                            </div>
                          )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {selectedTab === 0 && list.isOperativeBranch ? (
                    <>{renderLocalSupplierActions(list)}</>
                  ) : (
                    <></>
                  )}
                  {selectedTab === 0 && !list.isOperativeBranch && (
                    <>
                      {!list.dispatch &&
                      // !list.driver &&
                      list.supplierInfo &&
                      list.supplierInfo.status !== 'expired' &&
                      list.supplierInfo.status !== 'declined' &&
                      list.supplierInfo.status !== 'declined changes' &&
                      list.status !== 'completed' &&
                      list.status !== 'cancellation_accepted_by_agent' &&
                      list.status !== 'cancelled_by_agent' ? (
                        <div className='driver-booking-cta-footer'>
                          <Button
                            className='select-btn select-vehicle mb-2'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            variant='brown'
                            onClick={() => {
                              onAcceptBooking(list._id)
                            }}
                          >
                            Accept offer
                          </Button>
                          <Button
                            className='partner-btn-desktop-tertiary select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              textShadow: '0.25px 0 #a79779',
                            }}
                            onClick={() => {
                              setShowDeclineConfirmationModal(true)
                            }}
                          >
                            Decline
                          </Button>
                        </div>
                      ) : (!list.partnerVehicleId ||
                          !list.partnerChauffeurId) &&
                        !list.driver &&
                        !list.dispatchChauffeurAssigned &&
                        list.supplierInfo &&
                        list.supplierInfo.status !== 'expired' &&
                        list.supplierInfo.status !== 'declined' &&
                        list.supplierInfo.status !== 'declined changes' ? (
                        <div className='driver-booking-cta-footer'>
                          <Button
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            variant='brown'
                            onClick={() => {
                              assignChauffeur()
                            }}
                          >
                            {list.partnerChauffeurId
                              ? 'Assign vehicle'
                              : `Assign ${isCarRental ? 'agent' : 'chauffeur'}`}
                          </Button>
                        </div>
                      ) : (
                        <>
                          {list.isDriverAndNotAccepted && (
                            <div className='driver-booking-cta-footer'>
                              <Button
                                className='select-btn select-vehicle mb-2'
                                style={{
                                  width: '100%',
                                  fontWeight: '900',
                                  fontSize: '16px',
                                  textShadow: '0.25px 0 #ffffff',
                                }}
                                variant='brown'
                                onClick={() => {
                                  onAcceptBookingDriver(list._id)
                                }}
                              >
                                Accept booking
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {selectedTab === 1 &&
                    list.driver &&
                    !list.isDriverAndNotAccepted &&
                    list.status !== 'completed' &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' &&
                    !updateDayCompleted && (
                      <div className='driver-booking-cta-footer'>
                        {proSteps.length > step && details && details.isDriver && (
                          <Button
                            className='select-btn select-vehicle'
                            style={{
                              width: '100%',
                              fontWeight: '900',
                              fontSize: '16px',
                              textShadow: '0.25px 0 #ffffff',
                            }}
                            variant='brown'
                            onClick={nextStep}
                            disabled={isSubmitting}
                          >
                            {buttonLabels[step]}
                          </Button>
                        )}
                      </div>
                    )}
                  {selectedTab === 1 &&
                    updateDayCompleted &&
                    (!list.supplierInfo ||
                      (list.supplierInfo &&
                        list.supplierInfo.status !== 'expired')) &&
                    list.days &&
                    list.status !== 'cancellation_accepted_by_agent' &&
                    list.status !== 'cancelled_by_agent' && (
                      <div className='driver-booking-container'>
                        <div className='driver-info-layout-mobile'>
                          <div className='driver-info-column'>
                            <div className='partner-banner mt-4'>
                              <div className='imageContainer'>
                                <img
                                  src='/images/icons/icon-warning.png'
                                  alt=''
                                />
                              </div>
                              <div className='partnerContainer'>
                                <span>
                                  {list.days[currentDay]
                                    ? `Updates for Day ${currentDay} complete. Please come back on Day ${currentDay +
                                        1} (${moment
                                        .utc(list.days[currentDay].pickUpDate)
                                        .format(dateFormatDetailed)}).`
                                    : 'Updates for all days complete.'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <PartnerAcceptedModal
                  isBookingAccepted={showAcceptedModal}
                  isBookingDeclined={showDeclinedModal}
                  isBookingChangesAccpted={showAccptedChangesModal}
                  isBookingChangesDeclined={showDeclinedChangesModal}
                />
              )}
            </>
          </SmallDown>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const mapStateToProps = ({
  auth: { isLocalSupplier = null, companyId = null },
}) => ({
  isLocalSupplier,
  companyId,
})

export default withRouter(
  connect(mapStateToProps, {
    acceptOffer,
    acceptOfferOp,
    declineOffer,
    getBookingTimeZone,
    acceptPartnerBooking,
    generatePdfBoard,
    getPrivatePartnerFileURL,
    switchFlightApiKey,
    bookingChangesPartner,
    updateChangesStatusPartner,
    storeFlightIcao,
    getContinent,
    cancelDispatchBooking,
    cancelDispatchBookingMobile,
  })(BookingDetails)
)
