import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile, numberWithCommas } from 'helpers'

const isMobileBrowser = isMobile()

export const CommissionModal = ({
  show,
  onHide,
  commissionValue,
  commissionWeight,
  discountValue,
  discountWeight,
  currency,
  referralDiscountAmount,
}) => {
  const onClose = () => {
    onHide()
  }

  return (
    <Modal
      size='lg'
      show={show}
      onHide={onClose}
      className='client-commission-modal modal-90w'
      dialogClassName='modal-90w '
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-lg' className='modal-title'>
          <div className='close-btn-wrap'>
            <Button
              variant='link'
              onClick={onClose}
              className={isMobileBrowser ? 'btn-link-draft-mobile' : 'pr-3'}
            >
              <img
                className='w-100 h-100'
                src={`${
                  isMobileBrowser
                    ? '/images/icons/close-icon-black.png'
                    : '/images/icons/modal-close-icon.svg'
                }`}
                alt=''
                height={isMobileBrowser ? 12 : 50}
                width={isMobileBrowser ? 12 : 50}
                style={
                  isMobileBrowser
                    ? { marginRight: '12px', marginTop: '10px' }
                    : {}
                }
              />
            </Button>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {commissionValue && discountValue && !referralDiscountAmount ? (
          <>
            <div
              className='d-flex'
              style={
                isMobileBrowser
                  ? { flexDirection: 'column', gridGap: '2em' }
                  : { justifyContent: 'space-around' }
              }
            >
              <div>
                <div className='price-text'>
                  -{discountValue} {currency}
                </div>
                <div className='commission-text'>Discount applied</div>
              </div>
              <div>
                <div className='price-text'>
                  {commissionValue} {currency}
                </div>
                <div className='commission-text'>Commissionable</div>
              </div>
            </div>
            <div className='description-text'>
              {discountWeight && discountWeight.weight
                ? discountWeight.code && discountWeight.code !== ''
                  ? `${discountWeight.weight}% discount`
                  : `Get a ${discountWeight.weight}% discount on every booking.`
                : `Get a ${discountWeight}% discount on every booking.`}
            </div>
            <div className='description-text' style={{ marginTop: 0 }}>
              Earn a {commissionWeight}% commission on every booking.
            </div>
          </>
        ) : commissionValue && !referralDiscountAmount ? (
          <>
            <div className='price-text'>
              {commissionValue} {currency}
            </div>
            <div className='commission-text'>Commissionable</div>
            <div className='description-text'>
              Earn a {commissionWeight}% commission on every booking.
            </div>
          </>
        ) : referralDiscountAmount ? (
          <>
            <div
              className='d-flex'
              style={
                isMobileBrowser
                  ? { flexDirection: 'column', gridGap: '2em' }
                  : { justifyContent: 'space-around' }
              }
            >
              <div>
                <div className='price-text'>
                  -{numberWithCommas(referralDiscountAmount.toFixed(2))}{' '}
                  {currency}
                </div>
                <div className='commission-text'>Referral discount applied</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='price-text'>
              -{discountValue} {currency}
            </div>
            <div className='commission-text'>Discount applied</div>
            <div className='description-text'>
              {discountWeight && discountWeight.weight
                ? discountWeight.code && discountWeight.code !== ''
                  ? `${discountWeight.weight}% discount`
                  : `Get a ${discountWeight.weight}% discount on every booking.`
                : `Get a ${discountWeight}% discount on every booking.`}
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
