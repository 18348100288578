import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import AuthForm from './AuthForm'
import {
  clearClientData,
  registerClientUser,
  setClientUserDetails,
} from '../../../../../actions'
import FormInputPhoneNew, {
  phoneNumberRequiredValidator,
  phoneNumberValidator,
} from '../../../../../components/FormInputPhoneNew'
import './../../../../../assets/styles/pages/_clientAuth.scss'
import {
  requiredStringValidator,
  emailValidator,
  stringValidator,
} from '../../../../../form-validation'
import LocationAutocomplete from 'components/LocationAutocomplete'
import FormSelect from '../../../../../components/FormSelect'
import { defaultFleetOptions } from '../../../../../constants/index'
import { isMobile } from '../../../../../helpers'
const isMobileBrowser = isMobile()
const fields = [
  {
    name: 'name',
    label: 'NAME*',
    icon: 'account_circle',
  },
  {
    name: 'email',
    label: 'Email*',
    type: 'email',
  },
  {
    name: 'phoneNumber',
    label: 'PHONE NUMBER',
    component: FormInputPhoneNew,
  },
  {
    name: 'company',
    label: 'COMPANY*',
    icon: 'account_circle',
  },
  {
    name: 'fleetSize',
    label: 'FLEET SIZE*',
    component: FormSelect,
    options: defaultFleetOptions,
    md: 3,
  },
  {
    name: 'place',
    label: 'City*',
    component: LocationAutocomplete,
    searchOptions: { types: ['(cities)'] },
    confirmOnSelect: true,
    needFullAddress: true,
    displayKey: 'fullAddress',
    showPin: true,
    needBounds: true,
  },
  {
    name: 'website',
    label: 'Website*',
  },
]

const validationSchema = Yup.object().shape({
  name: requiredStringValidator(),
  email: requiredStringValidator().concat(emailValidator()),
  phoneNumber: phoneNumberRequiredValidator().concat(phoneNumberValidator()),
  company: requiredStringValidator(),
  place: requiredStringValidator(),
  website: stringValidator(),
  fleetSize: Yup.mixed().test('is-object-or-empty', 'Required', value => {
    if (typeof value === 'object' || value === '') {
      return true
    }
    return false
  }),
})

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  company: '',
  place: '',
  website: '',
  fleetSize: '',
}

function RegisterPartner(props) {
  return (
    <>
      <div className='auth-page'>
        {!isMobileBrowser && <div className='image-container-new-partner' />}
        <div
          className={
            isMobileBrowser ? 'auth-container' : 'auth-container-partner'
          }
        >
          <AuthForm
            title='Partner Register'
            initialValues={initialValues}
            validationSchema={validationSchema}
            fields={fields}
            isRegistration
            buttonLabel='Submit'
            submitStyle='login-btn'
          />
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  return {
    clientAuth: state.clientAuth,
  }
}

export default connect(
  mapStateToProps,
  { registerClientUser, setClientUserDetails, clearClientData }
)(RegisterPartner)
