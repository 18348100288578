import React, { useEffect, useState, useRef, useCallback } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button, Modal, Badge } from 'react-bootstrap'
import VehicleCard from '../../VehicleCard'
import MessageInput from './MessageInput'
import Scrollbar from 'react-scrollbars-custom'
import LocationType from '../../locationType'
import { connectedApiService } from '../../../'
import LoadingSpinner from '../../LoadingSpinner'
import { UAParser } from 'ua-parser-js'
import { isMobile, getLabelFromValue } from '../../../helpers'
import { defaultBookingStatus } from '../../../constants'
import _ from 'lodash'
import { logger } from '../../../actions'
import { CommissionModal } from '../../CommissionModal'
import { apiHost } from '../../../api'

const isMobileBrowser = isMobile()
const dateFormat = 'DD/MM/YYYY'
const newTimeFormat = 'HH:mm'

const renderTextWithMentions = (text, links, onGetFile) => {
  const splited = text.split(/\r?\n/)
  // remove empty strings at the end of array
  while (true) {
    if (splited[splited.length - 1] === '') {
      splited.pop()
    } else {
      break
    }
  }

  return splited.map((item, index) => {
    const urls = item.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g)

    if (item.includes(' ')) {
      const splitedItem = item.split(' ')
      return splitedItem.map((item, idx) => {
        const foundInvoice = item.match(/^@private_file\d+/)
        if (foundInvoice) {
          let link = foundInvoice[0] === '@private_file0' ? links[0] : links[1]
          let invoiceText
          if (link.meta && link.meta.caption) {
            invoiceText = link.meta.caption
          } else {
            invoiceText = foundInvoice[0]
          }
          const rest = foundInvoice.input.replace(foundInvoice[0], '')
          return (
            <span key={index}>
              {idx === 0 && index !== 0 && <br />}
              <Button
                onClick={onGetFile(link.fileId)}
                variant='link'
                className='text-brown pointer-link'
              >
                {invoiceText}
              </Button>
              {rest.length ? rest : ' '}{' '}
            </span>
          )
        }
        const foundMention = item.match(
          /^@booking\d{4,6}-R\b|@booking\d{4,6}-Z\b|@quote\d{4,6}-R\b|@quote\d{4,6}-Z\b|@bookingL-\d{4,6}|@bookingI-\d{4,6}|@quote\d{4,6}|@booking\d{4,6}/
        )
        if (foundMention) {
          const mentionText = foundMention[0]
          const foundLink = mentionText.includes('booking')
            ? links.find(
                link =>
                  mentionText.substring(8) === link.bookingNumber.toString()
              )
            : links.find(
                link =>
                  mentionText.substring(6) === link.bookingNumber.toString()
              )

          const rest = foundMention.input.replace(mentionText, '')
          if (foundLink) {
            return (
              <span key={`${mentionText}${idx}`}>
                {idx === 0 && index !== 0 && <br />}
                <Link
                  to={
                    foundLink.linkType === 'quote_link'
                      ? `/booking/quotes/${
                          foundLink.bookingType === 'airport_transfer'
                            ? 'airport-transfer'
                            : foundLink.bookingType === 'chauffeur_services'
                            ? 'chauffeur'
                            : 'car-rental'
                        }/${
                          foundLink.bookingId.length >= 18
                            ? foundLink.bookingId.slice(0, -1)
                            : foundLink.bookingId
                        }/${foundLink.quoteIndex}`
                      : `/booking/my-bookings/details/${foundLink.bookingId}`
                  }
                  className='text-brown pointer-link'
                >
                  {mentionText}
                </Link>
                {rest.length ? rest : ' '}{' '}
              </span>
            )
          }
        }
        const foundTeamMention = item.match(
          /^@TeamBooking\d{4,6}-R\b|@TeamBooking\d{4,6}-Z\b|@TeamBookingL-\d{4,6}|@bookingI-\d{4,6}|@TeamBooking\d{4,6}/
        )
        if (foundTeamMention) {
          const mentionText = foundTeamMention[0]
          const foundLink = links.find(
            link => mentionText.substring(12) === link.bookingNumber.toString()
          )

          const rest = foundTeamMention.input.replace(mentionText, '')
          if (foundLink) {
            return (
              <span key={`${mentionText}${index}`}>
                {idx === 0 && index !== 0 && <br />}
                <Link
                  to={
                    foundLink.linkType === 'quote_link'
                      ? `/booking/quotes/${
                          foundLink.bookingType === 'airport_transfer'
                            ? 'airport-transfer'
                            : foundLink.bookingType === 'chauffeur_services'
                            ? 'chauffeur'
                            : 'car-rental'
                        }/${
                          foundLink.bookingId.length >= 18
                            ? foundLink.bookingId.slice(0, -1)
                            : foundLink.bookingId
                        }/${foundLink.quoteIndex}`
                      : `/booking/team-bookings/details/${foundLink.bookingId}`
                  }
                  className='text-brown pointer-link'
                >
                  {mentionText}
                </Link>
                {rest.length ? rest : ' '}{' '}
              </span>
            )
          }
        }
        return [
          urls && urls.includes(item) ? (
            <a
              className='align-items-center add-link text-brown'
              style={{ whiteSpace: 'nowrap !important' }}
              href={item}
              target='_blank'
            >
              {idx === 0 && index !== 0 && <br />}
              {`${item} `}
            </a>
          ) : (
            <span>
              {idx === 0 && index !== 0 && <br />}
              {`${item} `}
            </span>
          ),
        ]
      })
    } else {
      const foundInvoice = item.match(/^@private_file\d+/)
      if (foundInvoice) {
        let link = foundInvoice[0] === '@private_file0' ? links[0] : links[1]
        let invoiceText
        if (link.meta && link.meta.caption) {
          invoiceText = link.meta.caption
        } else {
          invoiceText = foundInvoice[0]
        }
        const rest = foundInvoice.input.replace(foundInvoice[0], '')
        return (
          <span key={index}>
            <Button
              onClick={onGetFile(link.fileId)}
              variant='link'
              className='text-brown pointer-link'
            >
              {invoiceText}
            </Button>
            {rest.length ? rest : ' '}{' '}
          </span>
        )
      }
      const foundMention = item.match(
        /^@booking\d{4,6}-R\b|@booking\d{4,6}-Z\b|@quote\d{4,6}-R\b|@quote\d{4,6}-Z\b|@bookingL-\d{4,6}|@bookingI-\d{4,6}|@quote\d{4,6}|@booking\d{4,6}/
      )
      if (foundMention) {
        const mentionText = foundMention[0]
        const foundLink = mentionText.includes('booking')
          ? links.find(
              link => mentionText.substring(8) === link.bookingNumber.toString()
            )
          : links.find(
              link => mentionText.substring(6) === link.bookingNumber.toString()
            )

        const rest = foundMention.input.replace(mentionText, '')
        if (foundLink) {
          return (
            <span key={`${mentionText}${index}`}>
              {index !== 0 && <br />}
              <Link
                to={
                  foundLink.linkType === 'quote_link'
                    ? `/booking/quotes/${
                        foundLink.bookingType === 'airport_transfer'
                          ? 'airport-transfer'
                          : foundLink.bookingType === 'chauffeur_services'
                          ? 'chauffeur'
                          : 'car-rental'
                      }/${
                        foundLink.bookingId.length >= 18
                          ? foundLink.bookingId.slice(0, -1)
                          : foundLink.bookingId
                      }/${foundLink.quoteIndex}`
                    : `/booking/my-bookings/details/${foundLink.bookingId}`
                }
                className='text-brown pointer-link'
              >
                {mentionText}
              </Link>
              {rest.length ? rest : ' '}{' '}
            </span>
          )
        }
      }
      const foundTeamMention = item.match(
        /^@TeamBooking\d{4,6}-R\b|@TeamBooking\d{4,6}-Z\b|@TeamBookingL-\d{4,6}|@bookingI-\d{4,6}|@TeamBooking\d{4,6}/
      )
      if (foundTeamMention) {
        const mentionText = foundTeamMention[0]
        const foundLink = links.find(
          link => mentionText.substring(12) === link.bookingNumber.toString()
        )

        const rest = foundTeamMention.input.replace(mentionText, '')
        if (foundLink) {
          return (
            <span key={`${mentionText}${index}`}>
              {index !== 0 && <br />}
              <Link
                to={
                  foundLink.linkType === 'quote_link'
                    ? `/booking/quotes/${
                        foundLink.bookingType === 'airport_transfer'
                          ? 'airport-transfer'
                          : foundLink.bookingType === 'chauffeur_services'
                          ? 'chauffeur'
                          : 'car-rental'
                      }/${
                        foundLink.bookingId.length >= 18
                          ? foundLink.bookingId.slice(0, -1)
                          : foundLink.bookingId
                      }/${foundLink.quoteIndex}`
                    : `/booking/team-bookings/details/${foundLink.bookingId}`
                }
                className='text-brown pointer-link'
              >
                {mentionText}
              </Link>
              {rest.length ? rest : ' '}{' '}
            </span>
          )
        }
      }
    }
    return [
      urls && urls.includes(item) ? (
        <a
          className='align-items-center add-link text-brown'
          style={{ whiteSpace: 'nowrap !important' }}
          href={item}
          target='_blank'
        >
          {index !== 0 && <br />}
          {`${item} `}
        </a>
      ) : (
        <span key={`${item}${index}`}>
          {index !== 0 && <br />}
          {`${item} `}
        </span>
      ),
    ]
  })
}

const refineTextView = text => {
  let splited = text.split(/\n/g)
  // remove empty strings at the end of array
  while (true) {
    if (splited[splited.length - 1] === '') {
      splited.pop()
    } else {
      break
    }
  }

  return splited.map((item, index) => {
    const urls = item.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g)
    if (urls) {
      let splitedLinks = item.split(' ')
      return (
        <span>
          {splitedLinks.map((val, i) => {
            return [
              urls.includes(val) ? (
                <a
                  className='align-items-center add-link text-brown'
                  style={{ whiteSpace: 'nowrap !important' }}
                  href={val}
                  target='_blank'
                >
                  {`${val} `}
                </a>
              ) : (
                <span key={`${val}${i}`}>{`${val} `}</span>
              ),
            ]
          })}
        </span>
      )
    }
    return (
      <span key={`${item}${index}`}>
        {`${item} `}
        <br />
      </span>
    )
  })
}
const renderText = text => {
  if (text) {
    let string = text.split('<br>')
    if (string.length <= 1) {
      return (
        <div style={{ whiteSpace: 'pre-wrap' }}>{refineTextView(text)}</div>
      )
    } else {
      return (
        <p>
          {string.map(str => (
            <span>
              {str}
              <br />
            </span>
          ))}
        </p>
      )
    }
  } else {
    return null
  }
}
const getPostData = (quote, query, msgId) => {
  const {
    bookingType,
    globalRateLocationId,
    vehicle: { id },
    durationType,
    duration,
    smartRateId,
    globalRateItemId,
  } = quote
  const { pickUpLocation, dropOffLocation, pickUpDate, dropOffDate } = query
  const generalData = {
    chatMessageId: msgId,
    type: bookingType,
    globalVehicleId: id,
    pickUpLocation,
    pickUpDate,
  }

  switch (bookingType) {
    case 'chauffeur_services': {
      return {
        ...generalData,
        durationType,
        globalRateLocationId,
        duration,
      }
    }
    case 'airport_transfer': {
      const postData = smartRateId
        ? {
            ...generalData,
            smartRateId,
            globalRateLocationId,
            isSmartRate: true,
            dropOffLocation,
          }
        : {
            ...generalData,
            globalRateItemId,
            dropOffLocation,
          }
      return postData
    }
    case 'car_rentals': {
      return {
        ...generalData,
        dropOffLocation,
        globalRateLocationId,
        dropOffDate,
      }
    }
    default: {
      return {}
    }
  }
}

const getTimeZoneData = async Data => {
  try {
    const { data } =
      (await connectedApiService.post(`booking-timeZone`, Data)) || {}
    return data
  } catch (error) {
    logger({ fileName: 'chatRoom', error: error })
    console.log(error)
    return {}
  }
}
export default function ChatRoom({
  messages,
  attachedAdmin,
  chatId,
  chatUser,
  companyId,
  isTyping,
  markAsRead,
  unsetTyping,
  getPrivateFileURL,
  companyLogo,
  lastMessageDate,
  history,
  createBooking,
  isPanel = false,
  readMoreMessage,
  currentPage,
  isLoading,
  lastMessage,
  vehicleSearch,
}) {
  const [showPdf, setShowPdf] = useState(false)
  const [isTypeImg, setTypeImg] = useState(false)
  const [keyboadOpen, setkeyboadOpen] = useState(false)
  const [pdfLink, setPdfLink] = useState('')
  const [isLateSearch, setIsLateSearch] = useState(null)
  const [showCommissionModal, setShowCommissionModal] = useState(false)
  const [commissionValue, setCommissionValue] = useState(null)
  const [commissionWeight, setCommissionWeight] = useState(null)
  const [discountValue, setDiscountValue] = useState(null)
  const [discountWeight, setDiscountWeight] = useState(null)
  const [currency, setCurrency] = useState(null)

  useEffect(() => {
    if (isTyping) {
      setTimeout(() => {
        unsetTyping(chatId)
      }, 3000)
    }
  }, [isTyping, chatId, markAsRead, unsetTyping, companyId])

  useEffect(() => {
    if (vehicleSearch) {
      setIsLateSearch(vehicleSearch)
    } else {
      setIsLateSearch(null)
    }
  }, [vehicleSearch])

  const onGetFile = useCallback(
    fileId => async () => {
      try {
        const { url, extension } = await getPrivateFileURL(fileId)
        if (url) {
          if (extension !== 'pdf') {
            setTypeImg(true)
          }
          let parser = new UAParser()
          parser.setUA(navigator.userAgent)
          const browser = parser.getBrowser().name
          const result = parser.getOS()
          const name = result.name
          if (
            name.search('iOS') !== -1 ||
            browser.search('Safari') !== -1 ||
            browser.search('safari') !== -1
          ) {
            setShowPdf(true)
            setPdfLink(url)
          } else {
            window.open(url, '_blank')
          }
        }
      } catch (error) {
        logger({ fileName: 'chatRoom', error: error })
        console.log(error)
      }
    },
    [getPrivateFileURL]
  )
  const resetModal = () => {
    setShowPdf(false)
    setTypeImg(true)
  }
  const listRef = useRef(null)
  const renderVehicleCardHandler = async (
    bookingId,
    query,
    type,
    result,
    messageId,
    pickUpLocation,
    dropOffLocation,
    index
  ) => {
    if (bookingId) {
      history.push(`/booking/my-bookings/details/${bookingId}`)
      return
    }
    let locationType = null
    if (query && query.dropOffLocation) {
      locationType = !(
        query.pickUpLocation.locationType || query.dropOffLocation.locationType
      )
        ? await LocationType(query.pickUpLocation, query.dropOffLocation)
        : null
    } else {
      locationType =
        query && !query.pickUpLocation.locationType
          ? await LocationType(query.pickUpLocation)
          : null
    }
    if (query.pickUpDate) {
      const Data = {
        eventTime: query.pickUpDate,
        coordinates: `${pickUpLocation.lat},${pickUpLocation.lng}`,
      }
      const timeZoneData = await getTimeZoneData(Data)
      let pickUpTime = `${moment
        .utc(timeZoneData.eventTimeStamp * 1000)
        .format(newTimeFormat)}`
      let pickUpDate = `${moment
        .utc(timeZoneData.eventTimeStamp * 1000)
        .format(dateFormat)}`
      query.pickUpTime = pickUpTime
      query.pickUpDate = pickUpDate
    }

    if (query.dropOffDate) {
      const Data = {
        eventTime: query.dropOffDate,
        coordinates: `${dropOffLocation.lat},${dropOffLocation.lng}`,
      }
      const timeZoneData = await getTimeZoneData(Data)
      let dropOffTime = `${moment
        .utc(timeZoneData.eventTimeStamp * 1000)
        .format(newTimeFormat)}`
      let dropOffDate = `${moment
        .utc(timeZoneData.eventTimeStamp * 1000)
        .format(dateFormat)}`

      query.dropOffTime = dropOffTime
      query.dropOffDate = dropOffDate
    }
    history.push({
      // pathname: `/booking/quotes/${type}`,
      pathname: `/booking/quotes/${type}/${messageId}/${index}`,
      query: query,
      result: result,
      LocationType: locationType,
      messageId: messageId,
    })
  }
  const filterMembersDiscount = list => {
    var discountObject = null
    _.each(list, item => {
      if (item.type === 'fixedDiscount' || item.type === 'percentageDiscount') {
        discountObject = item
      }
    })
    if (discountObject) {
      return {
        hasDiscount: true,
        discountObject: discountObject,
      }
    } else {
      return {
        hasDiscount: false,
        discountObject: null,
      }
    }
  }
  const renderVehicleCard = (quote, messageId) => {
    if (!quote) {
      return
    }
    const {
      query: {
        mode,
        pickUpLocation,
        dropOffLocation,
        rateType,
        arrival,
        departure,
      },
    } = quote
    const query = JSON.parse(JSON.stringify(quote.query))
    return quote.searchResults.map((result, index) => {
      const {
        rate,
        currency,
        clientCurrency,
        vehicle: {
          image,
          categoryChauffeured,
          categorySelfDrive,
          label,
          years,
          luggage,
          Passenger,
          electricVehicle,
        },
        bookingType,
        bookingId,
        booking,
        rateMode,
        actualRate,
        benefits,
        memberBenefits,
        discountWeight,
        paymentType,
        vehicleHourlyRate,
        durationType,
        status,
      } = result
      var slectedMemberBenefits = null
      if (memberBenefits && memberBenefits.list.length) {
        const discounts = filterMembersDiscount(memberBenefits.list)
        var response = {
          hasDiscount: discounts.hasDiscount,
          discountObject: discounts.discountObject,
          ...memberBenefits,
        }
        slectedMemberBenefits = response
      } else {
        slectedMemberBenefits = memberBenefits
      }
      const isBooked = booking && booking.status && booking.status === 'quote'
      const isExpired =
        !booking &&
        (status === 'quote_expired' ||
          status === 'cancellation_accepted_by_agent' ||
          status === 'unavailable')
      const isPaid = booking && booking.status && booking.status !== 'quote'
      const type =
        (bookingType === 'airport_transfer' ||
          bookingType === 'return_transfer' ||
          bookingType === 'departure_transfer') &&
        mode === 'chauffeured' &&
        rateType === 'a-to-b'
          ? 'airport-transfer'
          : (bookingType === 'chauffeur_services' &&
              mode === 'chauffeured' &&
              rateType === 'a-to-b') ||
            (bookingType === 'chauffeur_services' &&
              mode === 'chauffeured' &&
              (rateType === 'hourly' || rateType === 'daily'))
          ? 'chauffeur'
          : 'car-rental'
      const buttonTitle = isBooked || isPaid ? 'View booking' : 'Book now'
      return (
        <div key={`vehicle${label}${index}`}>
          <VehicleCard
            src={image}
            category={
              rateMode && rateMode === 'self_drive'
                ? categorySelfDrive
                : categoryChauffeured
            }
            actualRate={actualRate}
            benefits={benefits}
            memberBenefits={
              slectedMemberBenefits && slectedMemberBenefits.hasDiscount
                ? slectedMemberBenefits.discountObject
                : null
            }
            discountWeight={
              benefits && benefits.discountWeight
                ? benefits.discountWeight
                : discountWeight
            }
            paymentType={paymentType}
            title={label}
            price={rate}
            currency={clientCurrency ? clientCurrency : currency}
            passangersCapacity={Passenger}
            lugageCapacity={luggage} // to show luggage for all mode
            years={years}
            buttonTitle={buttonTitle}
            className='chat-vehicle-card'
            booked={isPaid}
            electricVehicle={electricVehicle}
            status={booking && booking.status}
            active={isPaid ? '' : 'inactive'}
            durationType={durationType}
            vehicleHourlyRate={vehicleHourlyRate}
            handler={() =>
              renderVehicleCardHandler(
                bookingId,
                query,
                type,
                result,
                messageId,
                pickUpLocation,
                dropOffLocation,
                index
              )
            }
            arrival={arrival}
            departure={departure}
            openCommissionModal={(
              commValue,
              commWeight,
              discValue,
              discWeight,
              currency
            ) => {
              setShowCommissionModal(true)
              setCommissionValue(commValue)
              setCommissionWeight(commWeight)
              setDiscountValue(discValue)
              setDiscountWeight(discWeight)
              setCurrency(currency)
            }}
          />
          {((booking && booking.status) || isExpired) && (
            <div className='message-info-bottom-chat client-chat'>
              <div className='message-info-booked-event-chat'>
                <Badge
                  href='#'
                  variant={
                    isExpired && status
                      ? `${status} badge-outlined badge-outlined-none`
                      : `${booking.status} badge-outlined badge-outlined-none`
                  }
                  style={{ textTransform: 'uppercase' }}
                >
                  {getLabelFromValue(
                    defaultBookingStatus,
                    isExpired && status ? status : booking.status
                  )}
                </Badge>
              </div>
            </div>
          )}
          {!booking && !bookingId && !isExpired && (
            <div className='message-info-bottom-chat client-chat'>
              <div className='message-info-booked-event-chat'>
                <Button
                  className='mt-2 book-btn'
                  variant='brown'
                  type='button'
                  onClick={() =>
                    renderVehicleCardHandler(
                      bookingId,
                      query,
                      type,
                      result,
                      messageId,
                      pickUpLocation,
                      dropOffLocation,
                      index
                    )
                  }
                >
                  Book
                </Button>
              </div>
            </div>
          )}
        </div>
      )
    })
  }

  const onScroll = useCallback(() => {
    const bottom = listRef.current.scrollTop + listRef.current.clientHeight
    const scrollHeight = listRef.current.scrollHeight
    if (listRef.current.scrollTop < 10) {
      //if its moved to top, scroll position is less than 10
      window.currentScroll = listRef.current.scrollHeight
      readMoreMessage()
    }
  }, [readMoreMessage])

  useEffect(() => {
    if (currentPage === 1) {
      listRef.current.scrollToBottom()
      window.currentScroll = 0
    }
  }, [onScroll])

  useEffect(() => {
    if (lastMessage) {
      if (
        lastMessage &&
        lastMessage.user &&
        lastMessage.user.id == chatUser.id
      ) {
        setTimeout(() => {
          if (listRef && listRef.current) {
            listRef.current.scrollToBottom()
          }
        }, 500)
      } else {
        const bottom = listRef.current.scrollTop + listRef.current.clientHeight
        const scrollHeight = listRef.current.scrollHeight

        if (bottom >= scrollHeight - 100) {
          listRef.current.scrollToBottom()
        }
      }
    }
  }, [lastMessage])

  var timer = null
  var keyboard_status = false
  if (timer) {
    clearInterval(timer)
  }
  timer = setInterval(() => {
    if (!listRef || !listRef.current) {
      clearInterval(timer)
      return
    }

    let opened = document
      .getElementsByClassName('app-content')[0]
      .classList.contains('keyboard-open')
    if (keyboard_status === false && opened === true) {
      if (opened) {
        listRef.current.scrollToBottom()
      }
    }
    keyboard_status = opened
  }, 100)

  const updateKeyboadState = res => {
    setkeyboadOpen(res)
    if (listRef && listRef.current) {
      listRef.current.scrollToBottom()
    }
  }

  return (
    <div
      className={`client-chat-room ${
        isMobileBrowser && keyboadOpen
          ? 'client-chat-room-open'
          : isMobileBrowser && !keyboadOpen
          ? 'client-chat-room-close'
          : ''
      }`}
    >
      {isLoading && <LoadingSpinner />}
      <div className='client-messages-list mx-auto'>
        <Scrollbar
          ref={listRef}
          onScroll={onScroll}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {messages &&
            messages.map(
              (
                {
                  user,
                  text,
                  updatedAt,
                  createdAt,
                  quote,
                  links,
                  files,
                  id,
                  isDeleted,
                },
                i
              ) => {
                if (isDeleted) {
                  return ''
                }
                return (
                  <div key={i}>
                    {i === 0 &&
                      (moment().isSame(
                        createdAt ? createdAt : updatedAt,
                        'day'
                      ) ? (
                        <div className='date-line'>
                          <div>Today</div>
                        </div>
                      ) : (
                        <div className='date-line'>
                          <div style={{ width: '97px' }}>
                            {moment(createdAt ? createdAt : updatedAt).format(
                              'ddd D MMM YY'
                            )}
                          </div>
                        </div>
                      ))}
                    {i > 0 &&
                      !moment(createdAt ? createdAt : updatedAt).isSame(
                        messages[i - 1].createdAt
                          ? messages[i - 1].createdAt
                          : messages[i - 1].updatedAt,
                        'day'
                      ) &&
                      (moment().isSame(
                        createdAt ? createdAt : updatedAt,
                        'day'
                      ) ? (
                        <div className='date-line'>
                          <div>Today</div>
                        </div>
                      ) : (
                        <div className='date-line'>
                          <div style={{ width: '97px' }}>
                            {moment(createdAt ? createdAt : updatedAt).format(
                              'ddd D MMM YY'
                            )}
                          </div>
                        </div>
                      ))}
                    <div
                      className={`message message-${i}
      ${chatUser && user && chatUser.id === user.id ? 'is-current' : ''}
                `}
                    >
                      {!quote && (
                        <div className='user-avatar'>
                          {chatUser && user && chatUser.id === user.id ? (
                            <>
                              {user.image || companyLogo ? (
                                <img src={user.image || companyLogo} alt='' />
                              ) : (
                                <div className='default-logo'>
                                  {`${user.firstName.substring(0, 1)}${
                                    user.lastName
                                      ? user.lastName.substring(0, 1)
                                      : ''
                                  }`}
                                </div>
                              )}
                            </>
                          ) : (
                            <img
                              src={'/images/icons/chat/rolzo_avatar.png'}
                              alt=''
                              className='rolzo-logo'
                            />
                          )}
                        </div>
                      )}
                      <div
                        className={quote ? 'vehicle-wrap w-100' : 'text-wrap'}
                      >
                        {quote ? (
                          renderVehicleCard(quote, id)
                        ) : (
                          <>
                            {(links || text) && (
                              <div className='text'>
                                {links && links.length
                                  ? renderTextWithMentions(
                                      text,
                                      links,
                                      onGetFile
                                    )
                                  : <>{renderText(text)}</> || ''}
                              </div>
                            )}
                            {files && files.length
                              ? files.map(file => {
                                  return (
                                    <div
                                      onClick={onGetFile(file.id)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      {file.isImage ||
                                      (file.type &&
                                        file.type.includes('image')) ? (
                                        <img
                                          src={`${apiHost}/cdn/storage/files/${file.id}/chat`}
                                          alt=''
                                          width='200'
                                          style={{ marginBottom: '10px' }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <Button
                                        className='download-link'
                                        variant='link'
                                        key={file.id}
                                        // onClick={onGetFile(file.id)}
                                        style={{ marginBottom: '15px' }}
                                      >
                                        <img
                                          className='pdf-icon'
                                          src='/images/icons/clippedFile.png'
                                          alt=''
                                        />
                                        {file.name}
                                      </Button>
                                    </div>
                                  )
                                })
                              : ''}
                          </>
                        )}
                        <div className='message-info-bottom'>
                          <div className='message-info'>
                            {attachedAdmin &&
                            user &&
                            attachedAdmin.id === user.id
                              ? attachedAdmin.firstName
                              : user && chatUser.id === user.id
                              ? chatUser.firstName
                              : user && user.role && user.role.system
                              ? user.firstName
                              : 'ROLZO'}
                            ,{' '}
                            {moment(createdAt ? createdAt : updatedAt).format(
                              'HH:mm'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          <div
            className={`message is-typing
            ${!isTyping ? 'hidden displaynone' : ''}
          `}
          >
            <p
              className={isMobileBrowser ? 'typer-name' : 'typer-name-desktop'}
            >
              {' '}
              <b>
                {' '}
                {attachedAdmin && attachedAdmin.id && attachedAdmin.firstName}
              </b>{' '}
              is typing...{' '}
            </p>
          </div>
        </Scrollbar>
      </div>
      {chatId ? (
        <div className='client-message-input-block'>
          <div className='chat-footer-container'>
            <MessageInput
              chatId={chatId}
              isPanel={isPanel}
              updateKeyboadState={updateKeyboadState}
              isLateSearch={isLateSearch}
              isMobileBrowser={isMobileBrowser ? true : false}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <Modal
        show={showPdf}
        onHide={() => resetModal()}
        dialogClassName='modal-90w modal-padding'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {isTypeImg ? (
            <img src={pdfLink} alt='' height='100%' width='100%' />
          ) : (
            <embed
              src={pdfLink}
              type='application/pdf'
              height='100%'
              width='100%'
            ></embed>
          )}
        </Modal.Body>
      </Modal>
      {(commissionValue || discountValue) && (
        <CommissionModal
          onHide={() => {
            setShowCommissionModal(false)
          }}
          show={showCommissionModal}
          commissionValue={commissionValue}
          commissionWeight={commissionWeight}
          discountValue={discountValue}
          discountWeight={discountWeight}
          currency={currency}
        />
      )}
    </div>
  )
}
