import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

export default function LoadingSpinner({
  className = '',
  isCenter = false,
  flight = false,
}) {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    const x = setTimeout(() => {
      setShowLoader(true)
    }, 500) //0.5 seconds

    return () => {
      clearTimeout(x)
    }
  }, [])

  return (
    <>
      {showLoader && (
        <>
          {isCenter ? (
            <div className='align-center'>
              <div className='main_spinner' />
            </div>
          ) : (
            <div
              className={flight ? 'spinner_wrapper mt-5' : 'spinner_wrapper'}
            >
              <div className='main_spinner' />
            </div>
          )}
          {/*<div className={`p-3 text-center ${className}`}>
            <Spinner animation='grow' />
          </div>*/}
        </>
      )}
    </>
  )
}
